import React, { useEffect, useState } from "react";
import Icon1Active from "../../icons/Trending up - active.webp";
import Icon1 from "../../icons/Trending up.webp";
import Icon2Active from "../../icons/Text fields - active.webp";
import Icon2 from "../../icons/Text fields.webp";
import Icon3Active from "../../icons/Text snippet - active.webp";
import Icon3 from "../../icons/Text snippet.webp";
import Icon4Active from "../../icons/Vpn key - active.webp";
import Icon4 from "../../icons/Vpn key.webp";
import CreateAdsContentStrategy from "../../icons/image4_ups.webp";
import { trackClickFeaturesCarousel } from "../../utils/analytics";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function CreateAdsContentAndStrategyFasterComponent() {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [hasStoppedTheRotation, setHasStoppedTheRotation] = useState(false);

  const icons = [Icon1, Icon2, Icon3, Icon4];
  const iconsActive = [Icon1Active, Icon2Active, Icon3Active, Icon4Active];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!hasStoppedTheRotation) {
        setActiveStep((prevStep) => (prevStep + 1) % icons.length);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [hasStoppedTheRotation]);

  const getStepItem = (index: number) => {
    const isActiveStep = activeStep === index;
    const stepTitle = t(`website.features.step${index + 1}_title`);
    const stepDescription = t(`website.features.step${index + 1}_description`);

    return (
      <Grid container key={index} xs={12}
            onClick={() => {
              trackClickFeaturesCarousel(stepTitle);
              setActiveStep(index);
              setHasStoppedTheRotation(true);
              setTimeout(() => {
                setHasStoppedTheRotation(false);
              }, 10000);
            }}
            spacing={1}
            sx={{
              alignItems: "center",
              alignContent: "center",
              bgcolor: isActiveStep ? "primary.light" : undefined,
              cursor: "pointer",
              px: 4,
              borderRadius: 2,
              transition: "background-color 0.1s ease-in-out",
            }}>
        <Grid>
          <img style={{ width: 36, display: "inline" }}
               alt={stepTitle}
               src={isActiveStep ? iconsActive[index] : icons[index]} />
        </Grid>
        <Grid xs>
          <Typography variant="h3" sx={{ fontSize: 20, fontWeight: isActiveStep ? 600 : 400 }}>
            {stepTitle}
          </Typography>
        </Grid>
        {isActiveStep && <Grid xs={12}>
          <Typography variant="body1">
            {stepDescription}
          </Typography>
        </Grid>}
      </Grid>
    );
  };

  return (
    <Grid sm={12} md={10} lg={8} container spacing={8} sx={{ mt: 8 }}>
      <Grid xs={12} textAlign={"center"}>
        <Typography variant="h2" color={"primary"} fontWeight={400}
                    id={"features"}
                    sx={{
                      mr: 1,
                      fontSize: { xs: 46, sm: 32 },
                      background: "linear-gradient(270.17deg, #A57DD3 3.88%, #0040FF 98.08%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      textFillColor: "transparent"
                    }}>
          {t("website.features.title")}
        </Typography>
      </Grid>
      <Grid xs={12} container spacing={4} direction={"row"}>
        <Grid container xs={12} sm={6} spacing={1}
              sx={{ alignSelf: "center", height: { xs: "50%", sm: "100%" }, my: 0.5 }}>
          {Array.from({ length: icons.length }, (_, i) => getStepItem(i))}
        </Grid>
        <Grid xs={12} sm={6} sx={{ maxHeight: 512 }}>
          <img style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: 4 }}
               src={CreateAdsContentStrategy}
               alt={t("website.features.imageAlt")} />
        </Grid>
      </Grid>
    </Grid>
  );
}
