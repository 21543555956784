import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Creativity } from "../components/app/CreativityLevel";
import { get, keys, mapValues, pickBy } from "lodash";
import { OutputLanguageData } from "../components/app/OutputLanguageSelect";

export const NUMBER_OF_FIELDS_TO_SHOW_NON_AUTH_USERS = 1;
export const NUMBER_OF_TABS_TO_SHOW_NON_AUTH_USERS = 3;

export type SubscriptionStatus =
  "paid_active" // user is paying and subscription is not canceled
  | "paid_active_limited" // user is paying and subscription is not canceled but requests limit is reached
  | "canceled_active" // subscription was canceled but user still has access until the end of the period
  | "canceled_past_due" // subscription was canceled and user lost access
  | "freemium"; // user is not paying

export type StripeUser = {
  amountTotal?: number
  cancelAtPeriodEnd?: boolean
  cancelAt?: number
  currencyCode?: string
  customerId?: string
  planId?: string
  planInterval?: "month" | "year"
  subscriptionName?: string
};

export type SubscriptionInfo = {
  status: SubscriptionStatus,
  requestsUsed: number,
  requestsLimit: number,
  requestsLeft: number,
  dateStart: Date,
  dateEnd: Date,
  dateCycleStart: Date,
  dateCycleEnd: Date,
}

export type State = {
  url: string;
  summary: string;
  title: string;
  description: string;
  favicon?: string;
  creativity: Creativity;
  activeTabIndex: number;
  session: string;
  stripe?: StripeUser,
  subcription?: SubscriptionInfo,
  isDevMode?: boolean;
  outputLanguage?: OutputLanguageData;
  isOutputLanguageManuallySelected?: boolean;
  tabs: {
    [key: string]: {
      icon?: string;
      fields: {
        [key: string]: {
          name: string;
          description?: string;
          isLoading?: boolean;
          hash?: number;
          content?: string;
        };
      };
    };
  };
};

export const INITIAL_STATE_FULL: State = {
  url: "",
  summary: "",
  title: "",
  description: "",
  favicon: "",
  creativity: "standard",
  activeTabIndex: 0,
  session: "",
  isDevMode: false,
  outputLanguage: undefined,
  isOutputLanguageManuallySelected: false,
  tabs: {
    "Facebook": {
      fields: {
        "FACEBOOK_AD_TEXT_IMG_IDEAS": {
          name: "app.tabs.facebook.FACEBOOK_AD_TEXT_IMG_IDEAS.name",
          description: "app.tabs.facebook.FACEBOOK_AD_TEXT_IMG_IDEAS.description"
        },
        "FACEBOOK_NEED_SPECIFIC_ADS": {
          name: "app.tabs.facebook.FACEBOOK_NEED_SPECIFIC_ADS.name",
          description: "app.tabs.facebook.FACEBOOK_NEED_SPECIFIC_ADS.description"
        },
        "FACEBOOK_BARRIERS": {
          name: "app.tabs.facebook.FACEBOOK_BARRIERS.name",
          description: "app.tabs.facebook.FACEBOOK_BARRIERS.description"
        },
        "FACEBOOK_POSTS": {
          name: "app.tabs.facebook.FACEBOOK_POSTS.name",
          description: "app.tabs.facebook.FACEBOOK_POSTS.description"
        },
        "FACEBOOK_POST_IDEAS": {
          name: "app.tabs.facebook.FACEBOOK_POST_IDEAS.name",
          description: "app.tabs.facebook.FACEBOOK_POST_IDEAS.description"
        }
      }
    },
    "Google": {
      fields: {
        "GOOGLE_ADS_TEXT": {
          name: "app.tabs.google.GOOGLE_ADS_TEXT.name",
          description: "app.tabs.google.GOOGLE_ADS_TEXT.description"
        },
        "GOOGLE_NEED_SPECIFIC_ADS": {
          name: "app.tabs.google.GOOGLE_NEED_SPECIFIC_ADS.name",
          description: "app.tabs.google.GOOGLE_NEED_SPECIFIC_ADS.description"
        },
        "GOOGLE_ADS_BARRIERS": {
          name: "app.tabs.google.GOOGLE_ADS_BARRIERS.name",
          description: "app.tabs.google.GOOGLE_ADS_BARRIERS.description"
        },
        "GOOGLE_ADS_DISPLAY": {
          name: "app.tabs.google.GOOGLE_ADS_DISPLAY.name",
          description: "app.tabs.google.GOOGLE_ADS_DISPLAY.description"
        },
        "GOOGLE_ADS_VIDEO": {
          name: "app.tabs.google.GOOGLE_ADS_VIDEO.name",
          description: "app.tabs.google.GOOGLE_ADS_VIDEO.description"
        }
      }
    },
    "Strategy": {
      fields: {
        "STRATEGY_BARRIERS": {
          name: "app.tabs.strategy.STRATEGY_BARRIERS.name",
          description: "app.tabs.strategy.STRATEGY_BARRIERS.description"
        },
        "STRATEGY_JOBS_TO_BE_DONE": {
          name: "app.tabs.strategy.STRATEGY_JOBS_TO_BE_DONE.name",
          description: "app.tabs.strategy.STRATEGY_JOBS_TO_BE_DONE.description"
        },
        "STRATEGY_CUSTOMER_PERSONA": {
          name: "app.tabs.strategy.STRATEGY_CUSTOMER_PERSONA.name",
          description: "app.tabs.strategy.STRATEGY_CUSTOMER_PERSONA.description"
        },
        "STRATEGY_MARKETING_IDEAS": {
          name: "app.tabs.strategy.STRATEGY_MARKETING_IDEAS.name",
          description: "app.tabs.strategy.STRATEGY_MARKETING_IDEAS.description"
        },
        "STRATEGY_VALUE_PROPOSITION": {
          name: "app.tabs.strategy.STRATEGY_VALUE_PROPOSITION.name",
          description: "app.tabs.strategy.STRATEGY_VALUE_PROPOSITION.description"
        },
        "STRATEGY_POSITIONING": {
          name: "app.tabs.strategy.STRATEGY_POSITIONING.name",
          description: "app.tabs.strategy.STRATEGY_POSITIONING.description"
        },
        "STRATEGY_CONSUMER_DECISION_JOURNEY": {
          name: "app.tabs.strategy.STRATEGY_CONSUMER_DECISION_JOURNEY.name",
          description: "app.tabs.strategy.STRATEGY_CONSUMER_DECISION_JOURNEY.description"
        },
        "STRATEGY_BUSINESS_MODEL_CANVAS": {
          name: "app.tabs.strategy.STRATEGY_BUSINESS_MODEL_CANVAS.name",
          description: "app.tabs.strategy.STRATEGY_BUSINESS_MODEL_CANVAS.description"
        },
        "STRATEGY_VALUE_PROPOSITION_CANVAS": {
          name: "app.tabs.strategy.STRATEGY_VALUE_PROPOSITION_CANVAS.name",
          description: "app.tabs.strategy.STRATEGY_VALUE_PROPOSITION_CANVAS.description"
        }
      }
    },
    "Frameworks": {
      fields: {
        "FRAMEWORKS_STEPPS": {
          name: "app.tabs.frameworks.FRAMEWORKS_STEPPS.name",
          description: "app.tabs.frameworks.FRAMEWORKS_STEPPS.description"
        },
        "FRAMEWORKS_7P_OF_MARKETING": {
          name: "app.tabs.frameworks.FRAMEWORKS_7P_OF_MARKETING.name",
          description: "app.tabs.frameworks.FRAMEWORKS_7P_OF_MARKETING.description"
        },
        "FRAMEWORKS_SWOT": {
          name: "app.tabs.frameworks.FRAMEWORKS_SWOT.name",
          description: "app.tabs.frameworks.FRAMEWORKS_SWOT.description"
        },
        "FRAMEWORKS_PORTERS_FIVE_FORCES": {
          name: "app.tabs.frameworks.FRAMEWORKS_PORTERS_FIVE_FORCES.name",
          description: "app.tabs.frameworks.FRAMEWORKS_PORTERS_FIVE_FORCES.description"
        },
        "FRAMEWORKS_AIDA": {
          name: "app.tabs.frameworks.FRAMEWORKS_AIDA.name",
          description: "app.tabs.frameworks.FRAMEWORKS_AIDA.description"
        },
        "FRAMEWORKS_AARRR": {
          name: "app.tabs.frameworks.FRAMEWORKS_AARRR.name",
          description: "app.tabs.frameworks.FRAMEWORKS_AARRR.description"
        },
        "FRAMEWORKS_LEAN_ANALYTICS_STAGES": {
          name: "app.tabs.frameworks.FRAMEWORKS_LEAN_ANALYTICS_STAGES.name",
          description: "app.tabs.frameworks.FRAMEWORKS_LEAN_ANALYTICS_STAGES.description"
        },
        "FRAMEWORKS_HOOK_FRAMEWORK": {
          name: "app.tabs.frameworks.FRAMEWORKS_HOOK_FRAMEWORK.name",
          description: "app.tabs.frameworks.FRAMEWORKS_HOOK_FRAMEWORK.description"
        },
        "FRAMEWORKS_ICE_SCORE": {
          name: "app.tabs.frameworks.FRAMEWORKS_ICE_SCORE.name",
          description: "app.tabs.frameworks.FRAMEWORKS_ICE_SCORE.description"
        },
        "FRAMEWORKS_ANSOFF_MATRIX": {
          name: "app.tabs.frameworks.FRAMEWORKS_ANSOFF_MATRIX.name",
          description: "app.tabs.frameworks.FRAMEWORKS_ANSOFF_MATRIX.description"
        }
      }
    },
    "Email": {
      fields: {
        "EMAIL_SUBJECT": {
          name: "app.tabs.email.EMAIL_SUBJECT.name",
          description: "app.tabs.email.EMAIL_SUBJECT.description"
        },
        "EMAIL_PROMOTIONAL": {
          name: "app.tabs.email.EMAIL_PROMOTIONAL.name",
          description: "app.tabs.email.EMAIL_PROMOTIONAL.description"
        },
        "EMAIL_MARKETING_TOPICS": {
          name: "app.tabs.email.EMAIL_MARKETING_TOPICS.name",
          description: "app.tabs.email.EMAIL_MARKETING_TOPICS.description"
        }
      }
    },
    "LinkedIn": {
      fields: {
        "LINKEDIN_AD_TEXT_IMG_IDEAS": {
          name: "app.tabs.linkedin.LINKEDIN_AD_TEXT_IMG_IDEAS.name",
          description: "app.tabs.linkedin.LINKEDIN_AD_TEXT_IMG_IDEAS.description"
        },
        "LINKEDIN_NEED_SPECIFIC_ADS": {
          name: "app.tabs.linkedin.LINKEDIN_NEED_SPECIFIC_ADS.name",
          description: "app.tabs.linkedin.LINKEDIN_NEED_SPECIFIC_ADS.description"
        },
        "LINKEDIN_BARRIERS": {
          name: "app.tabs.linkedin.LINKEDIN_BARRIERS.name",
          description: "app.tabs.linkedin.LINKEDIN_BARRIERS.description"
        },
        "LINKEDIN_POSTS": {
          name: "app.tabs.linkedin.LINKEDIN_POSTS.name",
          description: "app.tabs.linkedin.LINKEDIN_POSTS.description"
        },
        "LINKEDIN_POST_IDEAS": {
          name: "app.tabs.linkedin.LINKEDIN_POST_IDEAS.name",
          description: "app.tabs.linkedin.LINKEDIN_POST_IDEAS.description"
        }
      }
    },
    "Instagram": {
      fields: {
        "INSTAGRAM_AD_TEXT_IMG_IDEAS": {
          name: "app.tabs.instagram.INSTAGRAM_AD_TEXT_IMG_IDEAS.name",
          description: "app.tabs.instagram.INSTAGRAM_AD_TEXT_IMG_IDEAS.description"
        },
        "INSTAGRAM_CAPTIONS": {
          name: "app.tabs.instagram.INSTAGRAM_CAPTIONS.name",
          description: "app.tabs.instagram.INSTAGRAM_CAPTIONS.description"
        },
        "INSTAGRAM_POST_IDEAS": {
          name: "app.tabs.instagram.INSTAGRAM_POST_IDEAS.name",
          description: "app.tabs.instagram.INSTAGRAM_POST_IDEAS.description"
        },
        "INSTAGRAM_BARRIERS": {
          name: "app.tabs.instagram.INSTAGRAM_BARRIERS.name",
          description: "app.tabs.instagram.INSTAGRAM_BARRIERS.description"
        },
        "INSTAGRAM_NEED_SPECIFIC_ADS": {
          name: "app.tabs.instagram.INSTAGRAM_NEED_SPECIFIC_ADS.name",
          description: "app.tabs.instagram.INSTAGRAM_NEED_SPECIFIC_ADS.description"
        },
        "INSTAGRAM_JOKES_AND_STORIES": {
          name: "app.tabs.instagram.INSTAGRAM_JOKES_AND_STORIES.name",
          description: "app.tabs.instagram.INSTAGRAM_JOKES_AND_STORIES.description"
        },
        "INSTAGRAM_HASHTAG": {
          name: "app.tabs.instagram.INSTAGRAM_HASHTAG.name",
          description: "app.tabs.instagram.INSTAGRAM_HASHTAG.description"
        },
        "INSTAGRAM_ADS_VIDEO": {
          name: "app.tabs.instagram.INSTAGRAM_ADS_VIDEO.name",
          description: "app.tabs.instagram.INSTAGRAM_ADS_VIDEO.description"
        }
      }
    },
    "TikTok": {
      fields: {
        "TIKTOK_VIDEO": {
          name: "app.tabs.tiktok.TIKTOK_VIDEO.name",
          description: "app.tabs.tiktok.TIKTOK_VIDEO.description"
        },
        "TIKTOK_CONTENT_STRATEGY": {
          name: "app.tabs.tiktok.TIKTOK_CONTENT_STRATEGY.name",
          description: "app.tabs.tiktok.TIKTOK_CONTENT_STRATEGY.description"
        },
        "TIKTOK_HASHTAGS": {
          name: "app.tabs.tiktok.TIKTOK_HASHTAGS.name",
          description: "app.tabs.tiktok.TIKTOK_HASHTAGS.description"
        },
        "TIKTOK_BIO": {
          name: "app.tabs.tiktok.TIKTOK_BIO.name",
          description: "app.tabs.tiktok.TIKTOK_BIO.description"
        }
      }
    },
    "PR": {
      fields: {
        "PR_LIST_OF_MEDIA_TO_USE_FOR_PROMO": {
          name: "app.tabs.pr.PR_LIST_OF_MEDIA_TO_USE_FOR_PROMO.name",
          description: "app.tabs.pr.PR_LIST_OF_MEDIA_TO_USE_FOR_PROMO.description"
        },
        "PR_CAMPAIGN_IDEAS": {
          name: "app.tabs.pr.PR_CAMPAIGN_IDEAS.name",
          description: "app.tabs.pr.PR_CAMPAIGN_IDEAS.description"
        },
        "PR_ARTICLE_IDEAS": {
          name: "app.tabs.pr.PR_ARTICLE_IDEAS.name",
          description: "app.tabs.pr.PR_ARTICLE_IDEAS.description"
        },
        "PR_GUEST_ARTICLE": {
          name: "app.tabs.pr.PR_GUEST_ARTICLE.name",
          description: "app.tabs.pr.PR_GUEST_ARTICLE.description"
        }
      }
    },
    "YouTube": {
      fields: {
        "YOUTUBE_TITLES_PROMO_VIDEO": {
          name: "app.tabs.youtube.YOUTUBE_TITLES_PROMO_VIDEO.name",
          description: "app.tabs.youtube.YOUTUBE_TITLES_PROMO_VIDEO.description"
        },
        "YOUTUBE_VIDEO_IDEAS": {
          name: "app.tabs.youtube.YOUTUBE_VIDEO_IDEAS.name",
          description: "app.tabs.youtube.YOUTUBE_VIDEO_IDEAS.description"
        },
        "YOUTUBE_KEYWORDS": {
          name: "app.tabs.youtube.YOUTUBE_KEYWORDS.name",
          description: "app.tabs.youtube.YOUTUBE_KEYWORDS.description"
        },
        "YOUTUBE_ADS_VIDEO": {
          name: "app.tabs.youtube.YOUTUBE_ADS_VIDEO.name",
          description: "app.tabs.youtube.YOUTUBE_ADS_VIDEO.description"
        }
      }
    },
    "Blog": {
      fields: {
        "BLOG_IDEAS": {
          name: "app.tabs.blog.BLOG_IDEAS.name",
          description: "app.tabs.blog.BLOG_IDEAS.description"
        },
        "BLOG_PROMO_ARTICLE": {
          name: "app.tabs.blog.BLOG_PROMO_ARTICLE.name",
          description: "app.tabs.blog.BLOG_PROMO_ARTICLE.description"
        },
        "BLOG_KEYWORDS": {
          name: "app.tabs.blog.BLOG_KEYWORDS.name",
          description: "app.tabs.blog.BLOG_KEYWORDS.description"
        },
        "BLOG_FAQ": {
          name: "app.tabs.blog.BLOG_FAQ.name",
          description: "app.tabs.blog.BLOG_FAQ.description"
        }
      }
    },
    "SEO": {
      fields: {
        "SEO_COMMON_KEYWORDS": {
          name: "app.tabs.seo.SEO_COMMON_KEYWORDS.name",
          description: "app.tabs.seo.SEO_COMMON_KEYWORDS.description"
        },
        "SEO_LONG_TAIL_KEYWORDS": {
          name: "app.tabs.seo.SEO_LONG_TAIL_KEYWORDS.name",
          description: "app.tabs.seo.SEO_LONG_TAIL_KEYWORDS.description"
        },
        "SEO_META_TITLE_AND_DESCRIPTION": {
          name: "app.tabs.seo.SEO_META_TITLE_AND_DESCRIPTION.name",
          description: "app.tabs.seo.SEO_META_TITLE_AND_DESCRIPTION.description"
        }
      }
    },
    "Branding": {
      fields: {
        "BRAND_GUIDE": {
          name: "app.tabs.branding.BRAND_GUIDE.name",
          description: "app.tabs.branding.BRAND_GUIDE.description"
        }
      }
    }
  }
};


//  update old state s1 with new state s2 by injecting non-empty fields from existing tabs
function switchStateToSignedIn(stateOld: State, stateNew: State): State {
  const stateNewCopy: State = JSON.parse(JSON.stringify(stateNew));

  const fieldsToKeep = {
    url: stateOld.url,
    summary: stateOld.summary,
    favicon: stateOld.favicon,
    creativity: stateOld.creativity,
    activeTabIndex: stateOld.activeTabIndex,
    session: stateOld.session,
    stripe: stateOld.stripe,
    subcription: stateOld.subcription,
    isDevMode: stateOld.isDevMode,
    outputLanguage: stateOld.outputLanguage,
    isOutputLanguageManuallySelected: stateOld.isOutputLanguageManuallySelected
  };

  const mergedState: State = {
    ...stateNewCopy,
    ...fieldsToKeep,
    tabs: {
      ...stateNewCopy.tabs
    }
  };


  Object.keys(stateOld.tabs).forEach((tabKey) => {
    const tab = stateOld.tabs[tabKey];
    const mergedFields = Object.assign({}, mergedState.tabs[tabKey].fields);

    Object.keys(tab.fields).forEach((fieldKey) => mergedFields[fieldKey] = { ...tab.fields[fieldKey] });

    mergedState.tabs[tabKey].fields = mergedFields;
  });

  return mergedState;
}

// Removing all tabs fields except the first two for each tab
function switchStateToSignedOut(state: State): State {
  const {
    url,
    summary,
    favicon,
    creativity,
    activeTabIndex,
    session,
    isDevMode,
    title,
    description,
    outputLanguage,
    isOutputLanguageManuallySelected
  } = state;

  return {
    url,
    summary,
    title,
    description,
    favicon,
    creativity,
    activeTabIndex,
    session,
    isDevMode,
    outputLanguage,
    isOutputLanguageManuallySelected,
    tabs: mapValues(state.tabs, tab => ({
      ...tab,
      fields: pickBy(tab.fields, (_, key) => {
        const fieldKeys = Object.keys(tab.fields);
        return fieldKeys.indexOf(key) < NUMBER_OF_FIELDS_TO_SHOW_NON_AUTH_USERS;
      })
    }))
  };
}

function switchStateToExpiredSubscription(state: State): State {
  return {
    ...state,
    tabs: mapValues(state.tabs, tab => ({
      ...tab,
      fields: pickBy(tab.fields, (_, key) => {
        const fieldKeys = Object.keys(tab.fields);
        return fieldKeys.indexOf(key) < NUMBER_OF_FIELDS_TO_SHOW_NON_AUTH_USERS;
      })
    }))
  };
}

// Include only the first two fields per tab
export const INITIAL_STATE_DEMO = {
  ...INITIAL_STATE_FULL,
  tabs: mapValues(INITIAL_STATE_FULL.tabs, tab => ({
    ...tab,
    fields: pickBy(tab.fields, (_, key) => {
      const fieldKeys = Object.keys(tab.fields);
      return fieldKeys.indexOf(key) < NUMBER_OF_FIELDS_TO_SHOW_NON_AUTH_USERS;
    })
  }))
};

export const stateSlice = createSlice({
  name: "appState",
  initialState: INITIAL_STATE_DEMO,
  reducers: {
    resetState: (state, action: PayloadAction<{
      isRegistered: boolean,
    }>) => action.payload.isRegistered
      ? switchStateToSignedIn(state, INITIAL_STATE_FULL)
      : switchStateToSignedOut(state),
    updateStateLoading: (state, action: PayloadAction<{
      field: string,
      isLoading: boolean
    }>) => {
      const { field, isLoading } = action.payload;
      // find tab key by filed name
      const tab = Object.keys(state.tabs).find(tab => !!state.tabs[tab].fields[field]) || keys(state.tabs)[state?.activeTabIndex];
      get(get(state.tabs, tab)?.fields, field).isLoading = isLoading;
    },
    updateActiveTabIndex: (state, action: PayloadAction<number>) => {
      state.activeTabIndex = action.payload;
    },
    updateContent: (state, action: PayloadAction<{
      field: string,
      content: string,
      hash: number
    }>) => {
      const { field, content, hash } = action.payload;
      // find tab key by filed name
      const tab = Object.keys(state.tabs).find(tab => !!state.tabs[tab].fields[field]) || keys(state.tabs)[state?.activeTabIndex];
      const fieldObject = get(get(state.tabs, tab)?.fields, field);
      fieldObject.content = content;
      fieldObject.isLoading = false;
      fieldObject.hash = hash;
    },
    updateWebsiteDetails: (state, action: PayloadAction<{
      url: string,
      summary: string,
      title: string,
      description: string,
      favicon: string,
    }>) => {
      const { url, summary, favicon, title, description } = action.payload;
      state.url = url;
      state.summary = summary;
      state.favicon = favicon;
      state.title = title;
      state.description = description;
    },
    updateCreativity: (state, action: PayloadAction<Creativity>) => {
      state.creativity = action.payload;
    },
    updateStripeUser: (state, action: PayloadAction<StripeUser>) => {
      state.stripe = action.payload;
    },
    updateSubscriptionInfo: (state, action: PayloadAction<SubscriptionInfo>) => {
      state.subcription = action.payload;
    },
    updateSession: (state, action: PayloadAction<string>) => {
      state.session = action.payload;
    },
    switchStateToExpiredSubscriptionReducer: (state) => switchStateToExpiredSubscription(state),
    updateOutputLanguage: (state, action: PayloadAction<OutputLanguageData>) => {
      state.outputLanguage = action.payload;
    },
    setOutputLanguageManuallySelected: (state, action: PayloadAction<boolean>) => {
      state.isOutputLanguageManuallySelected = action.payload;
    }
  }
});

export const {
  resetState,
  updateContent,
  updateStateLoading,
  updateActiveTabIndex,
  updateWebsiteDetails,
  updateCreativity,
  updateStripeUser,
  updateSubscriptionInfo,
  updateSession,
  switchStateToExpiredSubscriptionReducer,
  updateOutputLanguage,
  setOutputLanguageManuallySelected
} = stateSlice.actions;

export const stateReducer = stateSlice.reducer;
