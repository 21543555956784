import Popup from "../../icons/popup.webp";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button, List,
  ListItem,
  ListItemText,
  styled,
  Theme,
  Typography,
  useMediaQuery
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { isUserRegisteredAsync, registerWithPopupAsync } from "../../utils/auth";
import { useTranslation } from "react-i18next";

export function UpgradePopup() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const triggerSignInWithPopup = async () => {
    if (await isUserRegisteredAsync()) {
      navigate("/profile");
      return;
    } else {
      try {
        await registerWithPopupAsync();
        navigate("/profile");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    "&::before": {
      content: "\"•\"",
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main
    }
  }));

  const features = [
    t('website.upgradePopup.features.feature1'),
    t('website.upgradePopup.features.feature2'),
    t('website.upgradePopup.features.feature3'),
    t('website.upgradePopup.features.feature4')
  ];


  return <Grid container direction={"column"} id={"aiter-upgrade-popup"}
               sx={{
                 zIndex: 2000,
                 position: "fixed",
                 top: "50%",
                 left: "50%",
                 transform: "translate(-50%, -50%)",
                 filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))",
                 display: "none"
               }}>
    <Grid sx={{ zIndex: 2000 }}>
      <img style={{ maxWidth: isDesktop ? 400 : 300 }} src={Popup} alt={"Popup"} />
    </Grid>
    <Grid sx={{
      bgcolor: "white", pt: 18, pb: 4, px: 2, mt: -14,
      borderRadius: 4,
      textAlign: "-webkit-center",
      width: isDesktop ? 400 : "100%"
    }}>
      <Typography variant={"h6"}>
        {t("website.upgradePopup.title")}
      </Typography>

      <Typography variant={"body1"} sx={{mb: 3}}>
        {t("website.upgradePopup.header")}
      </Typography>

      <Typography variant="subtitle1" fontWeight="bold">
        {t("website.upgradePopup.features.title")}
      </Typography>

      <List dense disablePadding sx={{mx: 2}}>
        {features.map((feature, index) => (
          <StyledListItem key={index}>
            <ListItemText primary={feature} />
          </StyledListItem>
        ))}
      </List>

      <Box mt={3}>
        <Typography variant="body2" fontStyle="italic">
          {t("website.upgradePopup.footer")}
        </Typography>
      </Box>

      <Button variant={"contained"} color="success"
              onClick={triggerSignInWithPopup} sx={{
        m: 2,
        display: "block",
        textTransform: "none",
        fontWeight: 600,
        minWidth: 150
      }}>
        {t("website.upgradePopup.button")}
      </Button>
      <Button sx={{ color: grey[600] }} onClick={() => hideUpgradePopup()}>
        {t("website.upgradePopup.cancel")}
      </Button>
    </Grid>
  </Grid>;
}

export function showUpgradePopup() {
  const popup = document.getElementById("aiter-upgrade-popup");
  if (popup) {
    popup.style.display = "block";
  }
}

export function hideUpgradePopup() {
  const popup = document.getElementById("aiter-upgrade-popup");
  if (popup) {
    popup.style.display = "none";
  }
}
