import * as React from "react";
import LinkedinWhite from "../../icons/LinkedIn White.webp";
import TwitterWhite from "../../icons/Twitter White.webp";
import FacebookWhite from "../../icons/Facebook White.webp";
import { IconButtonComponent } from "./IconButton";
import {
  trackClickBannerTheresanaiforthatFooter,
  trackClickCookiePolicy,
  trackClickFacebookFooter,
  trackClickLinkedinFooter,
  trackClickPrivacyPolicy,
  trackClickTermsOfService,
  trackClickTwitterFooter
} from "../../utils/analytics";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TAAFT_URL } from "./BadgesSectionComponent";

export default function FooterComponent() {
  const { t } = useTranslation();

  return <Grid container xs={12} justifyContent={"center"} sx={{
    mt: { xs: "20%", sm: "10%" },
    width: "100%",
    p: { xs: 0, sm: 8 },
    py: { xs: 4, sm: 8 },
    backgroundImage: "linear-gradient(270.17deg, #A57DD3 3.88%, #3A6CFF 98.08%)"
  }}>
    <Grid container sm={12} md={10} lg={7} gap={{ xs: 4, sm: 2 }}
          direction={{ xs: "column", md: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "center",
          }}>
      <Grid xs={12} sm={2}>
        <Typography variant="h4" sx={{
          display: "flex",
          fontFamily: "roboto",
          fontWeight: 700,
          textDecoration: "none",
          color: "white",
          justifyContent: "center",
        }}>
          {t("website.footer.companyName")}
        </Typography>
      </Grid>
      <Grid xs={12} sm={true} container direction={"column"}>
        <Grid xs>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              fontFamily: "roboto",
              textDecoration: "none",
              color: "white",
              justifyContent: "center",
              textAlign: "center"
            }}>
            {t("website.footer.copyright")}
          </Typography>
        </Grid>
        <Grid xs sx={{ textAlign: "center" }}>
          <Typography
            variant="body2"
            sx={{
              justifyContent: "center",
              textAlign: "center",
              "& a": {
                fontFamily: "roboto",
                textDecoration: "none",
                color: grey[300],
                display: "inline-block"
              }
            }}>
            <Link href={"/privacy"} target={"_blank"} onClick={() => {
              trackClickPrivacyPolicy();
            }}>{t("website.footer.privacyPolicy")} •
            </Link> <Link href={"/terms"} target={"_blank"} onClick={() => {
            trackClickTermsOfService();
          }}>{t("website.footer.termsOfService")} •
          </Link> <Link href={"/cookies"} target={"_blank"} onClick={() => {
            trackClickCookiePolicy();
          }}>{t("website.footer.cookiePolicy")}</Link>
          </Typography>
          <Typography variant={"caption"}>
            <Link href={"mailto:hello@aiter.io"} target={"_blank"}
                  sx={{
                    textAlign: "center",
                    fontFamily: "roboto",
                    color: grey[100]
                  }}>
              hello@aiter.io
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={8} sm={3} container direction={"column"}>
        <Grid container justifyContent={"center"}>
          <Grid xs={12}>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                fontFamily: "roboto",
                textDecoration: "none",
                color: "white",
                textAlign: "center",
                justifyContent: "flex-end"
              }}>
              <div dangerouslySetInnerHTML={{ __html: t("website.footer.followUs") }} />
            </Typography>
          </Grid>
          <Grid>
            <Box alignSelf={"end"} sx={{ flexGrow: 1 }}>
              <IconButtonComponent icon={LinkedinWhite}
                                   callback={trackClickLinkedinFooter}
                                   url={"https://www.linkedin.com/company/aiter-io"} />
              <IconButtonComponent icon={TwitterWhite}
                                   callback={trackClickTwitterFooter}
                                   url={"https://twitter.com/aiter_io"} />
              <IconButtonComponent icon={FacebookWhite}
                                   callback={trackClickFacebookFooter}
                                   url={"https://www.facebook.com/aiter.tool/"} />
            </Box>
          </Grid>
        </Grid>
        <Grid mt={1}>
          <a href={TAAFT_URL}
             onClick={() => {
               trackClickBannerTheresanaiforthatFooter();
             }}
             target="_blank">
            <img style={{ width: 200, filter: "invert(1)" }}
                 alt={t("website.footer.featuredOnBannerImageAlt")}
                 src="https://media.theresanaiforthat.com/featured6.png" />
          </a>
        </Grid>
      </Grid>
    </Grid>
  </Grid>;
}
