import React, { useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  SubscriptionStatus,
  switchStateToExpiredSubscriptionReducer,
  updateSession,
  updateStripeUser,
  updateSubscriptionInfo
} from "./utils/state";
import { signInUserAnonymouslyAsync } from "./utils/auth";
import { useFirebaseLoginState } from "./utils/hooks";
import { trackShowSupportChat, trackSignIn } from "./utils/analytics";
import { isAmplitudeUserIdSet, setPlanName, setRequestsUsed } from "./utils/amplitude";
import { register } from "./utils/api";
import { firebaseTimestampToDate } from "./utils/dateUtils";
import { useListenUserChanges, useUserCodes } from "./utils/db";
import {
  IS_LOCAL_BASE_URL,
  IS_PROD_BASE_URL,
  STRIPE_URL_MANAGE_PLAN_DEV,
  STRIPE_URL_MANAGE_PLAN_PROD
} from "./utils/env";
import { firebaseRemoteConfig } from "./utils/firebaseUtils";
import LandingPage from "./screens/LandingPage";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import CookiePolicy from "./screens/CookiePolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import Profile from "./screens/Profile";
import "./utils/translations";
import ThankYouPage from "./screens/ThankYouPage";
import AppSumo from "./screens/AppSumo";
import { hideCrispChat, showCrispChat } from "./utils/crispUtils"; // Import the i18n configuration
import * as Sentry from "@sentry/react";

export const STRIPE_URL_MANAGE_PLAN = IS_PROD_BASE_URL ? STRIPE_URL_MANAGE_PLAN_PROD : STRIPE_URL_MANAGE_PLAN_DEV;

export default function App() {
  const dispatch = useDispatch();

  const { isRegistered, user, userId, isSignedIn, isAnonymous } = useFirebaseLoginState();

  const signInExecuted = useRef(false);

  const { dbUser, loading, error } = useListenUserChanges(userId);
  const appSumoCodes = useUserCodes(user?.uid);

  useEffect(() => {
    if (IS_LOCAL_BASE_URL && !window.emulatorStarted) {
      window.emulatorStarted = true;
      connectFirestoreEmulator(getFirestore(), "127.0.0.1", 8888);
      connectAuthEmulator(getAuth(), "http://localhost:9999");
    }

    firebaseRemoteConfig.settings.minimumFetchIntervalMillis = IS_LOCAL_BASE_URL
      ? 10000 // 10 seconds
      : 3600000; // 1 hour

    moment.locale("en");

    if (!signInExecuted.current) {
      signInUserAnonymouslyAsync();
      signInExecuted.current = true;
    }

    dispatch(updateSession("session_" + moment().format("YYYY-MM-DD_HH-mm-ss")));

  }, []);

  useEffect(() => {
    if (!!userId) {
      if (!isAmplitudeUserIdSet()) {
        trackSignIn(userId, isRegistered);
      }
      register(userId)
        .then(undefined, (error) => {
          console.error("Error registering", error);
        });
    }
  }, [isRegistered]);

  useEffect(() => {
    if (!!user) {
      Sentry.setUser({
        id: userId,
        email: user?.email || undefined,
        username: user?.displayName || undefined
      });
    } else {
      Sentry.getGlobalScope().clear();
    }
  }, [user]);

  useEffect(() => {
    if (dbUser?.stripe) {
      dispatch(updateStripeUser({
        amountTotal: dbUser?.stripe?.amount_total,
        cancelAtPeriodEnd: dbUser?.stripe?.cancel_at_period_end,
        cancelAt: dbUser?.stripe?.cancel_at,
        currencyCode: dbUser?.stripe?.currency_code,
        customerId: dbUser?.stripe?.customer_id,
        planId: dbUser?.stripe?.plan_id,
        planInterval: dbUser?.stripe?.plan_interval,
        subscriptionName: dbUser?.stripe?.subscription_name
      }));
      setPlanName(dbUser?.stripe?.subscription_name);
    }
  }, [JSON.stringify(dbUser?.stripe)]);

  useEffect(() => {
    if (dbUser?.subscription) {
      const data = {
        status: dbUser?.subscription?.status as SubscriptionStatus,
        requestsUsed: dbUser?.subscription?.requests_used,
        requestsLeft: dbUser?.subscription?.requests_left,
        requestsLimit: dbUser?.subscription?.requests_limit,
        dateStart: moment(firebaseTimestampToDate((dbUser?.subscription?.start_date))).toDate(),
        dateEnd: moment(firebaseTimestampToDate(dbUser?.subscription?.end_date)).toDate(),
        dateCycleEnd: moment(firebaseTimestampToDate(dbUser?.subscription?.cycle_end_date)).toDate(),
        dateCycleStart: moment(firebaseTimestampToDate(dbUser?.subscription?.cycle_start_date)).toDate()
      };
      setRequestsUsed(dbUser?.subscription?.requests_used);
      dispatch(updateSubscriptionInfo(data));
      if (data?.status !== "freemium" && data?.requestsLeft === 0) {
        dispatch(switchStateToExpiredSubscriptionReducer());
      }
    }
  }, [JSON.stringify(dbUser?.subscription)]);

  useEffect(() => {
    // user is logged in and has a subscription
    if (appSumoCodes?.length || (!!dbUser?.subscription?.status && dbUser?.subscription?.status !== "freemium" && !!userId)) {
      // @ts-ignore
      showCrispChat();
      trackShowSupportChat(`${userId}`, true);
    } else {
      // @ts-ignore
      hideCrispChat();
      trackShowSupportChat(`${userId}`, false);
    }
  }, [dbUser?.subscription?.status, userId]);


  function RedirectToHtml() {
    useEffect(() => {
      // move the user to the html page
      window.location.href = "/guide-to-ai-marketing-with-aiter.html";
    }, []);

    // Return null as nothing needs to be rendered
    return null;
  }

  return <BrowserRouter>
    <Routes>
      <Route path="*" element={<LandingPage />} />
      <Route path="guide-to-ai-marketing-with-aiter" element={<RedirectToHtml />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      <Route path="cookies" element={<CookiePolicy />} />
      <Route path="terms" element={<TermsAndConditions />} />
      <Route path="profile" element={<Profile />} />
      <Route path="thankyou" element={<ThankYouPage />} />
      <Route path="appsumo" element={<AppSumo />} />
    </Routes>
  </BrowserRouter>;
}
