import React from "react";
import { Box, styled, Typography, useTheme } from "@mui/material";
import { InfoIcon, InfoTooltipView } from "../InfoTooltipView";
import { trackShowTooltipManualMode } from "../../utils/analytics";
import { useTranslation } from "react-i18next";

const BoxContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: 14,
  height: 56,
  width: 432,
  display: "flex",
  alignItems: "center"
}));

const BoxChild = styled(Box)(({ theme }) => ({
  borderRadius: 12,
  margin: "2px",
  height: "calc(100% - 4px)",
  width: "50%",
  display: "flex",
  justifyContent: "center"
}));

export type Mode = "url" | "manual";

export function SwitchComponent({ mode, onModeChanged }: {
  mode: Mode,
  onModeChanged: (mode: Mode) => void
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return <BoxContainer>
    <BoxChild onClick={() => onModeChanged("url")}
              sx={{
                cursor: mode === "url" ? "default" : "pointer",
                bgcolor: mode === "url" ? "white" : theme.palette.primary.light,
                transition: "background-color 0.1s ease-in-out"
              }}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6" sx={{ fontSize: { xs: 14, sm: 18 } }}>
          {t("app.labels.switchUrlLabel")}
        </Typography>
      </Box>
    </BoxChild>
    <BoxChild onClick={() => onModeChanged("manual")}
              sx={{
                cursor: mode === "url" ? "pointer" : "default",
                bgcolor: mode === "url" ? theme.palette.primary.light : "white",
                transition: "background-color 0.1s ease-in-out"
              }}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="h6" sx={{ flexGrow: 0, fontSize: { xs: 14, sm: 18 } }}>
          {t("app.labels.switchManualInputLabel")}
        </Typography>
        <Box sx={{ flexGrow: 0, pl: 0.5, mt: 0.4 }}>
          <InfoTooltipView
            onTooltipOpenCallback={trackShowTooltipManualMode}
            text={t("app.labels.switchTooltipText")}
            icon={<InfoIcon color={"disabled"} />} />
        </Box>
      </Box>
    </BoxChild>
  </BoxContainer>;
}
