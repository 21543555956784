import { LoadingButton } from "@mui/lab";
import { Replay } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export function RegenerateButton({
                                   onRegenerateClicked,
                                   isLoading
                                 }: {
  onRegenerateClicked: () => void,
  isLoading: boolean
}) {
  const { t } = useTranslation();

  return (
    <LoadingButton variant="outlined"
                   color={"primary"}
                   startIcon={<Replay />}
                   onClick={onRegenerateClicked}
                   loading={isLoading}
                   disabled={isLoading}
                   sx={{
                     mt: 1,
                     border: isLoading ? 0 : 1,
                     borderColor: isLoading ? "secondary.light" : "primary.main"
                   }}>
      {t("app.buttons.regenerate")}
    </LoadingButton>
  );
}
