export function scrollToTop(onScrollFinished: (() => void) | undefined) {
  const handleScroll = () => {
    if (window.scrollY === 0) {
      if (!!onScrollFinished) onScrollFinished();
      window.removeEventListener("scroll", handleScroll);
    }
  };

  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });

  window.addEventListener("scroll", handleScroll);
  handleScroll();
}

