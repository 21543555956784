import React from "react";
import { Box, Button, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import bg from "../icons/Hexagon.svg";
import { GoogleAuthButton } from "../components/website/GoogleAuthButton";
import { InfoTooltipView } from "../components/InfoTooltipView";
import { useFirebaseLoginState } from "../utils/hooks";
import { redeemAppsumoCode } from "../utils/api";
import { LoadingButton } from "@mui/lab";
import { green } from "@mui/material/colors";
import { trackSentryError } from "../utils/analytics";

export default function AppSumo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme(); // useTheme hook to access the theme

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const { isRegistered, user, isAuthStateLoading } = useFirebaseLoginState();

  const [code, setCode] = React.useState("");
  const [isButtonStateLoading, setIsButtonStateLoading] = React.useState(false);
  const [codeError, setCodeError] = React.useState<string|undefined>();

  const profilePageUrl = "/profile" + window.location.search;

  const onSubmitCodeClick = () => {
    setIsButtonStateLoading(true);
    setCodeError(undefined);

    redeemAppsumoCode(user?.uid, code)
      .then((req) => {
        setIsButtonStateLoading(false);
        navigate(profilePageUrl);
      })
      .catch((error) => {
        trackSentryError(`Error redeeming code`,{
          code: code,
          error: error.message
        });
        setIsButtonStateLoading(false);
        setCodeError(JSON.parse(error.message).message);
      });
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      backgroundImage: `url(${bg})`, // Correctly formatted background image
      backgroundSize: "cover", // Ensure the image covers the box
      backgroundPosition: "center" // Center the background image
    }}>
      <Grid container justifyContent="center" textAlign="center" gap={4}
            sx={{
              backgroundColor: "#F1F1FB",
              width: isDesktop ? 600 : "90%",
              borderRadius: 2,
              p: 4
            }}>
        <Grid xs={12}>
          <Typography variant={isDesktop ? "h3" : "h5"}>
            {t("website.appsumoPage.title")}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant={"h6"}>
            {t("website.appsumoPage.subtitle_1")}
          </Typography>
          <Typography variant={"body1"}>
            {t("website.appsumoPage.subtitle_2")}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <TextField
            label={t('website.profile.appsumoAppsumoCode')} variant="outlined" sx={{ minWidth: isDesktop ? 300 : "100%" }}
            placeholder={t('website.profile.appsumoCodeExample')} value={code}
            error={!!codeError} helperText={codeError}
            onChange={(e) => setCode(e.target.value)} />
        </Grid>
        {isRegistered
          ? <Grid xs={12} sx={{mt: -2}}>
            <LoadingButton
              onClick={onSubmitCodeClick} variant="contained" color={"success"}
              loading={isButtonStateLoading} size={"large"}
              sx={{
                ...(isButtonStateLoading && {
                  background: `${green[100]} !important`
                })
              }}>
              {t("website.appsumoPage.submitCode")}
            </LoadingButton>
          </Grid>
          : <Grid xs={12}>
            {!code
              ? <InfoTooltipView text={"Enter the code to enable login"} icon={
                <Button disabled sx={{ opacity: 0.5 }}>
                  <GoogleAuthButton />
                </Button>
              } />
              : <GoogleAuthButton />}
          </Grid>}
      </Grid>
    </Box>
  );
}
