import { filter, maxBy } from "lodash";

export interface AppsumoPlan {
  id: number;
  name: string;  // shown in the UI
  minCodesToRedeem: number; // incluseive
  reqPerMonth: number; // optional
}

export const PLANS_APPSUMO: AppsumoPlan[] = [
  {
    id: 1,
    name: "AppSumo Begin Plan (Lifetime Access)",
    minCodesToRedeem: 1,
    reqPerMonth: 25
  },
  {
    id: 2,
    name: "AppSumo Advance Plan (Lifetime Access)",
    minCodesToRedeem: 2,
    reqPerMonth: 100
  },
  {
    id: 3,
    name: "AppSumo Conquer Plan (Lifetime Access)",
    minCodesToRedeem: 4,
    reqPerMonth: 500
  }
];

export const getPlanByNumberOfCodesRedeemed = (numberOfCodes: number): AppsumoPlan | undefined => {
  return maxBy(filter(
    PLANS_APPSUMO,
    (plan) => plan.minCodesToRedeem <= numberOfCodes
  ), "minCodesToRedeem");
};

