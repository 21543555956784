import { marked } from "marked";

export function markdownToPlainText(markdown: string): string {
  // Convert markdown string to HTML string using marked
  const html: string = <string>marked.parse(markdown);

  // Create a temporary DOM element to insert the HTML content
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Extract and return the text content from the temporary DOM element
  // This effectively removes all HTML tags and returns only the text
  return tempDiv.textContent || tempDiv.innerText || "";
}


function hashCode(str: string) {
  let hash = 0, i = 0, len = str.length;
  while (i < len) {
    hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
  }
  return hash + 2147483647 + 1;
}

export function hashInputs(summary?: string, creativity?: string, url?: string, language?: string) {
  return hashCode(`${summary?.trim()}${creativity?.trim()}${url?.trim()}${language?.trim()}`);
}

export function isTextWithMarkdown(text: string): boolean {
  // Regex to check for Markdown headers
  const isMarkdownHeader = /^\s*(#+)\s.*$/mg;

  // Regex to check for Markdown tables (with headers, divider, and rows)
  const isMarkdownTable = /(^\|.*\|\s*$\n?^\|[-:]+[-| :]*$\n?^\|.*\|\s*$)/mg.test(text);

  // Regex to check for Markdown bold text
  const isMarkdownBold = /^.*\*\*.*?\*\*.*/gm.test(text);

  // Return true if any of the Markdown features are detected
  const isMarkdown = isMarkdownHeader.test(text) || isMarkdownTable || isMarkdownBold;

  if (text.includes("Transform Your Workflow with Notion Today!**")) {
    console.log(`isMarkdown: ${isMarkdown} | isMarkdownHeader: ${isMarkdownHeader.test(text)} | isMarkdownTable: ${isMarkdownTable} | isMarkdownBold: ${isMarkdownBold}`);
    console.log(text);
  }
  return isMarkdown;
}

export function cleanMarkdownAndFormat(text: string): string {
  if (!text.trim()) {
    return "";
  }

  // Regex to match all Markdown headers
  const headerRegex = /^#+/gm;

  // Replace all Markdown headers with two hashes
  const replacedText = text.replace(headerRegex, "###");

  // Remove all code blocks (lines starting with three backticks)
  return replacedText.replace(/^```.*$/gm, "");

}

export function postProcessSummary(summary: string) {
  if (!summary.trim()) {
    return "";
  }

  // if summary has markdown, convert ## and # into ###
  if (isTextWithMarkdown(summary)) {
    return cleanMarkdownAndFormat(summary);
  } else {
    // replace "Marketing brief" with "### Marketing Brief" on the first line
    return summary.replace(/^Marketing brief/gm, "### Marketing Brief");
  }
}
