import {
  getAuth,
  GoogleAuthProvider,
  linkWithPopup,
  onAuthStateChanged,
  signInAnonymously,
  signInWithCredential,
  User
} from "firebase/auth";


export async function signOutUser() {
  const auth = await getFirebaseAuthAsync();
  await auth.signOut();
  window.location.reload();
}

export function isUserSignedIn(user?: User | null) {
  return !!user;
}

export function isUserAnonymous(user?: User | null) {
  return user?.isAnonymous;
}

export function isUserRegistered(user?: User | null) {
  return !!(user && user.email);
}

export async function isUserSignedInAsync() {
  const auth = await getFirebaseAuthAsync();
  return isUserSignedIn(auth.currentUser);
}

export async function isUserAnonymousAsync() {
  const auth = await getFirebaseAuthAsync();
  return isUserAnonymous(auth.currentUser);
}

export async function isUserRegisteredAsync() {
  const auth = await getFirebaseAuthAsync();
  return isUserRegistered(auth.currentUser);
}

export async function getCurrentUserAsync() {
  const auth = await getFirebaseAuthAsync();
  return auth.currentUser;
}

export async function getFirebaseAuthAsync() {
  const auth = getAuth();
  await new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      user => {
        resolve(user);  // Resolves the promise when the auth state is ready.
        unsubscribe();  // Unsubscribe the observer.
      },
      error => reject(error)  // Rejects the promise if an error occurs.
    );
  });
  return auth;
}


export async function registerWithPopupAsync() {
  const auth = await getFirebaseAuthAsync();
  const googleProvider = new GoogleAuthProvider();
  if (auth.currentUser) {
    try {
      await linkWithPopup(auth.currentUser, googleProvider);
    } catch (error: any) {
      if (error.code === "auth/credential-already-in-use") {
        // noinspection JSUnresolvedReference
        const { oauthIdToken, oauthAccessToken } = error.customData._tokenResponse;
        const credential = GoogleAuthProvider.credential(oauthIdToken, oauthAccessToken);
        await signInWithCredential(auth, credential);
      } else {
        console.error("registerWithPopupAsync: error", error);
        throw error;
      }
    }
  } else {
    console.error("linkWithPopup: auth.currentUser is null");
  }
}

export async function signInUserAnonymouslyAsync() {
  const auth = await getFirebaseAuthAsync();

  const isSignedIn = await isUserSignedInAsync();
  const isAnonymous = await isUserAnonymousAsync();
  const isRegistered = await isUserRegisteredAsync();

  if (isRegistered || (isSignedIn && isAnonymous)) {
    return auth.currentUser;
  } else if (isSignedIn && isAnonymous) {
    return auth.currentUser;
  } else if (!isSignedIn) {
    return (await signInAnonymously(auth)).user;
  } else {
    console.error("signInUserAnonymously: unexpected state");
    return null;
  }
}
