export function openCrispChat() {
  console.log("Opening Crisp chat");
  // @ts-ignore
  if (window.$crisp) {
    // @ts-ignore
    window.$crisp.push(["do", "chat:open"]);
  } else {
    console.error("Crisp has not been initialized correctly.");
  }
}

export function showCrispChat() {
  console.log("Showing Crisp chat");
  // @ts-ignore
  if (window.$crisp) {
    // @ts-ignore
    window.$crisp.push(["do", "chat:show"]);
  } else {
    console.error("Crisp has not been initialized correctly.");
  }
}

export function hideCrispChat() {
  console.log("Hiding Crisp chat");
  // @ts-ignore
  if (window.$crisp) {
    // @ts-ignore
    window.$crisp.push(["do", "chat:hide"]);
  } else {
    console.error("Crisp has not been initialized correctly.");
  }
}
