import React, { useState } from "react";
import jsPDF from "jspdf";
import { LoadingButton } from "@mui/lab";
import { FileDownloadRounded } from "@mui/icons-material";
import { AnimatePresence, motion } from "framer-motion";
import logoImage from "../../icons/favicon/favicon.png";
import fingerUpImage from "../../icons/backhand-index-pointing-up.png";
import { green } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import LockIcon from "@mui/icons-material/Lock";
// Add Roboto font
import robotoRegular from "../../fonts/Roboto-Regular.ttf";
import robotoBold from "../../fonts/Roboto-Bold.ttf";
import robotoItalic from "../../fonts/Roboto-Italic.ttf";
import { styled } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { InfoTooltipView } from "../InfoTooltipView";
import { showUpgradePopup, UpgradePopup } from "../website/UpgradePopup";
import { isPaidUserStatus } from "./AiterAppComponent";

export type Page = {
  title: string;
  subtitle: string;
  subtitleDescription: string;
  content: string;
};

export type PdfGeneratorProps = {
  reportName: string;
  pages: Page[];
  serviceName: string;
  websiteUrl: string;
  dateTime: Date;
  marketingBrief: string;
};

const ShineEffect = styled(motion.div)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)",
  transform: "skewX(-20deg)",
  zIndex: 2,
  pointerEvents: "none"
});

export const PdfGeneratorButton: React.FC<PdfGeneratorProps> = ({
                                                                  reportName,
                                                                  pages,
                                                                  serviceName,
                                                                  websiteUrl,
                                                                  dateTime,
                                                                  marketingBrief
                                                                }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const { t } = useTranslation();

  const state = useSelector((state: RootState) => state.appState);
  const subscriptionSatus = state?.subcription?.status;
  const isPaidUser = isPaidUserStatus(subscriptionSatus);

  const renderImage = async (url: string): Promise<HTMLImageElement | null> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => resolve(null);
      img.crossOrigin = "anonymous";
      img.src = url;
    });
  };

  const applyGradientText = (pdf: jsPDF, text: string, x: number, y: number, fontSize: number, align: "left" | "center" | "right" = "left") => {
    pdf.setFontSize(fontSize);
    pdf.setFont("Roboto", "bold");

    const textWidth = pdf.getTextWidth(text);
    let startX = x;
    if (align === "center") startX = x - textWidth / 2;
    if (align === "right") startX = x - textWidth;

    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      const charX = startX + pdf.getTextWidth(text.substring(0, i));
      const progress = i / (text.length - 1);

      const r = Math.round(28 + (159 - 28) * progress);
      const g = Math.round(66 + (126 - 66) * progress);
      const b = Math.round(244 + (206 - 244) * progress);

      pdf.setTextColor(r, g, b);
      pdf.text(char, charX, y);
    }
  };

  const addContentWithPageBreaks = (
    pdf: jsPDF,
    content: string,
    startY: number,
    pageWidth: number,
    pageHeight: number,
    margin: number,
    fontSize: number
  ): { newY: number; remainingText: string } => {
    pdf.setFontSize(fontSize);
    pdf.setFont("Roboto", "normal");
    pdf.setTextColor(0);

    const lineHeight = fontSize * 0.6;
    const maxWidth = pageWidth - 2 * margin;
    let currentY = startY;

    const paragraphs = content.split(/\n\s*\n/);
    let processedContent = "";
    let remainingContent = "";

    const minContentForNewPage = 100; // Minimum characters to justify a new page

    for (const paragraph of paragraphs) {
      const lines = pdf.splitTextToSize(paragraph, maxWidth);

      for (const line of lines) {
        if (currentY + lineHeight > pageHeight - margin * 2) { // Added extra margin
          remainingContent = paragraph.substring(pdf.getTextWidth(processedContent)) + "\n\n" +
            paragraphs.slice(paragraphs.indexOf(paragraph) + 1).join("\n\n");

          // Check if remaining content is significant enough for a new page
          if (remainingContent.length < minContentForNewPage) {
            // If not significant, try to fit it on the current page
            if (currentY + lineHeight * 2 <= pageHeight - margin) {
              pdf.text(line, margin, currentY);
              currentY += lineHeight;
              processedContent += line + " ";
              remainingContent = paragraphs.slice(paragraphs.indexOf(paragraph) + 1).join("\n\n");
            }
          }

          return { newY: currentY, remainingText: remainingContent };
        }

        pdf.text(line, margin, currentY);
        currentY += lineHeight;
        processedContent += line + " ";
      }

      currentY += lineHeight;
      processedContent += "\n\n";
    }

    return { newY: currentY, remainingText: "" };
  };

  const generatePdf = async () => {
    setLoading(true);
    setProgress(0);
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 16;

    // Add Roboto font
    pdf.addFont(robotoRegular, "Roboto", "normal");
    pdf.addFont(robotoBold, "Roboto", "bold");
    pdf.addFont(robotoItalic, "Roboto", "italic");
    pdf.setFont("Roboto");

    // Title page
    const logo = await renderImage(logoImage);
    if (logo) {
      pdf.addImage(logo, "PNG", pageWidth / 2 - 25, pageHeight / 4 - 25, 50, 50);
    } else {
      pdf.setFillColor(211, 211, 211); // Light grey
      pdf.rect(pageWidth / 2 - 25, pageHeight / 4 - 25, 50, 50, "F");
    }

    applyGradientText(pdf, reportName, pageWidth / 2, pageHeight / 2 - 20, 40, "center");

    pdf.setFont("Roboto", "normal");
    pdf.setTextColor(0, 0, 255);

    // Add website URL
    pdf.setFontSize(16);
    pdf.text(websiteUrl, pageWidth / 2, pageHeight / 2 + 20, { align: "center" });

    // Add service name
    pdf.setTextColor(128, 128, 128); // Grey color
    pdf.setFontSize(12);
    pdf.text(serviceName, pageWidth / 2, pageHeight / 2 + 30, { align: "center" });

    pdf.setFontSize(10);
    pdf.setTextColor(192, 192, 192); // Light grey color
    const dateTimeString = dateTime.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
    pdf.text(dateTimeString, pageWidth / 2, pageHeight - margin, { align: "center" });

    pdf.setFontSize(10);
    pdf.setTextColor(128, 128, 128); // Grey color
    pdf.text("1", pageWidth - margin, pageHeight - margin, { align: "right" });

    setProgress(1);

    // Marketing Brief
    pdf.addPage();
    let currentPageNumber = 2;

    applyGradientText(pdf, "Marketing Brief", pageWidth / 2, margin, 24, "center");
    pdf.line(margin, margin + 5, pageWidth - margin, margin + 5);
    pdf.setFont("Roboto", "normal");

    let briefText = marketingBrief.trim();
    // Remove "marketing brief" or "marketing brief:" with line-break from the beginning, if present
    briefText = briefText.replace(/^marketing brief:? ?/i, "").trim();
    // Replace single new lines with 2 new lines
    briefText = briefText.replace(/\n/g, "\n\n").trim();
    // Add line breaks after each ":" or ": " if the next character is not a new line
    briefText = briefText.replace(/: ?(?=[^\n])/g, ": \n").trim();

    let {
      newY,
      remainingText
    } = addContentWithPageBreaks(pdf, briefText, margin + 21, pageWidth, pageHeight, margin, 10);

    while (remainingText) {
      pdf.addPage();
      currentPageNumber++;
      const result = addContentWithPageBreaks(pdf, remainingText, margin, pageWidth, pageHeight, margin, 10);
      newY = result.newY;
      remainingText = result.remainingText;
    }

    // Add page number
    pdf.setFontSize(10);
    pdf.setTextColor(128, 128, 128);
    pdf.text(currentPageNumber.toString(), pageWidth - margin, pageHeight - margin, { align: "right" });

    setProgress(2);

    // Generate content pages
    for (let i = 0; i < pages.length; i++) {
      let { title, subtitle, subtitleDescription, content } = pages[i];
      let partNumber = 1;
      let remainingContent = content;

      while (remainingContent.length > 0) {
        pdf.addPage();
        currentPageNumber++;
        let yPosition = margin;

        // Add title (only for the first part)
        if (partNumber === 1) {
          pdf.setFont("Roboto", "bold");
          applyGradientText(pdf, title, pageWidth / 2, yPosition, 24, "center");
          pdf.line(margin, yPosition + 5, pageWidth - margin, yPosition + 5);
          yPosition += 20;
        }

        // Add subtitle with part number
        pdf.setFontSize(16);
        pdf.setFont("Roboto", "bold");
        pdf.setTextColor("#00134C");
        const subtitleWithPart = partNumber > 1 ? `${t(subtitle)} (Part ${partNumber})` : t(subtitle);
        pdf.text(subtitleWithPart, pageWidth / 2, yPosition, { align: "center" });
        yPosition += 10;

        // Add content with automatic page breaks
        const result = addContentWithPageBreaks(pdf, remainingContent, yPosition, pageWidth, pageHeight, margin, 10);
        remainingContent = result.remainingText;

        // Only increment partNumber if there's significant remaining content
        if (remainingContent.length >= 100) {
          partNumber++;
        }

        // Add website URL at the bottom of the page
        pdf.setFontSize(10);
        pdf.setFont("Roboto", "normal");
        pdf.setTextColor(0, 0, 255);
        pdf.text(websiteUrl, margin, pageHeight - margin);

        // Add page number
        pdf.setTextColor(128, 128, 128);
        pdf.text(currentPageNumber.toString(), pageWidth - margin, pageHeight - margin, { align: "right" });
      }

      setProgress(i + 3);
    }

    // Add final page
    pdf.addPage();
    currentPageNumber++;
    const finalLogo = await renderImage(logoImage);
    if (finalLogo) {
      pdf.addImage(finalLogo, "PNG", pageWidth / 2 - 25, pageHeight / 3 - 50, 50, 50);
    } else {
      pdf.setFillColor(211, 211, 211); // Light grey
      pdf.rect(pageWidth / 2 - 25, pageHeight / 3 - 50, 50, 50, "F");
    }

    applyGradientText(pdf, "Need more insights?", pageWidth / 2, pageHeight / 2, 24, "center");

    pdf.setFontSize(24);
    pdf.setFont("Roboto", "normal");
    pdf.setTextColor(0, 0, 255);
    const aiterText = "aiter.io";
    const aiterWidth = pdf.getTextWidth(aiterText);
    const aiterX = pageWidth / 2 - aiterWidth / 2;
    pdf.textWithLink(aiterText, aiterX, pageHeight / 2 + 30, { url: "https://aiter.io" });

    // Add pointing up hand image
    const handImage = await renderImage(fingerUpImage);
    if (handImage) {
      pdf.addImage(handImage, "PNG", pageWidth / 2 - 8, pageHeight / 2 + 40, 16, 16);
    } else {
      pdf.setFillColor(211, 211, 211); // Light grey
      pdf.rect(pageWidth / 2 - 8, pageHeight / 2 + 40, 16, 16, "F");
    }

    pdf.setFontSize(10);
    pdf.setTextColor(128, 128, 128); // Grey color
    pdf.text(currentPageNumber.toString(), pageWidth - margin, pageHeight - margin, { align: "right" });

    // Save the PDF
    pdf.save(`${reportName}.pdf`);
    setLoading(false);
  };

  const shineVariants = {
    animate: {
      x: ["-100%", "200%", "200%", "-100%"],
      transition: {
        times: [0, 0.23, 1, 1],
        duration: 15,
        ease: "easeInOut",
        repeat: Infinity
      }
    }
  };

  const totalPagesToSaveAsPDF = pages.length + 3;

  const tooltipText = isPaidUser
    ? ""
    : t("website.pdfGenerator.button.upgrade", { pages: totalPagesToSaveAsPDF });

  const handleButtonClick = () => {
    if (isPaidUser) {
      generatePdf();
    } else {
      showUpgradePopup();
    }
  };

  const ButtonComponent = (
    <motion.div
      whileHover={{ scale: 1.05 }}
      style={{ position: "relative", width: "100%", height: "56px" }}
    >
      <AnimatePresence>
        {loading && (
          <motion.div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              backgroundColor: green[500],
              opacity: 0.4,
              borderRadius: "8px",
              zIndex: 1
            }}
            initial={{ width: 0 }}
            animate={{ width: `${(progress / totalPagesToSaveAsPDF) * 100}%` }}
            exit={{ width: 0 }}
            transition={{ duration: 0.5 }}
          />
        )}
      </AnimatePresence>
      <LoadingButton
        loading={loading}
        loadingPosition="start"
        onClick={handleButtonClick}
        variant="contained"
        color="success"
        startIcon={isPaidUser ? <FileDownloadRounded /> : <LockIcon />}
        sx={{
          height: 56,
          width: "100%",
          borderRadius: "8px",
          overflow: "hidden",
          position: "relative"
        }}
      >
        {loading
          ? t("website.pdfGenerator.button.generating", { progress, total: totalPagesToSaveAsPDF })
          : t("website.pdfGenerator.button.save", { pages: totalPagesToSaveAsPDF })}
      </LoadingButton>
      {!isPaidUser && <ShineEffect
        variants={shineVariants}
        animate="animate"
      />}
    </motion.div>
  );

  return (
    <>
      <InfoTooltipView
        text={tooltipText}
        icon={ButtonComponent}
        isDisabled={isPaidUser}
      />
      <UpgradePopup />
    </>
  );
};
