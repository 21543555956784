import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ApplyTheme } from "./utils/theme";
import { store } from "./utils/store";
import "./styles/main.css";
import { Provider } from "react-redux";
import { NetworkErrorBoundary } from "./components/NetworkErrorBoundary";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import "./icons/cover.webp";
import { IS_DEV_BASE_URL, IS_LOCAL_BASE_URL, IS_PROD_BASE_URL } from "./utils/env";
import { AuthProvider, FirebaseAppProvider, FirestoreProvider } from "reactfire";
import { firebaseAuth, firebaseConfig, firestoreDb } from "./utils/firebaseUtils";
import { ConfirmProvider } from "material-ui-confirm";
import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/react";

// redirect to "aiter.io" if not already there, not DEV, not local
if (!IS_LOCAL_BASE_URL && !IS_DEV_BASE_URL && !IS_PROD_BASE_URL) {
  console.warn("Redirecting to aiter.io");
  window.location.href = "https://aiter.io";
}

Sentry.init({
  dsn: "https://e38ea3a3588658e08c21550a8002a165@o4507397770903552.ingest.de.sentry.io/4507397772607568",
  environment: IS_PROD_BASE_URL ? "prod" : "dev",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "aiter-ai-dev.web.app", "aiter.io"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  defaultIntegrations: false, // Disable automatic error tracking
});

console.warn(`========= APP RUNNING IN ${(IS_PROD_BASE_URL ? "PROD" : "DEV")} ENVIRONMENT ============`);

ReactDOM.createRoot(document.getElementById("root")!)
  .render(<React.StrictMode>
    <NetworkErrorBoundary>
      <Provider store={store}>
        <ConfirmProvider>
          <ApplyTheme>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
              <AuthProvider sdk={firebaseAuth}>
                <FirestoreProvider sdk={firestoreDb}>
                  <App />
                  <ToastContainer
                    closeOnClick={false}
                    pauseOnFocusLoss
                    pauseOnHover
                    autoClose={15000}
                    style={{ marginTop: 72 }}
                  />
                </FirestoreProvider>
              </AuthProvider>
            </FirebaseAppProvider>
          </ApplyTheme>
        </ConfirmProvider>
      </Provider>
    </NetworkErrorBoundary>
  </React.StrictMode>);
