import { IconButton } from "@mui/material";
import * as React from "react";

export function IconButtonComponent({ icon, url, callback }: {
  icon: any,
  url: string,
  callback?: () => void
}) {
  return <IconButton sx={{ width: 36, height: 36 }} onClick={() => {
    window.open(url);
    if (callback) {
      callback();
    }
  }}>
    <img src={icon} alt="icon" style={{ width: 24, height: 24 }} />
  </IconButton>;
}
