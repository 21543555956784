import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import LockIcon from "@mui/icons-material/Lock";
import { InfoTooltipView } from "../InfoTooltipView";
import { showUpgradePopup } from "../website/UpgradePopup";
import { isPaidUserStatus } from "./AiterAppComponent";

export type Creativity = "conservative" | "standard" | "spicy" | "crazy";

export interface CreativityLevelData {
  name: string;
  type: Creativity;
}

export function CreativityLevel({
                                  onSelectedCreativityLvlChange,
                                  initType
                                }: {
  onSelectedCreativityLvlChange: (lvl: CreativityLevelData) => void,
  initType: Creativity
}) {
  const { t } = useTranslation();
  const [lvl, setLvl] = useState<Creativity>(initType);
  const [menuOpen, setMenuOpen] = useState(false);

  const state = useSelector((state: RootState) => state.appState);
  const subscriptionStatus = state?.subcription?.status;
  const isPaidUser = isPaidUserStatus(subscriptionStatus);

  useEffect(() => {
    if (!isPaidUser && initType !== "standard") {
      setLvl("standard");
      onSelectedCreativityLvlChange({
        name: t("app.labels.standardCreativity"),
        type: "standard"
      });
    }
  }, [isPaidUser, initType, onSelectedCreativityLvlChange, t]);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedLevel = event.target.value as Creativity;
    setLvl(selectedLevel);
    onSelectedCreativityLvlChange({
      name: t(`app.labels.${selectedLevel}Creativity`),
      type: selectedLevel
    });
  };

  const handleMenuItemClick = (value: Creativity) => {
    if (!isPaidUser && value !== "standard") {
      showUpgradePopup();
      setMenuOpen(false);
    } else {
      setLvl(value);
      onSelectedCreativityLvlChange({
        name: t(`app.labels.${value}Creativity`),
        type: value
      });
    }
  };

  const renderMenuItem = (value: Creativity) => {
    const label = t(`app.labels.${value}Creativity`);
    const isLocked = !isPaidUser && value !== "standard";

    const menuItemContent = (
      <Box sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "48px",
        padding: "0 16px",
        opacity: isLocked ? 0.8 : 1
      }}>
        <span>{label}</span>
        {isLocked && (
          <LockIcon sx={{ color: "grey.500", fontSize: 16, position: "absolute", right: 8 }} />
        )}
      </Box>
    );

    return (
      <MenuItem
        key={value}
        value={value}
        onClick={() => handleMenuItemClick(value)}
        sx={{
          padding: 0,
          backgroundColor: isLocked ? "grey.100" : "inherit",
          "&.Mui-disabled": { opacity: 1 },
          height: "48px"
        }}
      >
        {isLocked ? (
          <InfoTooltipView
            text={<div dangerouslySetInnerHTML={{ __html: t("app.labels.creativityUpgrade")}}/>}
            icon={menuItemContent}
            isDisabled={false}
          />
        ) : menuItemContent}
      </MenuItem>
    );
  };

  const creativityLevels: Creativity[] = isPaidUser
    ? ["conservative", "standard", "spicy", "crazy"]
    : ["standard", "conservative", "spicy", "crazy"];

  return (
    <Box sx={{ textAlign: "start" }}>
      <FormControl fullWidth>
        <InputLabel>{t("app.labels.creativityLabel")}</InputLabel>
        <Select
          sx={{ height: 56 }}
          value={lvl}
          label={t("app.labels.creativityLabel")}
          onChange={handleChange}
          open={menuOpen}
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          MenuProps={{ MenuListProps: { sx: { p: 0 } } }}
        >
          {creativityLevels.map(renderMenuItem)}
        </Select>
      </FormControl>
    </Box>
  );
}
