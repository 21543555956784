import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../../icons/lottie-loading-registered-users.json";
import { Box, LinearProgress, styled, Typography } from "@mui/material";
import AnimatedText from "./AnimatedText";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";

// Styled components
const LoadingGrid = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2)
}));

const TextContainer = styled(Box)({
  textAlign: "center",
  marginBottom: "16px" // Adjust the space between text and progress bar as needed
});

const Countdown = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: -theme.spacing(1.5),
  left: "50%",
  transform: "translateX(-50%)",
  borderRadius: "20px",
  padding: theme.spacing(0.5, 2)
}));

const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
  backgroundColor: `linear-gradient(90deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
  borderRadius: 5,
  "& .MuiLinearProgress-bar": {
    borderRadius: 5
  }
}));


const TIMER_DURATION_LOADING_SEC = 40;
const SCENE_DURATION_SEC = 10;

export default function LoadingResultsComponentRegisteredUsers() {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const { t } = useTranslation();

  // Constants for the progress bar and text animation
  const loadingTexts = [
    t("app.labels.openingWebsite"),
    t("app.labels.scanningInformation"),
    t("app.labels.generatingResults")
  ];

  // Lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  // Handle scene changes
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => {
        if (prevIndex === loadingTexts.length - 1) {
          clearInterval(interval);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, SCENE_DURATION_SEC * 1000);
    return () => clearInterval(interval);
  }, []);

  // Handle progress bar
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(timer);
          setIsIndeterminate(true); // When progress reaches 100%, start indeterminate animation
          return 100;
        }
        return oldProgress + (100 / TIMER_DURATION_LOADING_SEC);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // @ts-ignore
  const lottie = <Lottie options={defaultOptions} height={200} width={200} />;
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <LoadingGrid container>
        <Grid xs={12}>
          {lottie}
        </Grid>
        <Grid xs={12}>
          <TextContainer>
            {/* Add a key to force remount and reset the animation when the text changes */}
            <AnimatedText text={loadingTexts[currentTextIndex]} key={currentTextIndex} />
          </TextContainer>
        </Grid>
        <Grid xs={12} style={{ position: "relative" }}>
          {!isIndeterminate && (
            <Countdown>
              <Typography variant="caption">
                {`${TIMER_DURATION_LOADING_SEC - Math.floor(progress / (100 / TIMER_DURATION_LOADING_SEC))}s`}
              </Typography>
            </Countdown>
          )}
          <GradientLinearProgress
            variant={isIndeterminate ? "indeterminate" : "determinate"}
            value={progress}
          />
        </Grid>
      </LoadingGrid>
    </Box>
  );
}
