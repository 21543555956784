import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PricingPage } from "./PricingPage";

export function PricingSection() {
  const { t } = useTranslation();

  return (
    <Grid xs={12} id="pricing">
      <Container disableGutters component="main" sx={{ pt: 8, pb: 6 }} id="pricing">
        <Typography variant="h2" color="primary" fontWeight={400}
                    sx={{
                      mr: 1,
                      fontSize: { xs: 46, sm: 32 },
                      background: "linear-gradient(270.17deg, #A57DD3 3.88%, #0040FF 98.08%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      textFillColor: "transparent",
                      textAlign: "center"
                    }}>
          {t("website.pricing.mainTitle")}
        </Typography>
      </Container>
      <PricingPage />
    </Grid>
  );
}
