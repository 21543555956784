import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { registerWithPopupAsync } from "../../utils/auth"; // Adjust this import path as needed

interface PlanFeature {
  text: string;
}

interface PricingPlan {
  title: string;
  price: string;
  description: string;
  features: PlanFeature[];
  isHighlighted?: boolean;
}

interface PricingOptionProps extends PricingPlan {
  buttonText: string;
  onButtonClick: () => Promise<void>;
  isMonthly: boolean;
}

const PricingOption: React.FC<PricingOptionProps> = ({
                                                       title,
                                                       price,
                                                       description,
                                                       features,
                                                       buttonText,
                                                       isHighlighted,
                                                       onButtonClick,
                                                       isMonthly
                                                     }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        ...(isHighlighted && {
          border: `2px solid ${theme.palette.primary.main}`,
          position: "relative"
        })
      }}
    >
      {isHighlighted && isMonthly && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: theme.palette.primary.main,
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            fontSize: "0.75rem",
            textAlign: "center",
            width: "100%"
          }}
        >
          Best deal
        </Box>
      )}
      <Box sx={{
        backgroundColor: "#f5f5f5",
        p: 2,
        mt: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Typography variant="h3" component="div" sx={{ mb: 2, textAlign: "center" }}>
          ${price}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {description}
        </Typography>
        <Box sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end"
        }}>
          {features.map((feature, index) => (
            <Typography key={index} variant="body2" sx={{ mb: 1 }}>
              ✓ {feature.text}
            </Typography>
          ))}
        </Box>
        <Button
          fullWidth
          variant={isHighlighted ? "contained" : "outlined"}
          color={isHighlighted ? "primary" : "inherit"}
          sx={{
            mt: 2,
            backgroundColor: isHighlighted ? theme.palette.primary.main : theme.palette.primary.light,
            color: isHighlighted ? "white" : theme.palette.primary.main,
            "&:hover": {
              backgroundColor: isHighlighted ? theme.palette.primary.dark : theme.palette.primary.main,
              color: "white"
            }
          }}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );
};

const PricingTable: React.FC = () => {
  const [pricingPeriod, setPricingPeriod] = useState<"monthly" | "yearly" | "oneTime">("monthly");
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const handlePricingPeriodChange = (event: React.MouseEvent<HTMLElement>, newPeriod: "monthly" | "yearly" | "oneTime" | null) => {
    if (newPeriod !== null) {
      setPricingPeriod(newPeriod);
    }
  };

  const pricingData: Record<"monthly" | "yearly" | "oneTime", PricingPlan[]> = {
    monthly: [
      {
        title: "Begin",
        price: "9.90",
        description: "This package is ideal if you are a freelancer, a startup, or a small company that has one or two products and doesn't run several campaigns at the same time",
        features: [
          { text: "25 Requests / Month" },
          { text: "50+ Marketing Tasks Solved" },
          { text: "Fast Chat Support" },
          { text: "Save and export results as PDF" },
          { text: "All creativity levels" }
        ]
      },
      {
        title: "Advance",
        price: "19",
        description: "A great solution for small marketing agencies, busy freelancers and companies that run multiple campaigns",
        features: [
          { text: "100 Requests / Month" },
          { text: "50+ Marketing Tasks Solved" },
          { text: "Fast Chat Support" },
          { text: "Save and export results as PDF" },
          { text: "All creativity levels" }
        ],
        isHighlighted: true
      },
      {
        title: "Conquer",
        price: "59",
        description: "The ultimate solution for marketing agencies and companies that run numerous campaigns and want to maximize the use of Aiter",
        features: [
          { text: "500 Requests / Month" },
          { text: "50+ Marketing Tasks Solved" },
          { text: "Fast Chat Support" },
          { text: "Save and export results as PDF" },
          { text: "All creativity levels" }
        ]
      }
    ],
    yearly: [
      {
        title: "Begin",
        price: "29",
        description: "This package is ideal if you are a freelancer, a startup, or a small company that has one or two products and doesn't run several campaigns at the same time",
        features: [
          { text: "25 Requests / Month" },
          { text: "50+ Marketing Tasks Solved" },
          { text: "Fast Chat Support" },
          { text: "Save and export results as PDF" },
          { text: "All creativity levels" }
        ]
      },
      {
        title: "Advance",
        price: "49",
        description: "A great solution for small marketing agencies, busy freelancers and companies that run multiple campaigns",
        features: [
          { text: "100 Requests / Month" },
          { text: "50+ Marketing Tasks Solved" },
          { text: "Fast Chat Support" },
          { text: "Save and export results as PDF" },
          { text: "All creativity levels" }
        ],
        isHighlighted: true
      },
      {
        title: "Conquer",
        price: "179",
        description: "The ultimate solution for marketing agencies and companies that run numerous campaigns and want to maximize the use of Aiter",
        features: [
          { text: "500 Requests / Month" },
          { text: "50+ Marketing Tasks Solved" },
          { text: "Fast Chat Support" },
          { text: "Save and export results as PDF" },
          { text: "All creativity levels" }
        ]
      }
    ],
    oneTime: [
      {
        title: "Begin",
        price: "39",
        description: "This package is ideal if you are a freelancer, a startup, or a small company that has one or two products and doesn't run several campaigns at the same time",
        features: [
          { text: "25 Requests / Month" },
          { text: "50+ Marketing Tasks Solved" },
          { text: "Fast Chat Support" },
          { text: "Save and export results as PDF" },
          { text: "All creativity levels" }
        ]
      },
      {
        title: "Advance",
        price: "69",
        description: "A great solution for small marketing agencies, busy freelancers and companies that run multiple campaigns",
        features: [
          { text: "100 Requests / Month" },
          { text: "50+ Marketing Tasks Solved" },
          { text: "Fast Chat Support" },
          { text: "Save and export results as PDF" },
          { text: "All creativity levels" }
        ],
        isHighlighted: true
      },
      {
        title: "Conquer",
        price: "199",
        description: "The ultimate solution for marketing agencies and companies that run numerous campaigns and want to maximize the use of Aiter",
        features: [
          { text: "500 Requests / Month" },
          { text: "50+ Marketing Tasks Solved" },
          { text: "Fast Chat Support" },
          { text: "Save and export results as PDF" },
          { text: "All creativity levels" }
        ]
      }
    ]
  };

  const onButtonClicked = async () => {
    try {
      await registerWithPopupAsync();
      navigate("/profile");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ width: "100%", px: 4, pt: 8 }} id="pricing">
      <Box sx={{ maxWidth: 1200, margin: "auto" }}>
        <Typography variant="h2" color="primary" fontWeight={400}
                    sx={{
                      mr: 1,
                      fontSize: { xs: 32, sm: 46 },
                      background: "linear-gradient(270.17deg, #A57DD3 3.88%, #0040FF 98.08%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      textFillColor: "transparent",
                      textAlign: "center",
                      mb: 4
                    }}>
          {t("website.pricing.mainTitle")}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <ToggleButtonGroup
            value={pricingPeriod}
            exclusive
            onChange={handlePricingPeriodChange}
            aria-label="pricing period"
          >
            {["monthly", "yearly", "oneTime"].map((period) => (
              <ToggleButton
                key={period}
                value={period}
                aria-label={period}
                sx={{
                  "&.Mui-selected": {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.light
                  }
                }}
              >
                {period.toUpperCase()}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <AnimatePresence mode="wait">
          <motion.div
            key={pricingPeriod}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <Box sx={{
              display: "flex",
              flexDirection: isDesktop ? "row" : "column",
              gap: isDesktop ? 2 : 0
            }}>
              {pricingData[pricingPeriod].map((plan) => (
                <Box key={plan.title} sx={{ flex: 1, mb: isDesktop ? 0 : 2 }}>
                  <PricingOption
                    {...plan}
                    buttonText="SUBSCRIBE"
                    onButtonClick={onButtonClicked}
                    isMonthly={pricingPeriod === "monthly"}
                  />
                </Box>
              ))}
            </Box>
          </motion.div>
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default PricingTable;
