import React, { useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeHighlight from "rehype-highlight";
import "../../styles/markdownStyles.css";
import { Box, Button, Typography, useTheme } from "@mui/material";
import remarkBreaks from "remark-breaks";
import { green, grey } from "@mui/material/colors";
import { trackClickCollapse, trackClickCopy, trackClickExpand } from "../../utils/analytics";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Check, ContentCopy } from "@mui/icons-material";
import { cleanMarkdownAndFormat, markdownToPlainText } from "../../utils/text";
import Grid from "@mui/material/Unstable_Grid2";

export function FieldComponentMarkdown({
                                         fieldLabel,
                                         textMarkdown,
                                         buttonOverride
                                       }: {
  fieldLabel: string,
  textMarkdown: string,
  buttonOverride?: { text: string, onClick: () => void }
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isCopied, setCopied] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const markdownRef = useRef(null);

  const textMarkdownPostProcessed = cleanMarkdownAndFormat(textMarkdown);

  const toggleExpand = () => {
    if (isExpanded) {
      trackClickCollapse(fieldLabel);
    } else {
      trackClickExpand(fieldLabel);
    }

    setExpanded(!isExpanded);
    if (!isExpanded) return; // No need to scroll when expanding
    setTimeout(() => {
      if (markdownRef.current) {
        window.scrollTo({
          // @ts-ignore
          top: markdownRef.current.offsetTop - 100,
          behavior: "smooth"
        });
      }
    }, 100);
  };

  return (
    <Grid container xs={12} direction="column" ref={markdownRef}
          sx={{
            textAlign: "start",
            fontFamily: theme.typography.fontFamily,
            lineHeight: "1.5rem",
            border: 1,
            px: 2,
            borderColor: grey[300],
            borderRadius: 2,
            bgcolor: isCopied ? green[50] : "white",
            position: "relative",  // Position relative for absolute positioning of the gradient
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "secondary.light"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "secondary.light"
            }
          }}>
      <Grid textAlign={"right"}>
        <Button size={"small"}
                sx={{
                  bgcolor: "primary.light",
                  border: 1,
                  borderColor: isCopied ? "secondary.light" : "primary.light",
                  px: 2,
                  m: 1,
                  mr: -1
                }}
                startIcon={buttonOverride
                  ? undefined
                  : isCopied
                    ? <Check color={"success"} />
                    : <ContentCopy color={"secondary"} />}
                onClick={() => {
                  if (buttonOverride) {
                    buttonOverride.onClick();
                  } else {
                    trackClickCopy(fieldLabel);
                    navigator.clipboard.writeText(markdownToPlainText(textMarkdownPostProcessed));
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  }
                }}>
          <Typography variant={"caption"} color={"secondary"} sx={{ mb: -0.3 }}>
            {buttonOverride ? buttonOverride.text : (isCopied ? t("app.buttons.copied") : t("app.buttons.copy"))}
          </Typography>
        </Button>
      </Grid>
      <Box sx={{
        maxHeight: isExpanded ? "auto" : "300px",
        overflow: "hidden",
        position: "relative",
        m: 0,
        maxWidth: "100%",
        "h1": {
          lineHeight: 1,
        }
      }}>
        <ReactMarkdown
          children={textMarkdownPostProcessed}
          remarkPlugins={[remarkGfm, remarkBreaks]}
          rehypePlugins={[rehypeRaw, rehypeHighlight]}
        />
        {(!isExpanded && !isCopied) && (
          <Box sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "100px",
            background: "linear-gradient(to bottom, transparent, white)"
          }} />
        )}
      </Box>
      <Grid sx={{ mt: 1, mx: -2, textAlign: "center" }}>
        <Button
          sx={{ bgcolor: "primary.light", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
          fullWidth
          onClick={toggleExpand}
          color="primary"
          size="small">
          {isExpanded ? <KeyboardArrowUpIcon color={"primary"} /> :
            <KeyboardArrowDownIcon color={"primary"} />}
          <Typography variant="button" sx={{ ml: 1 }} color={"primary"}>
            {isExpanded ? t("app.buttons.collapse") : t("app.buttons.expand")}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
