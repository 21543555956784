import React, { useRef, useState } from "react";
import { Box, Button, FormControl, OutlinedInput, Typography } from "@mui/material";
import { trackClickCollapse, trackClickCopy, trackClickExpand } from "../../utils/analytics";
import { green, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Grid from "@mui/material/Unstable_Grid2";

export function FieldComponentWithCopy(
  {
    fieldLabel,
    fieldValue,
    onEdit,
    buttonEnabled = true,
    buttonOverride
  }: {
    fieldLabel?: string,
    fieldValue: string,
    onEdit?: (e: string) => void,
    buttonEnabled?: boolean,
    buttonOverride?: { text: string, onClick: () => void }
  }) {
  const { t } = useTranslation();
  const [isCopied, setCopied] = useState(false);
  const value = fieldValue.replace(/^[ \t]+/gm, "");
  const [isExpanded, setExpanded] = useState(false);

  const fieldRef = useRef(null);

  const isExpandButtonVisible = value.length > 250 || value.split("\n").length > 7;
  const isCopyButtonVisible = value.length > 0;

  const toggleExpand = () => {
    if (isExpanded) {
      trackClickCollapse(fieldLabel);
    } else {
      trackClickExpand(fieldLabel);
    }

    setExpanded(!isExpanded);
    if (!isExpanded) return; // No need to scroll when expanding
    setTimeout(() => {
      if (fieldRef.current) {
        window.scrollTo({
          // @ts-ignore
          top: fieldRef.current.offsetTop - 100,
          behavior: "smooth"
        });
      }
    }, 100);
  };

  return <Grid container xs={12} direction="column" ref={fieldRef}
               sx={{
                 textAlign: "start",
                 lineHeight: "1.5rem",
                 border: 1,
                 px: 2,
                 borderColor: grey[300],
                 borderRadius: 2,
                 bgcolor: isCopied ? green[50] : "white",
                 "&:hover .MuiOutlinedInput-notchedOutline": {
                   borderColor: "secondary.light"
                 },
                 "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                   borderColor: "secondary.light"
                 }
               }}>
    <Box sx={{
      maxHeight: isExpanded ? "auto" : "300px",
      overflow: "hidden",
      mb: -1,
    }}>
      <FormControl variant={"outlined"} size={"small"} fullWidth sx={{ mt: 1 }} ref={fieldRef}>
        <OutlinedInput
          sx={{
            bgcolor: isCopied ? green[50] : "white",
            minHeight: 200,
            alignItems: "start",
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: 0
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: 0
            }
          }}
          value={value}
          onChange={onEdit ? (e) => onEdit(e.target.value) : undefined}
          multiline
          endAdornment={
            buttonEnabled && isCopyButtonVisible && <Button size={"small"}
                                     sx={{
                                       m: 0,
                                       mr: -0.8,
                                       ml: 1,
                                       bgcolor: "primary.light",
                                       border: 1,
                                       borderColor: isCopied ? "secondary.light" : "primary.light",
                                       px: 2
                                     }}
                                     onClick={() => {
                                       if (buttonOverride) {
                                         buttonOverride.onClick();
                                       } else {
                                         trackClickCopy(fieldLabel);
                                         navigator.clipboard.writeText(value);
                                         setCopied(true);
                                         setTimeout(() => {
                                           setCopied(false);
                                         }, 2000);
                                       }
                                     }}>
              <Typography variant={"caption"} color={"secondary"} sx={{ mb: -0.3 }}>
                {buttonOverride ? buttonOverride.text : (isCopied ? t("app.buttons.copied") : t("app.buttons.copy"))}
              </Typography>
            </Button>
          }
        />
      </FormControl>
    </Box>
    <Grid sx={{ mt: 1, mx: -2, textAlign: "center" }}>
      {isExpandButtonVisible && <Button
        sx={{ bgcolor: "primary.light", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
        fullWidth
        onClick={toggleExpand}
        color="primary"
        size="small">
        {isExpanded ? <KeyboardArrowUpIcon color={"primary"} /> :
          <KeyboardArrowDownIcon color={"primary"} />}
        <Typography variant="button" sx={{ ml: 1 }} color={"primary"}>
          {isExpanded ? t("app.buttons.collapse") : t("app.buttons.expand")}
        </Typography>
      </Button>}
    </Grid>
  </Grid>;
}
