import * as React from "react";
import { FirebaseApp } from "@firebase/app";
import { useFirebaseLoginState } from "../../utils/hooks";
import {
  IS_PROD_BASE_URL,
  VITE_STRIPE_PRICING_TABLE_ID_DEV,
  VITE_STRIPE_PRICING_TABLE_ID_PROD,
  VITE_STRIPE_PUBLISHABLE_KEY_DEV,
  VITE_STRIPE_PUBLISHABLE_KEY_PROD
} from "../../utils/env";

export function PricingPage() {
  const { userId, user } = useFirebaseLoginState();

  return <stripe-pricing-table
    pricing-table-id={IS_PROD_BASE_URL ? VITE_STRIPE_PRICING_TABLE_ID_PROD : VITE_STRIPE_PRICING_TABLE_ID_DEV}
    publishable-key={IS_PROD_BASE_URL ? VITE_STRIPE_PUBLISHABLE_KEY_PROD : VITE_STRIPE_PUBLISHABLE_KEY_DEV}
    client-reference-id={userId}
    customer-email={user?.email}>
  </stripe-pricing-table>;
}

declare global {
  interface Window {
    firebaseApp: FirebaseApp;
    emulatorStarted: boolean;
  }

  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}
