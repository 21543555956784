import * as amplitude from "@amplitude/analytics-browser";
import { Identify } from "@amplitude/analytics-browser";
import { OpenAiTokenConsumptionType } from "./analytics";
import { IS_DEV_BASE_URL, IS_LOCAL_BASE_URL } from "./env";

const IS_DEVELOPMENT_ENV = IS_LOCAL_BASE_URL || IS_DEV_BASE_URL;

const API_KEY_PROD = import.meta.env.VITE_AMPLITUDE_API_KEY_PROD;
const API_KEY_DEV = import.meta.env.VITE_AMPLITUDE_API_KEY_DEV;
const PROPERTY_DEV = "dev";
const PROPERTY_PROD = "prod";

const API_KEY_ENV = IS_DEVELOPMENT_ENV ? API_KEY_DEV : API_KEY_PROD;
const PROPERTY_ENV = IS_DEVELOPMENT_ENV ? PROPERTY_DEV : PROPERTY_PROD;

export const USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_TOTAL = "open_ai_token_consumption_total";
export const USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_INPUT = "open_ai_token_consumption_input";
export const USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_OUTPUT = "open_ai_token_consumption_output";
const USER_PROPERTY_SIGN_IN = "signed_in";
const USER_PROPERTY_REQUESTS_USED = "requests_used";
const USER_PROPERTY_REGENERATIONS_USED = "regenerations_used";
const USER_PROPERTY_PLAN_NAME = "plan_name";

const ampInstance = amplitude.createInstance();
ampInstance.init(API_KEY_ENV, undefined, {
  instanceName: PROPERTY_ENV,
  logLevel: IS_DEVELOPMENT_ENV ? amplitude.Types.LogLevel.Verbose : amplitude.Types.LogLevel.Warn,
  defaultTracking: true
});

export function isAmplitudeUserIdSet() {
  return !!ampInstance.getUserId();
}

export function amplitudeTrackEvent(eventName: string, eventCategory?: string, eventLabel?: string, eventValue?: string | number | boolean) {
  ampInstance.track({
    event_type: eventName,
    event_properties: {
      env: PROPERTY_ENV,
      event_name: eventName,
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue
    }
  });
}

export function amplitudeSetUserSignedIn(userId: string, isRegistered: boolean) {
  ampInstance.setUserId(userId);
  const setSignedInProporty = new Identify().set(USER_PROPERTY_SIGN_IN, isRegistered);
  ampInstance.identify(setSignedInProporty);
}

export function amplitudeSetUserSignedOut(userId: string) {
  ampInstance.setUserId(userId);
  ampInstance.identify(new Identify().set(USER_PROPERTY_SIGN_IN, false));
  ampInstance.reset();
}

export function setRequestsUsed(requestsUsed: number) {
  ampInstance.identify(new Identify().set(USER_PROPERTY_REQUESTS_USED, requestsUsed));
}

export function addRegenerationsUsed() {
  ampInstance.identify(new Identify().add(USER_PROPERTY_REGENERATIONS_USED, 1));
}

export function setPlanName(planName: string) {
  ampInstance.identify(new Identify().set(USER_PROPERTY_PLAN_NAME, planName));
}

export function amplitudeAddOpenAITokenConsumption(consumptionType: OpenAiTokenConsumptionType, tokens: number) {
  switch (consumptionType) {
    case "input":
      ampInstance.identify(new Identify().add(USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_INPUT, tokens));
      break;
    case "output":
      ampInstance.identify(new Identify().add(USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_OUTPUT, tokens));
      break;
    case "total":
      ampInstance.identify(new Identify().add(USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_TOTAL, tokens));
      break;
    default:
      throw new Error(`Unknown consumptionType: ${consumptionType}`);
  }
}
