import * as React from "react";
import { useCallback, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Linkedin from "../../icons/LinkedIn.webp";
import Twitter from "../../icons/Twitter.webp";
import Facebook from "../../icons/Facebook.webp";
import { IconButtonComponent } from "./IconButton";
import {
  trackClickBenefitsHeader,
  trackClickFacebookHeader,
  trackClickFeaturesHeader,
  trackClickHowItWorksHeader,
  trackClickLinkedinHeader,
  trackClickTwitterHeader,
  trackOpenNavigationMenu
} from "../../utils/analytics";
import { GoogleAuthButton } from "./GoogleAuthButton";
import { useFirebaseLoginState } from "../../utils/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Select,
  styled,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CloseOutlined } from "@mui/icons-material";
import { RequestsLeftCounter } from "./RequestsLeftCounter";
import { registerWithPopupAsync } from "../../utils/auth";
import { useTranslation } from "react-i18next";

const CustomSelect = styled(Select)(({ theme }) => ({
  "&&": {
    border: "none", // Ensure border is removed
    "& .MuiSelect-select": {
      paddingRight: theme.spacing(1), // Adjust right padding
      paddingLeft: theme.spacing(1), // Adjust left padding to ensure centering
      // Center text (and therefore, emoji) vertically
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: "center",
      textAlignLast: "center", // Specifically for centering in IE and Edge
      // Ensure the selected item is centered horizontally and vertically
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "&.MuiInput-underline:before, &.MuiInput-underline:after": {
      display: "none" // Remove underline
    },
    "& .MuiSvgIcon-root": {
      display: "none" // Ensure the dropdown icon is not displayed
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none" // Remove border for outlined variant
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none" // Remove hover border
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none" // Remove focus border
    },
    backgroundColor: "transparent" // Optional: Adjust background color
  }
}));

function GuideMenuItem() {
  const { t } = useTranslation();
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  function getNavigateAction() {
    window.location.href = "/guide-to-ai-marketing-with-aiter.html";
  }

  const mobileView = <MenuItem key={"Guide"} onClick={getNavigateAction}>
    <Typography textAlign="center">
      {t("website.header.guide")}
    </Typography>
  </MenuItem>;

  const desktopView = <Button key={"Guide"} onClick={getNavigateAction}
                              sx={{
                                my: 2,
                                display: "block",
                                textTransform: "none",
                                fontWeight: 400,
                                color: "text.primary"
                              }}
  >
    {t("website.header.guide")}
  </Button>;

  return isSmUp ? desktopView : mobileView;
}

interface LanguageOption {
  code: string;
  name: string;
}

const languageOptions: LanguageOption[] = [
  { code: "en", name: "English" },
  { code: "fr", name: "French" },
  { code: "uk", name: "Ukrainian" }
];

export default function HeaderComponent() {
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const isLgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const { t } = useTranslation();
  const { isRegistered } = useFirebaseLoginState();

  const location = useLocation();
  const navigate = useNavigate();

  const isLandingPageScreen = location.pathname === "/";

  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

  const pages = [
    { title: t("website.header.howItWorks"), anchor: "how-does-it-work" },
    { title: t("website.header.benefits"), anchor: "benefits" },
    { title: t("website.header.features"), anchor: "features" }
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    trackOpenNavigationMenu();
    setIsMenuOpen(true);
  };

  const handleCloseNavMenu = () => {
    setIsMenuOpen(false);
  };

  function onMenuItemClick(page: {
    anchor: string;
    title: string
  }) {
    if (page?.title === "How it works") {
      trackClickHowItWorksHeader();
    } else if (page?.title === "Benefits") {
      trackClickBenefitsHeader();
    } else if (page?.title === "Features") {
      trackClickFeaturesHeader();
    }

    const element = document.getElementById(page?.anchor);
    if (element) {
      // scroll 100px above the element
      const y = element.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
    handleCloseNavMenu();
  }

  function getPages() {
    return <Grid container>
      {pages.map((page) => <Grid key={page?.title}>
        <Button onClick={() => onMenuItemClick(page)} sx={{
          my: 2,
          display: "block",
          textTransform: "none",
          fontWeight: 400,
          color: "text.primary"
        }}>
          {page?.title}
        </Button>
      </Grid>)}
      {getPricingButton()}
      <GuideMenuItem />
    </Grid>;
  }

  function getPricingButton() {
    return <Grid>
      <Button
        key={"pricing"}
        onClick={onPricingClick}
        sx={{
          my: 2,
          display: "block",
          textTransform: "none",
          fontWeight: 400,
          color: "text.primary"
        }}>
        {t("website.header.pricing")}
      </Button>
    </Grid>;
  }

  function getSocialIcons() {
    return isLgUp && <Box alignSelf="center" mr={2}>
      <IconButtonComponent icon={Linkedin}
                           callback={trackClickLinkedinHeader}
                           url={"https://www.linkedin.com/company/aiter-io"} />
      <IconButtonComponent icon={Twitter}
                           callback={trackClickTwitterHeader}
                           url={"https://twitter.com/aiter_io"} />
      <IconButtonComponent icon={Facebook}
                           callback={trackClickFacebookHeader}
                           url={"https://www.facebook.com/aiter.tool/"} />
    </Box>;
  }

  function getLogo() {
    return <Typography variant="h4" onClick={() => {
      navigate("/");
    }} sx={{
      fontWeight: 800,
      textDecoration: "none",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      background: "linear-gradient(270.17deg, #A57DD3 3.88%, #0040FF 98.08%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      cursor: "pointer"
    }}>
      Aiter
    </Typography>;
  }

  function onPricingClick() {
    const element = document.getElementById("pricing");
    if (element) {
      // scroll 100px above the element
      const y = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: y, behavior: "smooth" });
    } else {
      navigate("/profile");
    }
    handleCloseNavMenu();
  }

  const LanguageSwitcher = React.memo(() => {
    const { i18n } = useTranslation();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

    useEffect(() => {
      const isValidLanguage = languageOptions.some(option => option.code === i18n.language);
      if (!isValidLanguage) {
        const defaultLanguage = languageOptions[0].code;
        i18n.changeLanguage(defaultLanguage);
      }
    }, [i18n.language]);

    const handleChange = useCallback((event: any) => {
      const newLanguage = event.target.value;
      i18n.changeLanguage(newLanguage);
    }, [i18n.language]);

    return (
      <CustomSelect
        value={i18n.language}
        onChange={handleChange}
        size="small"
        inputProps={{ "aria-label": "language" }}
        variant="standard"
        sx={{
          "& .MuiInputBase-inputSizeSmall": {
            padding: "12px !important"
          }
        }}
      >
        {languageOptions.map(({ code, name }) => (
          <MenuItem value={code} key={code}>
            <Typography variant={isSmUp ? "body2" : "h6"}>
              {isSmUp ? name : `${name.charAt(0).toUpperCase()}${name.slice(1)}`}
            </Typography>
          </MenuItem>
        ))}
      </CustomSelect>
    );
  });

  function getMobileView() {
    function getMobileMenuPopup() {
      const triggerSignInWithPopup = async () => {
        if (isRegistered) {
          setIsMenuOpen(false);
          return;
        } else {
          try {
            await registerWithPopupAsync();
            setIsMenuOpen(false);
          } catch (error) {
            console.error(error);
          }
        }
      };

      return <Box>
        {isLandingPageScreen &&
          <IconButton size="large" onClick={handleOpenNavMenu}>
            <MenuIcon />
          </IconButton>}
        {isMenuOpen &&
          <Grid container spacing={4} direction={"column"} alignItems={"center"}
                sx={{
                  position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000,
                  background: "white"
                }}>
            <Grid container xs={12} justifyContent={"space-between"}>
              <Grid alignSelf={"center"} ml={4}>
                {getLogo()}
              </Grid>
              <Grid>
                <CloseOutlined color={"disabled"}
                               sx={{ cursor: "pointer", mx: 2, mt: 2, p: 2, width: 64, height: 64 }}
                               onClick={handleCloseNavMenu} />
              </Grid>
            </Grid>
            {pages.map((page) => (
              <Grid xs={12} key={page?.title} onClick={() => onMenuItemClick(page)}>
                <Typography textAlign="center" variant={"h5"} fontWeight={400}>
                  {page?.title}
                </Typography>
                <Divider sx={{ mx: 2, mt: 4 }} />
              </Grid>
            ))}
            <Grid xs={12} onClick={onPricingClick}>
              <Typography textAlign="center" variant={"h5"} fontWeight={400}>
                {t("website.header.pricing")}
              </Typography>
              <Divider sx={{ mx: 2, mt: 4 }} />
            </Grid>
            <Grid xs={12} textAlign="center" sx={{
              "& .MuiSelect-select": {
                fontSize: "24px",
                textAlign: "center"
              },
              "& .MuiInputBase-root": {
                fontSize: "24px"
              }
            }}>
              <LanguageSwitcher />
            </Grid>
            {!isRegistered && <Grid xs={8} textAlign={"center"}>
              <Button
                fullWidth
                variant={"outlined"}
                color="primary"
                size="large"
                onClick={triggerSignInWithPopup}
                sx={{
                  border: 1, borderColor: "primary.main", borderRadius: 8
                }}>
                {t("app.buttons.resultsButton")}
              </Button>
            </Grid>}
          </Grid>}
      </Box>;
    }

    return <Grid container xs={12} justifyContent={"space-evenly"}>
      <Grid alignSelf={"center"}>
        {getMobileMenuPopup()}
      </Grid>
      <Grid xs alignSelf={"center"} textAlign={"center"}>
        {getLogo()}
      </Grid>
      {isRegistered && <RequestsLeftCounter />}
      <GoogleAuthButton />
    </Grid>;
  }

  function getDesktopView() {
    return <Grid container xs={12}>
      <Grid alignSelf={"center"}>
        {getLogo()}
      </Grid>
      <Grid xs container justifyContent={"right"}>
        <Grid container alignSelf={"center"} justifyContent={"center"}
              xs={isLandingPageScreen ? true : 4.5}
              sx={{ mr: isLgDown ? 0 : -12 }}>
          {isLandingPageScreen && <>
            {getPages()}
          </>}
        </Grid>
        <Grid alignSelf={"center"} textAlign={"center"}>
          {isRegistered ? <RequestsLeftCounter /> : getSocialIcons()}
        </Grid>
      </Grid>
      <Grid alignSelf={"center"}>
        <GoogleAuthButton />
      </Grid>
      <Grid alignSelf={"center"} sx={{ ml: 1 }}>
        <LanguageSwitcher />
      </Grid>
    </Grid>;
  }

  return (
    <AppBar position="static" sx={{ bgcolor: "white" }} elevation={1}>
      <Container maxWidth="lg" sx={{ px: "16px! important" }}>
        <Toolbar disableGutters style={{ height: 64 }}>
          {isSmUp ? getDesktopView() : getMobileView()}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
