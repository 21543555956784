import HowCanHelp1 from "../../icons/image1_ups.webp";
import HowCanHelp2 from "../../icons/image2_ups.webp";
import HowCanHelp3 from "../../icons/image3_ups.webp";
import Grid from "@mui/material/Unstable_Grid2";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

export function HowThisAiToolCanHelpComponent() {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  return (
    <Grid xs={12} md={10} lg={8} container spacing={8} sx={{ mt: 8 }}>
      <Grid xs={12} textAlign={"center"}>
        <Typography variant="h2" color={"primary"} fontWeight={400}
                    id={"benefits"}
                    sx={{
                      mr: 1,
                      fontSize: { xs: 46, sm: 32 },
                      background: "linear-gradient(270.17deg, #A57DD3 3.88%, #0040FF 98.08%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      textFillColor: "transparent"
                    }}>
          {t("website.howThisAiToolCanHelp.mainTitle")}
        </Typography>
      </Grid>
      <Grid container xs={12} direction={"row"}>
        <Grid xs={12} sm={6}
              sx={{ alignSelf: "center", display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6" lineHeight={1.2}>
            {t("website.howThisAiToolCanHelp.section1_title")}
          </Typography>
          <Typography variant="body1">
            {t("website.howThisAiToolCanHelp.section1_description")}
          </Typography>
        </Grid>
        <Grid xs={12} sm={6} textAlign={"right"}>
          <img style={{ width: isDesktop ? "80%" : "100%", borderRadius: 4 }} src={HowCanHelp1}
               alt={t("website.howThisAiToolCanHelp.section1_title")} />
        </Grid>
      </Grid>
      <Grid container xs={12} direction={"row-reverse"}>
        <Grid xs={12} sm={6}
              sx={{ alignSelf: "center", display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6" lineHeight={1.2}>
            {t("website.howThisAiToolCanHelp.section2_title")}
          </Typography>
          <Typography variant="body1">
            {t("website.howThisAiToolCanHelp.section2_description")}
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <img style={{ width: isDesktop ? "80%" : "100%", borderRadius: 4 }} src={HowCanHelp2}
               alt={t("website.howThisAiToolCanHelp.section2_title")} />
        </Grid>
      </Grid>
      <Grid container xs={12} direction={"row"}>
        <Grid xs={12} sm={6}
              sx={{ alignSelf: "center", display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6" lineHeight={1.2}>
            {t("website.howThisAiToolCanHelp.section3_title")}
          </Typography>
          <Typography variant="body1">
            {t("website.howThisAiToolCanHelp.section3_description")}
          </Typography>
        </Grid>
        <Grid xs={12} sm={6} textAlign={"right"}>
          <img style={{ width: isDesktop ? "80%" : "100%", borderRadius: 4 }} src={HowCanHelp3}
               alt={t("website.howThisAiToolCanHelp.section3_title")} />
        </Grid>
      </Grid>
    </Grid>
  );
}
