import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  query,
  updateDoc,
  where
} from "firebase/firestore";
import { firestoreDb } from "./firebaseUtils";
import moment from "moment";
import { firebaseTimestampToDate } from "./dateUtils";

const DB_PATH_USERS = "users";
const DB_PATH_CODES = "partners/appsumo/codes";


export const useListenUserChanges = (userId?: string) => {
  const [dbUser, setDbUser] = useState<DocumentData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      setError(new Error("No user ID provided"));
      return;
    }

    const userDocRef = doc(firestoreDb, DB_PATH_USERS, userId);

    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        setDbUser(snapshot.data());
      } else {
        setError(new Error("User document does not exist"));
      }
      setLoading(false);
    }, (err) => {
      setError(err);
      setLoading(false);
    });

    // Clean up subscription on unmount
    return () => {
      unsubscribe();
    };
  }, [userId]);

  return { dbUser, loading, error };
};

interface CodeDetails {
  code: string;
  createdAt: Date | null;
  isRedeemed: boolean;
  isRefunded: boolean;
  redeemedAt: Date | null;
  refundedAt: Date | null;
  userId: string | null;
}

export const useUserCodes = (userId?: string): CodeDetails[] => {
  const [codes, setCodes] = useState<CodeDetails[]>([]);

  useEffect(() => {
    if (!userId) {
      console.error("No user ID provided");
      setCodes([]);  // Clears any existing codes if the userId is not set
      return;  // Stop the effect if no userId is provided
    }

    // Reference to the user's codes collection in Firestore
    const codesCollectionRef = collection(firestoreDb, DB_PATH_CODES);

    // Create a query object for codes that belong to the user, filter for is_redeemed=true and is_refaunded=false
    const codesQuery = query(codesCollectionRef, where("user_id", "==", userId));

    // Subscribe to real-time updates with onSnapshot
    const unsubscribe = onSnapshot(codesQuery, (snapshot) => {
      const userCodes: CodeDetails[] = [];
      snapshot.forEach((doc) => {
        const documentData = doc.data();
        userCodes.push({
          code: doc.id,
          createdAt: documentData?.created_at ? moment(firebaseTimestampToDate((documentData?.created_at))).toDate() : null,
          isRedeemed: documentData.is_redeemed,
          isRefunded: documentData.is_refaunded,
          redeemedAt: documentData.redeemed_at ? moment(firebaseTimestampToDate(documentData.redeemed_at)).toDate() : null,
          refundedAt: documentData.refaunded_at ? moment(firebaseTimestampToDate(documentData.refaunded_at)).toDate() : null,
          userId: documentData.user_id
        } as CodeDetails);
      });
      setCodes(userCodes);  // Update state with the fetched codes
    }, (error) => {
      console.error("Error fetching user codes:", error);
      setCodes([]);  // Handle potential errors by setting codes to null
    });

    // Cleanup the listener when the component unmounts or userId changes
    return () => unsubscribe();
  }, [userId]);  // Re-run the effect if userId changes

  return codes;
};

const cleanEmpty = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj
      .map(v => (v && typeof v === "object") ? cleanEmpty(v) : v)
      .filter(v => !(v == null));
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === "object" ? cleanEmpty(v) : v])
      // @ts-ignore
      .reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
  }
};

export const setProductHuntLaunchBannerRejected = async (userId?: string) => {
  if (userId) {
    try {
      const userDocRef = doc(firestoreDb, `users/${userId}`);
      await updateDoc(userDocRef, {
        "flags.isProductHuntLaunchBannerRejected": true
      });
    } catch (error) {
      console.error("isProductHuntLaunchBannerRejected :: Error updating user flags:", error);
    }
  } else {
    console.error("isProductHuntLaunchBannerRejected :: No user ID provided");
  }
};

export const setProductHuntLaunchBannerClicked = async (userId?: string) => {
  if (userId) {
    try {
      const userDocRef = doc(firestoreDb, `users/${userId}`);
      await updateDoc(userDocRef, {
        "flags.isProductHuntLaunchBannerClicked": true
      });
    } catch (error) {
      console.error("setProductHuntLaunchBannerClicked :: Error updating user flags:", error);
    }
  } else {
    console.error("setProductHuntLaunchBannerClicked :: No user ID provided");
  }
};
