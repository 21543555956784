import { blueGrey, green, red } from "@mui/material/colors";
import { CookieConsent } from "react-cookie-consent";
import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function CookieConsentComponent() {
  const { t } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      cookieName="aiter-cookies-v1"
      enableDeclineButton
      flipButtons
      buttonText={t("app.labels.cookieConsentAccept")}
      buttonStyle={{
        background: green[500],
        borderRadius: 2,
        color: "white"
      }}
      declineButtonText={t("app.labels.cookieConsentDecline")}
      declineButtonStyle={{
        background: red[700] + "AA",
        borderRadius: 2
      }}
      style={{
        background: blueGrey[900] + "EE",
        margin: 32,
        padding: 8,
        paddingLeft: 32,
        paddingRight: 32,
        width: "calc(100% - 64px)",
        borderRadius: 8,
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
      }}
      expires={365}
    >
      <Typography variant={"body1"} sx={{ color: "white" }}>
        {t("app.labels.cookieConsentText")}
        <a href={"/cookies"} target={"_blank"} style={{ color: "white" }}>
          {t("app.labels.cookiePolicyLinkText")}
        </a>
      </Typography>
    </CookieConsent>
  );
}
