import React from "react";
import { BackgroundContainer } from "../components/BackgroundContainer";
import { trackClickGetAdsAndIdeas1, trackClickGetAdsAndIdeas2 } from "../utils/analytics";
import "../styles/shake-animation.css";
import HeaderComponent from "../components/website/HeaderComponent";
import { HowDoesItWorkComponent } from "../components/website/HowDoesItWorkComponent";
import { HowThisAiToolCanHelpComponent } from "../components/website/HowThisAiToolCanHelpComponent";
import { GetResultsButton } from "../components/website/GetResultsButton";
import {
  CreateAdsContentAndStrategyFasterComponent
} from "../components/website/CreateAdsContentAndStrategyFasterComponent";
import { FaqComponent } from "../components/website/FaqComponent";
import FooterComponent from "../components/website/FooterComponent";
import "firebase/firestore";
import "firebase/compat/firestore";
import { UpgradePopup } from "../components/website/UpgradePopup";
import { CookieConsentComponent } from "../components/website/CookieConsentComponent";
import { AiterAppComponent } from "../components/app/AiterAppComponent";
import Grid from "@mui/material/Unstable_Grid2";
import { Container, Theme, useMediaQuery } from "@mui/material";
import { useFirebaseLoginState } from "../utils/hooks";
import { useTranslation } from "react-i18next";
import { PricingSection } from "../components/website/PricingSection";
import { BadgesSectionComponent } from "../components/website/BadgesSectionComponent";
import CustomPricingTable from "../components/website/CustomPricingTable";

export default function LandingPage() {
  const { isRegistered } = useFirebaseLoginState();
  const { t } = useTranslation();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  return <BackgroundContainer>
    <HeaderComponent />
    <Container maxWidth={"xl"}>
      <Grid container justifyContent={"center"}
            sx={{ m: { xs: 1, sm: 4, xl: 8 }, p: { xs: 0, sm: 2, xl: 3 } }}>
        <UpgradePopup />
        <AiterAppComponent />
        <BadgesSectionComponent />
        <HowDoesItWorkComponent />
        <HowThisAiToolCanHelpComponent />
        <GetResultsButton size={"large"} text={t("app.buttons.getAdsAndIdeas")} mt={6}
                          callback={trackClickGetAdsAndIdeas1} />
        <CreateAdsContentAndStrategyFasterComponent />
        <GetResultsButton size={"large"} text={t("app.buttons.getAdsAndIdeas")}
                          mt={isDesktop ? 8 : 24}
                          callback={trackClickGetAdsAndIdeas2} />
        {isRegistered ? <PricingSection /> : <CustomPricingTable />}
        <FaqComponent />
      </Grid>
    </Container>
    <FooterComponent />
    <CookieConsentComponent />
  </BackgroundContainer>;
}
