import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReportProblemRounded, StarRounded } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  styled,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { green, yellow } from "@mui/material/colors";

export const InfoIcon = styled(InfoOutlinedIcon)({
  width: 24,
  height: 24,
  color: "secondary.light"
});

export const AlertIcon = styled(ReportProblemRounded)({
  width: 24,
  height: 24,
  color: yellow[800]
});

export const StarIcon = styled(StarRounded)({
  width: 24,
  height: 24,
  color: green[500]
});

export function InfoTooltipView({
                                  text,
                                  onTooltipOpenCallback,
                                  icon = <InfoIcon />,
                                  forceOpen,
                                  isDisabled
                                }: {
  text?: JSX.Element | string,
  onTooltipOpenCallback?: () => void,
  icon?: JSX.Element,
  forceOpen?: boolean,
  isDisabled?: boolean
}) {
  const [isOpen, setOpen] = useState(forceOpen === undefined ? undefined : forceOpen);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  useEffect(() => {
    if (!isDisabled) {
      setOpen(forceOpen);
    }
  }, [forceOpen, isDisabled]);

  function handleTooltipClose() {
    if (!isDisabled) {
      setOpen(false);
    }
  }

  function handleTooltipOpen() {
    if (isDisabled) return;
    setOpen(true);
    if (onTooltipOpenCallback) onTooltipOpenCallback();
  }

  const displayText = text ? (
    <Typography variant="caption" color="primary.contrastText" textAlign={"center"}>
      {text}
    </Typography>
  ) : null;

  const content = <span>{icon}</span>;

  if (!text) {
    return content;
  }

  if (isDesktop) {
    return (
      <Tooltip
        sx={{ alignItems: "center" }}
        open={isDisabled ? false : isOpen}
        title={displayText}
        arrow
        onOpen={handleTooltipOpen}
        placement="top"
      >
        {content}
      </Tooltip>
    );
  } else {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={displayText}
          placement="top"
          open={isDisabled ? false : isOpen}
          arrow
          onClose={handleTooltipClose}
          leaveTouchDelay={5000}
        >
          <Box
            onClick={(event) => {
              event.stopPropagation();
              if (!isDisabled) {
                handleTooltipOpen();
              }
            }}
          >
            {content}
          </Box>
        </Tooltip>
      </ClickAwayListener>
    );
  }
}
