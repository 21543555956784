import { IS_DEV_BASE_URL, IS_LOCAL_BASE_URL } from "./env";
import { Creativity } from "../components/app/CreativityLevel";
import { languagesType } from "./translations";
import { trackSentryError } from "./analytics";

const API_BASE_URL_LOCAL = "http://127.0.0.1:5001/aiter-ai-dev/europe-west1";
const API_BASE_URL_DEV = "https://europe-west1-aiter-ai-dev.cloudfunctions.net";
const API_BASE_URL_PROD = "https://europe-west1-aiter-ai.cloudfunctions.net";

const BASE_URL = IS_LOCAL_BASE_URL
  ? API_BASE_URL_LOCAL
  : IS_DEV_BASE_URL
    ? API_BASE_URL_DEV
    : API_BASE_URL_PROD;

export const REQ = [
  "FACEBOOK_AD_TEXT_IMG_IDEAS",
  "FACEBOOK_BARRIERS",
  "FACEBOOK_POSTS",
  "FACEBOOK_POST_IDEAS",
  "FACEBOOK_NEED_SPECIFIC_ADS",
  "GOOGLE_ADS_TEXT",
  "GOOGLE_NEED_SPECIFIC_ADS",
  "GOOGLE_ADS_BARRIERS",
  "GOOGLE_ADS_DISPLAY",
  "GOOGLE_ADS_VIDEO",
  "STRATEGY_BARRIERS",
  "STRATEGY_JOBS_TO_BE_DONE",
  "STRATEGY_CUSTOMER_PERSONA",
  "STRATEGY_MARKETING_IDEAS",
  "STRATEGY_VALUE_PROPOSITION",
  "STRATEGY_POSITIONING",
  "STRATEGY_CONSUMER_DECISION_JOURNEY",
  "STRATEGY_BUSINESS_MODEL_CANVAS",
  "STRATEGY_VALUE_PROPOSITION_CANVAS",
  "FRAMEWORKS_STEPPS",
  "FRAMEWORKS_7P_OF_MARKETING",
  "FRAMEWORKS_SWOT",
  "FRAMEWORKS_PORTERS_FIVE_FORCES",
  "FRAMEWORKS_AIDA",
  "FRAMEWORKS_AARRR",
  "FRAMEWORKS_LEAN_ANALYTICS_STAGES",
  "FRAMEWORKS_HOOK_FRAMEWORK",
  "FRAMEWORKS_ICE_SCORE",
  "FRAMEWORKS_ANSOFF_MATRIX",
  "EMAIL_SUBJECT",
  "EMAIL_PROMOTIONAL",
  "EMAIL_MARKETING_TOPICS",
  "LINKEDIN_AD_TEXT_IMG_IDEAS",
  "LINKEDIN_NEED_SPECIFIC_ADS",
  "LINKEDIN_BARRIERS",
  "LINKEDIN_POSTS",
  "LINKEDIN_POST_IDEAS",
  "INSTAGRAM_AD_TEXT_IMG_IDEAS",
  "INSTAGRAM_CAPTIONS",
  "INSTAGRAM_POST_IDEAS",
  "INSTAGRAM_BARRIERS",
  "INSTAGRAM_NEED_SPECIFIC_ADS",
  "INSTAGRAM_JOKES_AND_STORIES",
  "INSTAGRAM_HASHTAG",
  "INSTAGRAM_ADS_VIDEO",
  "TIKTOK_VIDEO",
  "TIKTOK_CONTENT_STRATEGY",
  "TIKTOK_HASHTAGS",
  "TIKTOK_BIO",
  "PR_LIST_OF_MEDIA_TO_USE_FOR_PROMO",
  "PR_CAMPAIGN_IDEAS",
  "PR_ARTICLE_IDEAS",
  "PR_GUEST_ARTICLE",
  "YOUTUBE_TITLES_PROMO_VIDEO",
  "YOUTUBE_VIDEO_IDEAS",
  "YOUTUBE_KEYWORDS",
  "YOUTUBE_ADS_VIDEO",
  "BLOG_IDEAS",
  "BLOG_PROMO_ARTICLE",
  "BLOG_KEYWORDS",
  "BLOG_FAQ",
  "SEO_COMMON_KEYWORDS",
  "SEO_LONG_TAIL_KEYWORDS",
  "SEO_META_TITLE_AND_DESCRIPTION",
  "BRAND_GUIDE",
];
export type TypeOfRequest = typeof REQ[number];

export interface UrlSummary {
  summary: string;
  title?: string;
  description?: string;
  favicon?: string;
  tokensInput: number;
  tokensOutput: number;
  tokensTotal: number;
}

interface OpenAIResponseData {
  text_response: string;
  tokens_input: number;
  tokens_output: number;
  tokens_total: number;
}

interface OpenAIResponse {
  responseText: string;
  tokensInput: number;
  tokensOutput: number;
  tokensTotal: number;
}

interface OpenAIResponseAPI {
  data: OpenAIResponseData;
}

export class ReqError extends Error {
  constructor(public message: string, public status: number) {
    super(message);
    this.name = "RequestError";
  }
}

export async function register(userId: string) {
  try {
    const response = await fetch(`${BASE_URL}/register?user_id=${userId}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    trackSentryError(`Failed to register user`,{
      userId: userId,
      error: error
    });
    throw error;
  }
}


export async function fetchUrlSummary(userId?: string, url?: string, language?: string): Promise<UrlSummary> {
  let requestUrl = `${BASE_URL}/summarize`;

  const params = new URLSearchParams();
  if (url) {
    params.append("url", encodeURIComponent(url));
  }
  if (userId) {
    params.append("user_id", userId);
  }
  if (language) {
    params.append("language", language);
  }

  const response = await fetch(`${requestUrl}?${params.toString()}`);

  if (response.ok) {
    const jsonPayload = await response.json();

    if (jsonPayload.data && typeof jsonPayload.data === "object") {
      const {
        summary: summary,
        title: title,
        description: description,
        favicon: favicon,
        tokens_input: tokensInput,
        tokens_output: tokensOutput,
        tokens_total: tokensTotal
      } = jsonPayload.data;

      return {
        summary: summary,
        title: title,
        description: description,
        favicon: favicon,
        tokensInput: tokensInput,
        tokensOutput: tokensOutput,
        tokensTotal: tokensTotal
      };
    }
    throw new Error("Unexpected JSON structure from the API");
  } else {
    const jsonError = await response.json();
    if (jsonError.message) {
      throw new ReqError(jsonError.message, response.status);
    } else {
      throw new ReqError("Unknown error occurred", response.status);
    }
  }
}

export async function fetchOpenAIResponse(
  userId?: string,
  type?: TypeOfRequest,
  summary?: string,
  creativity?: Creativity,
  session?: string,
  title?: string,
  description?: string,
  language?: string
): Promise<OpenAIResponse> {
  const params = new URLSearchParams();
  if (userId) {
    params.append("user_id", userId);
  }
  if (type) {
    params.append("type", type);
  }
  if (summary) {
    params.append("summary", summary);
  }
  if (creativity) {
    params.append("creativity", creativity);
  }
  if (session) {
    params.append("session", session);
  }
  if (title) {
    params.append("title", title);
  }
  if (description) {
    params.append("description", description);
  }
  if (language) {
    params.append("language", language);
  }

  const response = await fetch(`${BASE_URL}/get_response_open_ai?${params.toString()}`);

  if (response.ok) {
    const responseBody: OpenAIResponseAPI = await response.json();
    const {
      text_response: responseText,
      tokens_input: tokensInput,
      tokens_output: tokensOutput,
      tokens_total: tokensTotal
    } = responseBody.data;

    return {
      responseText: responseText,
      tokensInput: tokensInput,
      tokensOutput: tokensOutput,
      tokensTotal: tokensTotal
    };
  } else {
    const errorText = await response.text();
    throw new Error(errorText);
  }
}

export async function redeemAppsumoCode(userId: string | undefined, code: string) {
  const response = await fetch(`${BASE_URL}/redeem_code?user_id=${userId}&code=${code}`);
  if (response.ok) {
    const jsonPayload = await response.json();
    const { is_success, code, redeemed_at, user_id } = jsonPayload.data;
    return { is_success, code, redeemed_at, user_id };
  } else {
    const errorText = await response.text();
    throw new Error(errorText);
  }
}
