import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { trackExpandFaqSection } from "../../utils/analytics";

export function FaqComponent() {
  const [expanded, setExpanded] = useState<number | false>(0);
  const { t } = useTranslation();

  const handleChange = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? index : false);
    trackExpandFaqSection(t(`website.faq.item${index + 1}_title`));
  };

  return (
    <Grid sm={12} md={10} lg={8} container spacing={8} sx={{ mt: 8 }}>
      <Grid xs={12} textAlign={"center"}>
        <Typography variant="h1" color={"primary"} fontWeight={400} sx={{
          mr: 1, mb: 1,
          fontSize: { xs: 46, sm: 32 },
          background: "linear-gradient(270.17deg, #A57DD3 3.88%, #0040FF 98.08%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent"
        }}>
          {t("website.faq.mainTitle")}
        </Typography>
        <Typography variant="h6" color={"primary"} fontWeight={400} display={"inline"}>
          <div dangerouslySetInnerHTML={{ __html: t("website.faq.additionalText") }} />
        </Typography> <Typography component={"a"} target={"_blank"} display={"inline"}
                    href={t("website.faq.supportLinkUrl")} variant="h6" color={"primary"}
                    fontWeight={400}>
          {t("website.faq.supportLinkText")}
        </Typography>
      </Grid>
      <Grid xs={12} container spacing={2}>
        {Array.from({ length: 16 }).map((_, index) => (
          <Accordion key={index} sx={{ width: "100%" }}
                     expanded={expanded === index}
                     onChange={handleChange(index)}>
            <AccordionSummary
              sx={{ backgroundColor: expanded === index ? "primary.light" : undefined }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
              <Typography sx={{ fontWeight: expanded === index ? 600 : undefined }}>
                {t(`website.faq.item${index + 1}_title`)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component={"span"}>
                {t(`website.faq.item${index + 1}_description`)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
}
