import { Mode, SwitchComponent } from "./SwitchComponent";
import React, { useEffect, useRef, useState } from "react";
import { Creativity, CreativityLevel, CreativityLevelData } from "./CreativityLevel";
import { motion } from "framer-motion";
import Grid from "@mui/material/Unstable_Grid2";
import LoadingLottieAnimationAnonymousUsers from "./LoadingLottieAnimationAnonymousUsers";
import {
  INITIAL_STATE_FULL,
  NUMBER_OF_FIELDS_TO_SHOW_NON_AUTH_USERS,
  NUMBER_OF_TABS_TO_SHOW_NON_AUTH_USERS,
  resetState,
  State,
  updateActiveTabIndex,
  updateContent,
  updateCreativity,
  updateOutputLanguage,
  updateStateLoading,
  updateWebsiteDetails
} from "../../utils/state";
import { keys, values } from "lodash";
import { GoogleAuthButton } from "../website/GoogleAuthButton";
import {
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Theme,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  hashInputs,
  isTextWithMarkdown,
  markdownToPlainText,
  postProcessSummary
} from "../../utils/text";
import { FieldComponentMarkdown } from "./FieldComponentMarkdown";
import { FieldComponentWithCopy } from "./FieldComponentWithCopy";
import { RegenerateButton } from "./RegenerateButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { useFirebaseLoginState } from "../../utils/hooks";
import { fetchOpenAIResponse, fetchUrlSummary, ReqError } from "../../utils/api";
import {
  trackClickGetResultsButtonBody,
  trackClickRegenerate,
  trackOpenAiTokenConsumption,
  trackOutputLanguageChange,
  trackSelectCreativityLevel,
  trackSelectManualMode,
  trackSelectTab,
  trackSelectUrlMode,
  trackSentryError,
  trackSubmitWebsiteURL
} from "../../utils/analytics";
import { toast } from "react-toastify";
import { showUpgradePopup } from "../website/UpgradePopup";
import { LoadingButton } from "@mui/lab";
import { InfoTooltipView } from "../InfoTooltipView";
import { green } from "@mui/material/colors";
import "firebase/compat/firestore";
import { addRegenerationsUsed } from "../../utils/amplitude";
import { UpgradeButton } from "../website/RequestsLeftCounter";
import { STRIPE_URL_MANAGE_PLAN } from "../../App";
import { Rocket } from "@mui/icons-material";
import LoadingResultsComponentRegisteredUsers from "./LoadingResultsComponentRegisteredUsers";
import { useTranslation } from "react-i18next";
import { OutputLanguageData, OutputLanguageSelect } from "./OutputLanguageSelect";
import { OUTPUT_LANGUAGE_DATA } from "../../utils/translations";
import { Page, PdfGeneratorButton, PdfGeneratorProps } from "./PdfGeneratorButton";

const LOADING_SECTION_COMPONENT = <CircularProgress />;

export function isPaidUserStatus(subscriptionStatus?: string) {
  return subscriptionStatus === "paid_active" || subscriptionStatus === "paid_active_limited" || subscriptionStatus === "canceled_active";
}

function generateReportData(state: State): PdfGeneratorProps | undefined {
  const reportData = {
    reportName: "Generated Results",
    dateTime: new Date(),
    marketingBrief: markdownToPlainText(state.summary),
    pages: [] as Page[],
    serviceName: state.title,
    websiteUrl: state.url
  };

  // Generate pages based on tabs, filtering out empty content
  for (const [tabKey, tabValue] of Object.entries(state.tabs)) {
    for (const [_, fieldValue] of Object.entries(tabValue.fields)) {
      if (!!fieldValue?.content?.trim()?.length) {
        const content = markdownToPlainText(fieldValue.content);
        reportData.pages.push({
          title: tabKey,
          subtitle: fieldValue?.name || "",
          subtitleDescription: fieldValue?.description || "",
          content: content
        });
      }
    }
  }

  const isReportReady = !!state.summary?.trim()?.length || !!reportData?.pages?.length;
  console.log("isReportReady", isReportReady);
  return isReportReady ? reportData : undefined;
}

export function AiterAppComponent() {
  const state = useSelector((state: RootState) => state.appState);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const signInButtonReponseSectionRef = useRef<HTMLDivElement>(null);

  const { isAnonymous, isRegistered, userId, user } = useFirebaseLoginState();

  const [mode, setMode] = useState<Mode>("url");
  const [url, setUrl] = useState<string>(state?.url || "");
  const [urlError, setUrlError] = useState<string>();
  const [favicon, setFavicon] = useState<string | null>(state?.favicon || null);
  const [summary, setSummary] = useState<string>(state?.summary || "");
  const [title, setTitle] = useState<string>(state?.title || "");
  const [description, setDescription] = useState<string>(state?.description || "");
  const [creativity, setCreativity] = useState<Creativity>(state?.creativity || INITIAL_STATE_FULL?.creativity);
  const [outputLanguage, setOutputLanguage] = useState<OutputLanguageData>(state?.outputLanguage || OUTPUT_LANGUAGE_DATA.find(lang => lang.code === i18n.language) || OUTPUT_LANGUAGE_DATA[0]);
  const [isLoadingWebsiteDetails, setLoadingWebsiteDetails] = useState<boolean>(false);
  const [isLoadingGetResultsButton, setLoadingGetResultsButton] = useState<boolean>(false);

  const isAnyLoading = isLoadingWebsiteDetails || values(state?.tabs)?.map((tab) => values(tab?.fields).map((field) => field?.isLoading)).flat().some((value) => !!value);
  const isAnythingToShow = !urlError && values(state?.tabs)?.map((tab) => values(tab?.fields).map((field) => !!field?.content?.trim()?.length)).flat().some((value) => !!value);

  const [copiedField, setCopiedField] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get("lng"); // 'lng' is the expected query parameter

    if (lang && i18n.languages.includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }, []);

  useEffect(() => {
    fetchRequestsIfRequired();
  }, [state?.activeTabIndex, keys(values(state?.tabs)[0]?.fields)?.length]);

  useEffect(() => {
    dispatch(resetState({ isRegistered: isRegistered }));
  }, [isRegistered]);

  // ##########################################
  //
  // LOGIC
  //
  // ##########################################

  function resetBrandDetailsInfo() {
    setSummary("");
    setFavicon(null);
    setLoadingWebsiteDetails(false);
    setUrlError(undefined);
  }

  function resetOpenAPIInfo() {
    dispatch(resetState({ isRegistered: isRegistered }));
  }

  function fetchField(fieldCode: string, summary: string, title?: string, description?: string) {
    dispatch(updateStateLoading({ field: fieldCode, isLoading: true }));
    fetchOpenAIResponse(userId, fieldCode, summary, creativity, state?.session, title, description, outputLanguage.name)
      .then((response) => {
        const { responseText, tokensInput, tokensOutput, tokensTotal } = response;
        const currentHash = hashInputs(summary, creativity, url, outputLanguage.name);
        dispatch(updateContent({ field: fieldCode, content: responseText, hash: currentHash }));
        dispatch(updateCreativity(creativity));
        dispatch(updateOutputLanguage(outputLanguage));

        trackOpenAiTokenConsumption(fieldCode, "input", tokensInput);
        trackOpenAiTokenConsumption(fieldCode, "output", tokensOutput);
        trackOpenAiTokenConsumption(fieldCode, "total", tokensTotal);
      })
      .catch((response: string) => {
        trackSentryError(`Error fetching field ${fieldCode}`, { response });
        toast.error(response);
        dispatch(updateStateLoading({ field: fieldCode, isLoading: false }));
      });
  }

  async function fetchRequestsIfRequired(summaryValue?: string, titleValue?: string, descriptionValue?: string) {
    const summaryFinal = summaryValue || summary;
    const titleFinal = titleValue || title;
    const descriptionFinal = descriptionValue || description;
    if (!!summaryFinal) {
      const currentHash = hashInputs(summaryFinal, creativity, url, outputLanguage.name);
      const activeTab = values(state?.tabs)[state?.activeTabIndex];
      const activeNonLoadingFieldsKeys = activeTab?.fields && Object.keys(activeTab?.fields).filter((key) => !activeTab?.fields?.[key]?.isLoading);
      const activeNonLoadingFieldsWithChangedHash = activeNonLoadingFieldsKeys?.filter((key) => {
        const itemHash = activeTab?.fields?.[key]?.hash;
        return itemHash !== currentHash;
      });
      for (const fieldCode of activeNonLoadingFieldsWithChangedHash || []) {
        fetchField(fieldCode, summaryFinal, titleFinal, descriptionFinal);
      }
    }
  }

  function fetchWebsiteDetails(url: string | undefined) {
    if (url && !isAnyLoading) {
      trackSubmitWebsiteURL(url);

      resetBrandDetailsInfo();
      resetOpenAPIInfo();

      setLoadingWebsiteDetails(true);
      fetchUrlSummary(userId, url, outputLanguage.name)
        .then((response) => {
          const {
            summary,
            favicon,
            title,
            description
          } = response;

          setSummary(summary || "");
          setFavicon(favicon || "");
          setTitle(title || "");
          setDescription(description || "");

          dispatch(updateWebsiteDetails({
            url: url,
            summary: summary || "",
            title: title || "",
            description: description || "",
            favicon: favicon || ""
          }));

          if (summary?.trim()) {
            scrollToButton();
            fetchRequestsIfRequired(summary, title, description);
          }

          setLoadingWebsiteDetails(false);
        })
        .catch((response: ReqError) => {
          if (response.status >= 400 && response.status <= 500) {
            setUrlError(response.message);

            trackSentryError(`Error in fetchWebsiteDetails`,
              { response: response.message, userId, url, language: i18n.language });

            setSummary("");
            setFavicon("");
            setTitle("");
            setDescription("");

            dispatch(updateWebsiteDetails({
              url,
              summary: "",
              title: "",
              description: "",
              favicon: ""
            }));
          }
          setLoadingWebsiteDetails(false);
        });
    } else {
      console.warn(`fetchWebsiteDetails: no url or already loading: ${isAnyLoading}`);
    }
  }

  function scrollToButton() {
    setTimeout(() => {
      const element = document.getElementById("GET_RESULTS_BUTTON");
      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY - 200;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 300);
  }

  function enableButtonLoadingFor5Sec() {
    setLoadingGetResultsButton(true);
    // disable loading in 5 seconds
    setTimeout(() => {
      setLoadingGetResultsButton(false);
    }, 5000);
  }

  // ##########################################
  //
  // EVENT HANDLERS
  //
  // ##########################################

  function onSummaryChangeHandler(text: string) {
    setSummary(text);
  }

  async function onSubmitButtonHandler() {
    const isReqFinishedFreemium = isRegistered && state?.subcription?.status === "freemium" && (!state?.subcription?.requestsLeft || state?.subcription?.requestsLeft <= 0);
    const isReqFinishedForPaidUser = isRegistered && state?.subcription?.status !== "freemium" && state?.subcription?.requestsLeft === 0;
    if (isReqFinishedFreemium && !isReqFinishedForPaidUser) {
      showUpgradePopup();
      return;
    }

    if (!isAnyLoading) {
      dispatch(updateActiveTabIndex(0));
      trackClickGetResultsButtonBody(mode);

      if (mode === "url") {
        const isAnyInputChanged = !state?.url || state?.url?.trim()?.indexOf(url?.trim()) === -1 || state?.creativity !== creativity || state?.outputLanguage !== outputLanguage;
        if (summary?.trim()?.length && !isAnyInputChanged) {
          setUrlError(undefined);
          scrollToButton();
          enableButtonLoadingFor5Sec();
          await fetchRequestsIfRequired(summary, title, description);
        } else if (!url?.trim()?.length) {
          toast.error(t("app.alerts.enterValidUrl"));
        } else if (isAnyInputChanged) {
          enableButtonLoadingFor5Sec();
          fetchWebsiteDetails(url);
        } else {
          console.warn(`onSubmitButtonHandler: url is not changed: ${url}, ${state?.url}`);
        }
      } else if (mode === "manual") {
        if (!summary?.trim()?.length) {
          toast.error(t("app.alerts.enterValidSummary"));
          return;
        }
        resetOpenAPIInfo();
        setUrlError(undefined);
        scrollToButton();
        enableButtonLoadingFor5Sec();
        await fetchRequestsIfRequired(summary, title, description);
      } else {
        console.warn(`onSubmitButtonHandler: unknown mode: ${mode}`);
      }
    }
  }

  function onSelectedCreativityLvlHandler(lvl: CreativityLevelData) {
    setCreativity(lvl.type);
    trackSelectCreativityLevel(lvl.type);
  }

  function onOutputLanguageChangeHandler(newOutputLanguage: OutputLanguageData) {
    setOutputLanguage(newOutputLanguage);
    trackOutputLanguageChange(newOutputLanguage.name);
  }

  function onHandleTabChange(index: number) {
    dispatch(updateActiveTabIndex(index));

    const newTabName = Object.keys(state?.tabs)[index];
    const currentTabName = Object.keys(state?.tabs)[state?.activeTabIndex];
    if (newTabName !== currentTabName) {
      trackSelectTab(newTabName);
    }
  }

  const modeChangeHandler = (newMode: Mode) => {
    if (newMode !== mode) {
      if (newMode === "url") {
        trackSelectUrlMode();
      } else {
        trackSelectManualMode();
      }
    }
    setMode(newMode);
  };

  // ##########################################
  //
  // UI
  //
  // ##########################################

  function getSubmitButtonBlock() {
    const isInitialState = !state?.summary?.trim()?.length && !state?.url?.trim()?.length;
    const isSummaryEmpty = !summary?.trim()?.length;
    const isUrlEmpty = !url?.trim()?.length;
    const isRequiredFieldsEmpty = mode === "url"
      ? isUrlEmpty
      : isSummaryEmpty;
    const isAnyInputChanged = mode === "url"
      ? state?.url?.trim() !== url?.trim() || state?.summary?.trim() !== summary?.trim() || state?.creativity !== creativity || state?.outputLanguage !== outputLanguage
      : state?.summary?.trim() !== summary?.trim();
    const isButtonEnabled = isInitialState || (!isRequiredFieldsEmpty && isAnyInputChanged && !isAnyLoading);
    const isButtonLoading = isLoadingGetResultsButton || isAnyLoading;
    const generateButton = <LoadingButton
      variant="contained"
      id={"GET_RESULTS_BUTTON"}
      color={"primary"}
      size={"large"}
      sx={{ height: 56 }}
      startIcon={<Rocket />}
      onClick={onSubmitButtonHandler}
      disabled={!isButtonEnabled}
      loading={isButtonLoading}>
      {t("app.buttons.getResults")}
    </LoadingButton>;

    const reportData = generateReportData(state);
    return <>
      <Grid container gap={1} justifyContent={"center"}>
        {(isButtonEnabled && !isButtonLoading)
          ? generateButton
          : <Grid>
            <InfoTooltipView
              text={isAnyLoading
                ? t("app.alerts.waitUntilDataIsLoaded")
                : t("app.alerts.changeInputsForNewRequest")}
              icon={<span>{generateButton}</span>} />
          </Grid>}
        {!!reportData && <Grid><PdfGeneratorButton {...reportData} /></Grid>}
      </Grid>
    </>;
  }

  function getBrandDetails() {
    const postProcessedSummary = postProcessSummary(summary);
    const isMarkdown = isTextWithMarkdown(postProcessedSummary || "");
    return <Grid xs={12} sm={10} lg={8} sx={{ alignSelf: "center" }}>
      {isMarkdown && mode !== "manual"
        ? <FieldComponentMarkdown textMarkdown={postProcessedSummary || ""}
                                  buttonOverride={{
                                    text: t("app.buttons.edit"),
                                    onClick: () => setMode("manual")
                                  }}
                                  fieldLabel={("app.labels.productDescription")} />
        : <FieldComponentWithCopy fieldValue={postProcessedSummary || ""}
                                  onEdit={onSummaryChangeHandler}
                                  buttonEnabled={true}
                                  buttonOverride={isMarkdown ? {
                                    text: mode === "url" ? t("app.buttons.edit") : t("app.buttons.save"),
                                    onClick: () => setMode("url")
                                  } : undefined}
                                  fieldLabel={("app.labels.productDescription")} />}
    </Grid>;
  }

  function getOpenAPIResponseLoadingSection() {
    return <Grid xs={12}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}>
        {
          isRegistered
            ? <LoadingResultsComponentRegisteredUsers />
            : <LoadingLottieAnimationAnonymousUsers />
        }
      </motion.div>
    </Grid>;
  }

  function getTitle() {
    return <Grid container xs={12}
                 sx={{ mt: 8, alignItems: "baseline", justifyContent: "center" }}>
      <Grid xs={12} textAlign={"center"}>
        <Typography variant={"h1"} color={"primary"} fontWeight={800}
                    sx={{
                      mr: 1,
                      fontSize: { xs: 32, sm: 46 },
                      background: "linear-gradient(270.17deg, #A57DD3 3.88%, #0040FF 98.08%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      textFillColor: "transparent"
                    }}>
          {t("app.title")}
        </Typography>
      </Grid>
    </Grid>;
  }

  function getSubtitle() {
    const subtitle = t("app.subtitle", { break: isDesktop ? "<br />" : "" });
    return <Grid xs={"auto"} textAlign={"center"}>
      <Typography variant={"h1"} color={"primary"} fontWeight={400}
                  sx={{
                    fontSize: { xs: 18, sm: 20 },
                    maxWidth: 380,
                    mb: 4,
                    background: "linear-gradient(270.17deg, #A57DD3 3.88%, #0040FF 98.08%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent"
                  }}>
        <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
      </Typography>
    </Grid>;
  }

  function onRegenerateFieldClicked(field: string) {
    fetchField(field, summary);
    trackClickRegenerate(field);
    addRegenerationsUsed();

    const clickedFieldTitleId = `title_${field}`;
    const element = document.getElementById(clickedFieldTitleId);
    if (element) {
      // scroll 100px above the element
      const y = element.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }

  function getTabsSection() {

    function getSignInSection() {
      const getActiveTabFieldNames = Object.values(INITIAL_STATE_FULL.tabs[Object.keys(INITIAL_STATE_FULL.tabs)[state.activeTabIndex]].fields).map((field) => t(field.name));
      const totalFields = Object.values(INITIAL_STATE_FULL.tabs).reduce((count, tab) => count + Object.keys(tab.fields).length, 0);
      const fieldDiff = totalFields - getActiveTabFieldNames.length;
      const activeTabName = keys(state?.tabs)[state?.activeTabIndex];
      const signedUsersPromoLabel1 = t("app.labels.signedUsersPromoLabel1", { activeTabName: activeTabName });
      const signedUsersPromoLabel2 = t("app.labels.signedUsersPromoLabel2", { fieldDiff: fieldDiff });
      return <Grid mt={2} sx={{ alignSelf: "center", textAlign: "start" }}
                   ref={signInButtonReponseSectionRef}>
        <Grid>
          <Typography variant={"body1"} sx={{ my: 1 }}>
            <div dangerouslySetInnerHTML={{ __html: signedUsersPromoLabel1 }}></div>
          </Typography>
          <Typography variant={"body1"} sx={{ mx: 2 }}>
            {getActiveTabFieldNames.map((field) => <span key={field}>⭑ {field}<br /></span>)}
          </Typography>
          <Typography variant={"body1"} sx={{ mb: 1, mx: 2, ml: 2.3, mt: 0.5 }}>
            <div dangerouslySetInnerHTML={{ __html: signedUsersPromoLabel2 }}></div>
          </Typography>
        </Grid>
        <Grid textAlign={"center"}>
          <GoogleAuthButton />
        </Grid>
      </Grid>;
    }

    function getFinishedReqPaidPlanSection() {
      const getActiveTabFieldNames = Object.values(INITIAL_STATE_FULL.tabs[Object.keys(INITIAL_STATE_FULL.tabs)[state.activeTabIndex]].fields).map((field) => t(field.name));
      const totalFields = Object.values(INITIAL_STATE_FULL.tabs).reduce((count, tab) => count + Object.keys(tab.fields).length, 0);
      const fieldDiff = totalFields - getActiveTabFieldNames.length;
      const activeTabName = keys(state?.tabs)[state?.activeTabIndex];

      return (
        <Grid container direction={"column"} mt={2}
              sx={{ alignSelf: "center", textAlign: "start" }}>
          <Grid>
            <Typography variant={"body1"} sx={{ my: 1 }}>
              {t("app.labels.zeroMonthlyRequests")}
            </Typography>
            <Typography variant={"body1"} sx={{ my: 1 }}>
              <div
                dangerouslySetInnerHTML={{ __html: t("app.labels.upgradeForMoreAds", { activeTabName: activeTabName }) }}></div>
            </Typography>
            <Typography variant={"body1"} sx={{ mx: 2 }}>
              {getActiveTabFieldNames.map((field) => <span key={field}>⭑ {field}<br /></span>)}
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 1, mx: 2, ml: 2.3, mt: 0.5 }}>
              <div
                dangerouslySetInnerHTML={{ __html: t("app.labels.additionalTypesOfAds", { fieldDiff: fieldDiff }) }}></div>
            </Typography>
            <Typography variant={"body1"} sx={{ mx: 2, textAlign: "center" }}>
              {t("app.labels.upgradePrompt")}
            </Typography>
          </Grid>
          <Grid textAlign={"center"} sx={{ mt: 1 }}>
            <UpgradeButton callback={() => window.open(STRIPE_URL_MANAGE_PLAN, "_blank")} />
          </Grid>
        </Grid>
      );
    }

    const isReqFinishedForPaidUser = isRegistered && state?.subcription?.status !== "freemium" && state?.subcription?.requestsLeft === 0;
    const activeTabObject = state?.tabs[keys(state?.tabs)[state?.activeTabIndex]];

    return isAnythingToShow &&
      <Grid container xs={12} mt={2} spacing={0}>
        <Grid container xs={12} gap={1} sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}>
          {keys(state?.tabs).map((tab, index) =>
            <Grid key={tab}>
              <InfoTooltipView
                isDisabled={isRegistered || index < NUMBER_OF_TABS_TO_SHOW_NON_AUTH_USERS}
                text={t("app.labels.signUpForAllFeatures")}
                icon={
                  <Chip variant={index === state?.activeTabIndex ? "filled" : "outlined"}
                        sx={{ fontSize: 18 }} label={tab} onClick={() => onHandleTabChange(index)}
                        color={index === state?.activeTabIndex ? "primary" : "secondary"}
                        disabled={!isRegistered && index >= NUMBER_OF_TABS_TO_SHOW_NON_AUTH_USERS}
                  />
                } />
            </Grid>)}
        </Grid>
        <Grid container xs={12} justifyContent={"center"}>
          {activeTabObject?.fields && keys(activeTabObject?.fields).map((field, index) => {
            const fieldObject = activeTabObject?.fields[field];
            const isFieldLoading = !!fieldObject?.isLoading;
            const isMarkdown = isTextWithMarkdown(fieldObject?.content || "");
            return <Grid container direction={"column"} key={field} xs={12} sx={{ mt: 4 }}>
              <Grid container xs={12}
                    sx={{
                      p: 1,
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      bgcolor: "rgba(183,183,216,0.1)",
                      borderRadius: 2,
                      borderBottomLeftRadius: copiedField === field ? 0 : undefined,
                      borderBottomRightRadius: copiedField === field ? 0 : undefined
                    }}>
                <Grid sx={{ alignSelf: "center" }}>
                  <Typography id={`title_${field}`} variant={"h5"}
                              sx={{ textAlign: "left", ml: 1 }}>
                    {t(fieldObject?.name)}
                  </Typography>
                </Grid>
              </Grid>
              {fieldObject?.description && <Grid>
                <Typography variant={"body1"} sx={{ textAlign: "left", ml: 2, mt: 1 }}>
                  {t(fieldObject?.description)}
                </Typography>
              </Grid>}
              <Grid xs={12}>
                {isFieldLoading
                  ? <Grid sx={{ m: 2 }}>{LOADING_SECTION_COMPONENT}</Grid>
                  : <>
                    <Grid xs={12}
                          sx={{
                            bgcolor: copiedField === field ? green[50] : "transparent",
                            borderRadius: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0
                          }}>
                      {isMarkdown
                        ? <FieldComponentMarkdown fieldLabel={field}
                                                  textMarkdown={fieldObject?.content || ""} />
                        : <FieldComponentWithCopy fieldValue={fieldObject?.content || ""}
                                                  fieldLabel={field} />}
                    </Grid>
                    <RegenerateButton isLoading={isFieldLoading}
                                      onRegenerateClicked={() => onRegenerateFieldClicked(field)} />
                  </>}
              </Grid>
              {!isRegistered && (index === NUMBER_OF_FIELDS_TO_SHOW_NON_AUTH_USERS - 1) && getSignInSection()}
            </Grid>;
          })}
          <Grid container>
            {isReqFinishedForPaidUser && getFinishedReqPaidPlanSection()}
          </Grid>
        </Grid>
      </Grid>;
  }

  function getFaviconImage() {
    return favicon && <img src={favicon || ""} alt={"favicon"}
                           style={{
                             width: 20,
                             height: 20,
                             marginRight: 8,
                             marginBottom: -2,
                             position: "absolute",
                             top: 16
                           }} />;
  }

  function getSearchBar() {
    return (
      <FormControl variant={"outlined"} fullWidth sx={{
        "& .MuiOutlinedInput-root": {
          px: 2
        },
        "legend": {
          mx: 1.7
        },
        "& .MuiOutlinedInput-input": {
          pl: favicon ? 4 : 1
        }
      }}>
        <InputLabel sx={{ ml: 2 }}>
          {t("app.labels.searchBarInputLabel")}
        </InputLabel>
        <OutlinedInput label={t("app.labels.searchBarInputLabel")}
                       name={t("app.labels.searchBarInputLabel")}
                       placeholder={t("app.labels.searchBarInputPlaceholder")}
                       sx={{ background: "white" }}
                       startAdornment={getFaviconImage()}
                       value={url}
                       onChange={(e) => {
                         setUrl(e.target.value);
                         setUrlError(undefined);
                         setSummary("");
                       }}
                       onKeyDown={(e) => {
                         if (e.key === "Enter") {
                           e.preventDefault();
                           enableButtonLoadingFor5Sec();
                           onSubmitButtonHandler();
                         }
                       }}
        />
      </FormControl>
    );
  }

  function getUrlModeComponent() {
    const errRegisteredUser = <Typography variant={"caption"} color={"error"}>
      {t("app.labels.errorRegisteredUser", { urlError: urlError })}
    </Typography>;
    const errAnonymousUser = <Typography variant={"caption"} color={"error"}>
      {t("app.labels.errorAnonymousUser")}
    </Typography>;

    return (
      <Grid container xs={12} sm={12} lg={8} spacing={2} justifyContent={"center"}>
        <Grid container xs={12}>
          <Grid xs={12} sm>
            {getSearchBar()}
          </Grid>
          <Grid xs={12} sm={"auto"}>
            <CreativityLevel initType={creativity}
                             onSelectedCreativityLvlChange={onSelectedCreativityLvlHandler} />
          </Grid>
          <Grid xs={12} sm={"auto"}>
            <OutputLanguageSelect onOutputLanguageChange={onOutputLanguageChangeHandler} />
          </Grid>
        </Grid>
        {!urlError && isAnonymous && <FormHelperText sx={{ color: "grey", mx: 2 }}>
          {t("app.labels.searchBarHelperText")}
        </FormHelperText>}
        <Grid container xs={10}>
          {!!urlError
            ? isRegistered ? errRegisteredUser : errAnonymousUser
            : <></>}
        </Grid>
        <Grid sm={"auto"}>
          {!urlError
            ? !summary?.trim() && getSubmitButtonBlock()
            : isRegistered
              ? !summary?.trim() && getSubmitButtonBlock()
              : <GoogleAuthButton />}
        </Grid>
      </Grid>
    );
  }

  function getManualModeComponent() {
    return <Grid container xs={12} direction={"column"} spacing={2}>
      <Grid xs={12} sm={6} alignSelf={"center"}>
        <Typography variant={"body1"}>
          {t("app.labels.manualModeHelperText")}
        </Typography>
      </Grid>
      {getBrandDetails()}
      <Grid textAlign={"center"}>
        {getSubmitButtonBlock()}
      </Grid>
    </Grid>;
  }

  return <Grid container spacing={2} justifyContent={"center"}>
    <Grid container xs={12} gap={2} justifyContent={"center"}
          sx={{ textAlign: "center" }}>
      {getTitle()}
      {getSubtitle()}
      <Grid xs={12} sx={{ display: "flex", justifyContent: "center", mt: { xs: 0, sm: 8 } }}>
        <SwitchComponent mode={mode} onModeChanged={modeChangeHandler} />
      </Grid>
      {mode === "url" && getUrlModeComponent()}
      {(mode === "manual" || (!!summary && !urlError)) && getManualModeComponent()}
    </Grid>
    <Grid container xs={12} sm={10} lg={8} textAlign={"center"}>
      {isAnyLoading && !isAnythingToShow
        ? getOpenAPIResponseLoadingSection()
        : getTabsSection()}
    </Grid>
  </Grid>;
}
