import { configureStore } from "@reduxjs/toolkit";
import { stateReducer } from "./state";

export const AITER_STATE = "AITER_STATE";
const storedState = localStorage.getItem(AITER_STATE);
const persistedState = storedState ? JSON.parse(storedState) : {};

// clean up local storage saved earlier
if (String(persistedState).length > 10) {
  localStorage.setItem(AITER_STATE, JSON.stringify({}));
}

export const store = configureStore({
  reducer: {
    appState: stateReducer
  },
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

// store.subscribe(() => {
//     localStorage.setItem(AITER_STATE, JSON.stringify(store.getState()))
// })

export type RootState = ReturnType<typeof store.getState>;
