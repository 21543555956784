import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { initializeApp } from "firebase/app";
import {
  IS_PROD_BASE_URL,
  VITE_FIREBASECONFIG_DEV_APIKEY,
  VITE_FIREBASECONFIG_DEV_APPID,
  VITE_FIREBASECONFIG_DEV_AUTHDOMAIN,
  VITE_FIREBASECONFIG_DEV_MEASUREMENTID,
  VITE_FIREBASECONFIG_DEV_MESSAGINGSENDERID,
  VITE_FIREBASECONFIG_DEV_PROJECTID,
  VITE_FIREBASECONFIG_DEV_STORAGEBUCKET,
  VITE_FIREBASECONFIG_PROD_APIKEY,
  VITE_FIREBASECONFIG_PROD_APPID,
  VITE_FIREBASECONFIG_PROD_AUTHDOMAIN,
  VITE_FIREBASECONFIG_PROD_MEASUREMENTID,
  VITE_FIREBASECONFIG_PROD_MESSAGINGSENDERID,
  VITE_FIREBASECONFIG_PROD_PROJECTID,
  VITE_FIREBASECONFIG_PROD_STORAGEBUCKET
} from "./env";

const firebaseConfigProd = {
  apiKey: VITE_FIREBASECONFIG_PROD_APIKEY,
  authDomain: VITE_FIREBASECONFIG_PROD_AUTHDOMAIN,
  projectId: VITE_FIREBASECONFIG_PROD_PROJECTID,
  storageBucket: VITE_FIREBASECONFIG_PROD_STORAGEBUCKET,
  messagingSenderId: VITE_FIREBASECONFIG_PROD_MESSAGINGSENDERID,
  appId: VITE_FIREBASECONFIG_PROD_APPID,
  measurementId: VITE_FIREBASECONFIG_PROD_MEASUREMENTID
};

const firebaseConfigDev = {
  apiKey: VITE_FIREBASECONFIG_DEV_APIKEY,
  authDomain: VITE_FIREBASECONFIG_DEV_AUTHDOMAIN,
  projectId: VITE_FIREBASECONFIG_DEV_PROJECTID,
  storageBucket: VITE_FIREBASECONFIG_DEV_STORAGEBUCKET,
  messagingSenderId: VITE_FIREBASECONFIG_DEV_MESSAGINGSENDERID,
  appId: VITE_FIREBASECONFIG_DEV_APPID,
  measurementId: VITE_FIREBASECONFIG_DEV_MEASUREMENTID
};

export const firebaseConfig = IS_PROD_BASE_URL ? firebaseConfigProd : firebaseConfigDev;
const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebasePerformance = getPerformance(firebaseApp);
export const firestoreDb = getFirestore(firebaseApp);
export const firebaseRemoteConfig = getRemoteConfig(firebaseApp);
