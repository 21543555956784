import Grid from "@mui/material/Unstable_Grid2";
import "firebase/compat/auth";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  styled,
  Theme,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Logout, Person } from "@mui/icons-material";
import * as React from "react";
import { useState } from "react";
import { trackClickProfile, trackSignOutHeader } from "../../utils/analytics";
import { NavigateFunction, useNavigate } from "react-router-dom";
import "firebase/compat/firestore";
import { getCurrentUserAsync, registerWithPopupAsync, signOutUser } from "../../utils/auth";
import { useFirebaseLoginState } from "../../utils/hooks";
import googleLogo from "../../icons/google.svg";
import { User } from "firebase/auth";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const MenuMaterial = styled(Menu)({
  elevation: 0,
  "paper": {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    "&:before": {
      content: "\"\"",
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0
    }
  }
});

function getLoadingSkeleton() {
  return <Grid container gap={1} alignContent={"center"} alignItems={"center"} sx={{ px: 1 }}>
    <Typography variant="body1">
      <Skeleton variant="rectangular" width={180} height={40} />
    </Typography>
  </Grid>;
}

function getGoogleSignInButton(t: any) {

  const triggerSignInWithPopup = async () => {
    try {
      await registerWithPopupAsync();
    } catch (error) {
      console.error(error);
    }
  };

  return <Button variant="contained" color="primary" disableElevation={false}
                 onClick={triggerSignInWithPopup}
                 startIcon={<Box sx={{
                   bgcolor: "white",
                   width: 28,
                   height: 28,
                   borderRadius: 2
                 }}>
                   <img src={googleLogo} alt="Google Logo"
                        style={{
                          height: 18,
                          width: 18,
                          paddingLeft: 5,
                          marginRight: 8
                        }} />
                 </Box>}
                 sx={{
                   height: 44,
                   borderRadius: 2,
                   bgcolor: "#5383ec",
                   textTransform: "none",
                   elevation: "10px",
                   shadow: "10px",
                   "&:hover": {
                     backgroundColor: "primary.main",
                     boxShadow: "none"
                   }
                 }}>
    {t("website.googleAuthButton.signInWithGoogle")}
  </Button>;
}

function getUserProfileAvatar(navigate: NavigateFunction, user: User | null, anchorEl: HTMLElement | null, setAnchorEl: any, isDesktop: boolean, t: any, isMdUp:boolean) {
  const open = Boolean(anchorEl);

  const userName = user?.providerData[0]?.displayName ?? undefined;
  const userPhoto = user?.providerData[0]?.photoURL ?? undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = async () => {
    const user = await getCurrentUserAsync();
    const userId = user?.uid;
    try {
      await signOutUser();
    } catch (e) {
      console.error("Error signing out [header]:", e);
    }
    trackSignOutHeader(userId);
  };

  const handleProfileClick = () => {
    trackClickProfile();
    navigate("/profile");
  };

  return <Grid container gap={1} alignContent={"center"} alignItems={"center"}>
    <Grid>
      <IconButton onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}>
        <Avatar alt={userName} src={userPhoto} />
      </IconButton>
      {(isDesktop && !!userName && isMdUp) && <Typography variant={"caption"} sx={{ color: grey[700] }}>
        {userName}
      </Typography>}
    </Grid>
    <MenuMaterial anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
      <MenuItem onClick={handleProfileClick}>
        <ListItemIcon>
          <Person fontSize="small" />
        </ListItemIcon>
        {t("website.googleAuthButton.profile")}
      </MenuItem>
      <MenuItem onClick={handleLogoutClick}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {t("website.googleAuthButton.logout")}
      </MenuItem>
    </MenuMaterial>
  </Grid>;
}

export function GoogleAuthButton() {
  const { t } = useTranslation();
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const navigate = useNavigate();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const { isRegistered, isAuthStateLoading, user } = useFirebaseLoginState();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return isAuthStateLoading
    ? getLoadingSkeleton()
    : isRegistered
      ? getUserProfileAvatar(navigate, user, anchorEl, setAnchorEl, isDesktop, t, isMdUp)
      : getGoogleSignInButton(t);
}
