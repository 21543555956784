import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Person } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

export default function ThankYouPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const urlParams = new URLSearchParams(window.location.search);
  const profilePageUrl = "/profile" + window.location.search;

  const checkoutSessionId = urlParams.get("checkout_session_id") || undefined;
  const productName = urlParams.get("product_name") || undefined;
  const productPrice = urlParams.get("product_price") || undefined;

  useEffect(() => {
    if (!checkoutSessionId || !productName || !productPrice) {
      navigate(profilePageUrl)
    }
  }, [urlParams]);

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh"
    }}>
      <Grid container justifyContent="center" textAlign="center" gap={4}>
        <Grid xs={12}>
          <Typography variant={isDesktop ? "h3" : "h5"}>
            {t("website.thankyou.title")}
          </Typography>
          <br />
          <Typography variant={isDesktop ? "h4" : "h6"} color={blue[700]}>
            <b>{productName}</b> — {productPrice}$
          </Typography>
        </Grid>
        <Grid>
          <Button variant="contained" color="success" startIcon={<Person />}
                  onClick={() => navigate(profilePageUrl)}>
            {t("website.thankyou.button")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
