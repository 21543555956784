import { InfoTooltipView } from "../InfoTooltipView";
import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { green } from "@mui/material/colors";
import moment from "moment/moment";
import { firebaseTimestampToDate, isBeforeNow } from "../../utils/dateUtils";
import { STRIPE_URL_MANAGE_PLAN } from "../../App";
import { useTranslation } from "react-i18next";
import { useUserCodes } from "../../utils/db";
import { useFirebaseLoginState } from "../../utils/hooks";
import { openCrispChat } from "../../utils/crispUtils";

export function UpgradeButton({ callback }: { callback?: () => void }) {
  const { t } = useTranslation();
  return <Button variant={"contained"}
                 onClick={callback ?? (() => window.open(STRIPE_URL_MANAGE_PLAN, "_blank"))}
                 color={"success"}
                 sx={{
                   bgcolor: green[300],
                   "&:hover": {
                     bgcolor: green[500]
                   }
                 }}>
    {t("website.reqLeftCounter.upgrade")}
  </Button>;
}

function RequestCounterPopupComponent() {
  const { t } = useTranslation();

  function FreemiumView() {
    const reqMoreZero = t("website.reqLeftCounter.freemium.reqMoreZero", { reqLeft });
    const reqZero = t("website.reqLeftCounter.freemium.reqZero");
    return <Grid container justifyContent={"center"} textAlign={"center"}
                 direction={"column"} spacing={2} sx={{ m: 1 }}>
      <Grid>
        <Typography variant={"body2"} sx={{ color: "primary.contrastText" }}>
          <div dangerouslySetInnerHTML={{
            __html: (!reqLeft || reqLeft <= 0)
              ? reqZero
              : reqMoreZero
          }} />
        </Typography>
      </Grid>
      <Grid>
        <UpgradeButton callback={() => navigate("/profile")} />
      </Grid>
    </Grid>;
  }

  function PaidActiveView() {
    const { user } = useFirebaseLoginState();
    const appSumoCodes = useUserCodes(user?.uid);
    const codesRedeemed = appSumoCodes?.filter(x => x.isRedeemed);
    const codesRedeemedAndNotRefunded = codesRedeemed?.filter(x => !x.isRefunded);

    const isAppSumoUser = appSumoCodes?.length > 0;

    return <Grid container spacing={1} justifyContent={"center"} sx={{ my: 2 }}>
      {isAppSumoUser
        ? <Grid sx={{ px: 2, textAlign: "center" }}>
          <Typography variant={"body2"} display={"inline"} sx={{color: "primary.contrastText"}}>
            {t("website.reqLeftCounter.paidActive.appsumoYouHave")}
          </Typography> <Typography variant={"body2"} display={"inline"} sx={{ fontWeight: 900, color: "primary.contrastText" }}>
          {codesRedeemedAndNotRefunded.length} {t("website.reqLeftCounter.paidActive.appsumoCodes")}
        </Typography> <Typography variant={"body2"} display={"inline"} sx={{color: "primary.contrastText"}}>
          {t("website.reqLeftCounter.paidActive.appsumoRedeemedWith")}
        </Typography> <Typography variant={"body2"} display={"inline"} sx={{ fontWeight: 800, color: "primary.contrastText" }}>
          {reqLeft} {t("website.reqLeftCounter.paidActive.appsumoRequests")}
        </Typography> <Typography variant={"body2"} display={"inline"} sx={{color: "primary.contrastText"}}>
          {t("website.reqLeftCounter.paidActive.appsumoLeftThisMonth")}
        </Typography>
          <Typography variant={"body2"} sx={{ pt: 1, color: "primary.contrastText" }}>
            {t("website.reqLeftCounter.paidActive.appsumoNeedMoreContactUs")}
          </Typography>
          <Button variant={"contained"} onClick={() => openCrispChat()} color={"success"}
                  sx={{
                    bgcolor: green[300],
                    "&:hover": {
                      bgcolor: green[500]
                    }
                  }}>
            {t("website.reqLeftCounter.paidActive.appsumoContactUs")}
          </Button>
        </Grid>
        : <>
          <Grid textAlign={"center"}>
            <Typography variant={"body2"} sx={{ color: "primary.contrastText" }}>
              <div dangerouslySetInnerHTML={{
                __html: t("website.reqLeftCounter.paidActive.message", { reqLeft })
              }} />
            </Typography>
          </Grid>
          <Grid>
            <UpgradeButton />
          </Grid>
        </>}
    </Grid>;
  }

  function PaidLimitedView() {
    const expirationTime = state?.subcription?.dateEnd || state?.subcription?.dateCycleEnd;
    const isLimitedBecauseOfExpirationDate = isBeforeNow(expirationTime);
    const isLimitedBecauesOfRequests = reqLeft <= 0 && !isLimitedBecauseOfExpirationDate;

    const textLimitedBecauseOfRequests = t("website.reqLeftCounter.paidLimited.textLimitedBecauseOfRequests", {
      subscriptionName,
      reqLeft
    });

    const expirationTimeF = moment(expirationTime).fromNow();
    const textLimitedBecauseOfExpirationDate = t("website.reqLeftCounter.paidLimited.textLimitedBecauseOfExpirationDate", {
      subscriptionName,
      expirationTimeF
    });

    const text = isLimitedBecauesOfRequests
      ? textLimitedBecauseOfRequests
      : textLimitedBecauseOfExpirationDate;

    return <Grid container spacing={1} justifyContent={"center"} sx={{ my: 2 }}>
      <Grid textAlign={"center"}>
        <Typography variant={"body2"} sx={{ color: "primary.contrastText" }}>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Typography>
      </Grid>
      <Grid>
        <UpgradeButton />
      </Grid>
    </Grid>;
  }

  function CanceledActiveView() {
    const cancelAtMoment = moment(firebaseTimestampToDate(cancelAt));
    const isCancelationDateInFuture = cancelAt && cancelAtMoment.isAfter(moment());
    const cancelAtFormattedString = cancelAt && cancelAtMoment.format("LLL");
    return <Grid container direction={"column"} justifyContent={"center"} textAlign={"center"}
                 spacing={2} sx={{ my: 1 }}>
      <Grid>
        <Typography variant={"body1"} color={"primary.contrastText"}>
          <div dangerouslySetInnerHTML={{
            __html: isCancelationDateInFuture
              ? t("website.reqLeftCounter.canceledActive.futureCancelation.title", { cancelAtFormattedString })
              : t("website.reqLeftCounter.canceledActive.pastCancelation.title", { cancelAtFormattedString })
          }} />
        </Typography>
      </Grid>
      <Grid>
        <Typography variant={"body2"} color={"primary.contrastText"}>
          <div dangerouslySetInnerHTML={{
            __html: isCancelationDateInFuture
              ? t("website.reqLeftCounter.canceledActive.futureCancelation.subtitle", { reqLeft })
              : t("website.reqLeftCounter.canceledActive.pastCancelation.subtitle", { reqLeft })
          }} />
        </Typography>
      </Grid>
      <Grid>
        <UpgradeButton />
      </Grid>
    </Grid>;
  }

  function CanceledPastDueView() {
    return <Grid container spacing={1} justifyContent={"center"} sx={{ my: 2 }}>
      <Grid textAlign={"center"}>
        <Typography variant={"body2"} sx={{ color: "primary.contrastText" }}>
          {t("website.reqLeftCounter.canceledPastDue.title")}
        </Typography>
      </Grid>
      <Grid>
        <UpgradeButton />
      </Grid>
    </Grid>;
  }

  const navigate = useNavigate();

  const state = useSelector((state: RootState) => state.appState);

  const status = state?.subcription?.status;
  const reqLeft = state?.subcription?.requestsLeft ?? 0;
  const cancelAt = state?.stripe?.cancelAt;
  const subscriptionName = state?.stripe?.subscriptionName;

  if (status === "freemium") {
    return <FreemiumView />;
  } else if (status === "paid_active") {
    return <PaidActiveView />;
  } else if (status === "paid_active_limited") {
    return <PaidLimitedView />;
  } else if (status === "canceled_active") {
    return <CanceledActiveView />;
  } else if (status === "canceled_past_due") {
    return <CanceledPastDueView />;
  } else {
    console.warn("Unknown stripe status: ", status);
    return <></>;
  }
}

export function RequestsLeftCounter() {
  const state = useSelector((state: RootState) => state.appState);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const requestsLeft = state?.subcription?.requestsLeft ?? 0;

  return <InfoTooltipView
    text={<RequestCounterPopupComponent />}
    icon={
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} p={2}
           sx={{
             cursor: "pointer"
           }}
           onClick={() => {
             navigate("/profile");
           }}>
        <RocketLaunchIcon sx={{ fontSize: 20, color: "secondary.main", mr: 0.5 }} />
        <Typography variant={"body1"}>
          {(isMdUp || isSmDown) ? t("website.reqLeftCounter.requests", { requestsLeft }) : requestsLeft}
        </Typography>
      </Box>
    } />;
}
