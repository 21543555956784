import { Box } from "@mui/material";
import React from "react";

export const BackgroundContainer = (props: any) => {
  return <Box style={{
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh"
  }}>
    {props.children}
  </Box>;
};
