import { useEffect, useState } from "react";
import { useSigninCheck } from "reactfire";
import { isUserAnonymous, isUserRegistered, isUserSignedIn } from "./auth";

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return debouncedValue;
}

export function useArrayHook<T>(initialValue: T[]) {
  const [value, setValue] = useState<T[]>(initialValue);
  const add = (item: T) => setValue([...value, item]);
  const remove = (item: T) => setValue(value.filter((i) => i !== item));
  const has = (item: T) => value.includes(item);
  const clear = () => setValue([]);
  return { isNotEmpty: !!value?.length, value, add, remove, has, clear };
}

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY || document.documentElement.scrollTop;
      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return scrollPosition;
};

export function useFirebaseLoginState() {
  const { status, error, data } = useSigninCheck();

  const user = data?.user;
  const userId = user?.uid;

  const isSignedIn = isUserSignedIn(user)
  const isRegistered = isUserRegistered(user)
  const isAnonymous = isUserAnonymous(user)
  const isAuthStateLoading = status === "loading"

  return {
    status,
    user,
    userId,
    isRegistered,
    isSignedIn,
    isAnonymous,
    isAuthStateLoading,
    error,
  }
}

