import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import HowWorks1 from "../../icons/1.webp";
import HowWorks2 from "../../icons/2.webp";
import HowWorks3 from "../../icons/3.webp";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function HowDoesItWorkComponent() {
  const { t } = useTranslation();

  const [isSm, setIsSm] = useState(false);
  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 600) {
        setIsXs(true);
        setIsSm(false);
        return;
      } else if (window.innerWidth < 750) {
        setIsSm(true);
        setIsXs(false);
        return;
      } else {
        setIsSm(false);
        setIsXs(false);
      }
    }

    window.addEventListener("resize", handleResize);
  }, []);

  const iconSize = isXs
    ? 64
    : isSm
      ? 48
      : 64;

  return <Grid sm={12} md={10} lg={8} container spacing={{ xs: 4, lg: 8 }} sx={{ mt: 8 }}>
    <Grid xs={12} textAlign={"center"}>
      <Typography variant="h2" color={"primary"} fontWeight={400}
                  id={"how-does-it-work"}
                  sx={{
                    mr: 1,
                    fontSize: { xs: 46, sm: 32 },
                    background: "linear-gradient(270.17deg, #A57DD3 3.88%, #0040FF 98.08%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent"
                  }}>
        {t("website.howDoesItWork.mainTitle")}
      </Typography>
    </Grid>
    <Grid xs={12} container spacing={2}>
      <Grid xs={3} sm={1.3} xl={1} sx={{ textAlign: "right" }}>
        <img style={{ width: iconSize }} src={HowWorks1}
             alt={t("website.howDoesItWork.step1_alt")} />
      </Grid>
      <Grid xs={7} sm={true} xl={true} sx={{ alignSelf: "center", pb: 2 }}>
        <Typography variant="body1">
          {t("website.howDoesItWork.step1_description")}
        </Typography>
      </Grid>
      <Grid container xs={12} sm={4} xl={4} direction={{ xs: "row-reverse", sm: "row" }}>
        <Grid xs={3} sm={4} xl={3}>
          <img style={{ width: iconSize }} src={HowWorks2}
               alt={t("website.howDoesItWork.step2_alt")} />
        </Grid>
        <Grid xs={7} sm={true} xl={true} sx={{ alignSelf: "center", pb: 2 }}>
          <Typography variant="body1" textAlign={{ xs: "end", sm: "start" }}>
            {t("website.howDoesItWork.step2_description")}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={3} sm={1.3} xl={1} sx={{ textAlign: "right" }}>
        <img style={{ width: iconSize }} src={HowWorks3}
             alt={t("website.howDoesItWork.step3_alt")} />
      </Grid>
      <Grid xs={7} sm={true} xl={true} sx={{ alignSelf: "center", pb: 2 }}>
        <Typography variant="body1">
          {t("website.howDoesItWork.step3_description")}
        </Typography>
      </Grid>
    </Grid>
  </Grid>;
}
