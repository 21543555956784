import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, TextField, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { OUTPUT_LANGUAGE_DATA } from "../../utils/translations";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { setOutputLanguageManuallySelected } from "../../utils/state";

export interface OutputLanguageData {
  code: string;
  name: string;
}

export function OutputLanguageSelect({ onOutputLanguageChange }: {
  onOutputLanguageChange: (lang: OutputLanguageData) => void
}) {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.appState);

  const { i18n, t } = useTranslation();
  const storedOutputLanguage = state.outputLanguage;
  const isManuallySelected = state.isOutputLanguageManuallySelected;

  const [selectedLang, setSelectedLang] = useState<OutputLanguageData>(
    storedOutputLanguage || OUTPUT_LANGUAGE_DATA.find(lang => lang.code === i18n.language) || OUTPUT_LANGUAGE_DATA[0]
  );

  const autocompleteRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const [collapsedWidth, setCollapsedWidth] = useState(150);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  useEffect(() => {
    if (!isManuallySelected) {
      const newLang = OUTPUT_LANGUAGE_DATA.find(lang => lang.code === i18n.language) || OUTPUT_LANGUAGE_DATA[0];
      setSelectedLang(newLang);
      onOutputLanguageChange(newLang);
    }
  }, [i18n.language, dispatch, isManuallySelected]);

  useEffect(() => {
    if (textRef.current && isDesktop) {
      const textWidth = textRef.current.getBoundingClientRect().width;
      setCollapsedWidth(Math.max(150, textWidth + 70)); // Add some padding
    }
  }, [selectedLang, isDesktop]);

  const handleChange = (_: React.SyntheticEvent, newValue: OutputLanguageData | null) => {
    if (newValue) {
      setSelectedLang(newValue);
      onOutputLanguageChange(newValue);
      if (!isManuallySelected) {
        dispatch(setOutputLanguageManuallySelected(true));
      }
    }
  };

  return (
    <motion.div
      initial={{ width: isDesktop ? collapsedWidth : "100%" }}
      animate={{ width: isDesktop ? collapsedWidth : "100%" }}
      whileHover={{ width: isDesktop ? 230 : "100%" }}
      transition={{ duration: 0.3 }}
      style={{ width: isDesktop ? undefined : "100%" }}
    >
      <Autocomplete
        ref={autocompleteRef}
        options={OUTPUT_LANGUAGE_DATA}
        autoHighlight
        value={selectedLang}
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("app.labels.outputLanguageLabel")}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <span ref={textRef} style={{
                    position: "absolute",
                    visibility: "hidden",
                    whiteSpace: "nowrap"
                  }}>
                    {selectedLang.name}
                  </span>
                </>
              )
            }}
            inputProps={{
              ...params.inputProps,
              sx: { ml: 1 },
              autoComplete: "new-password" // disable autocomplete and autofill
            }}
          />
        )}
        disableClearable
        sx={{ width: "100%" }}
      />
    </motion.div>
  );
}
