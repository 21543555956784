import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  trackClickBannerProductHunt,
  trackClickBannerTheresanaiforthatBadgesSection
} from "../../utils/analytics";
import { useTranslation } from "react-i18next";

export const TAAFT_URL = "https://theresanaiforthat.com/ai/aiter-marketing-content-strategy-and-ads-generator/";
export const PH_PAGE_URL = "https://www.producthunt.com/posts/aiter-io?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-aiter&#0045;io";

export function BadgesSectionComponent() {
  const { t } = useTranslation();

  return <Grid container sx={{ mt: { xs: 16, sm: 16 }, mb: {xs: 4, sm: 6} }} mb={-2} gap={2} xs={12}
               justifyContent={"center"}>
    <Grid>
      <a href={TAAFT_URL} target="_blank" style={{ width: 250, height: 54 }} onClick={() => {
        trackClickBannerTheresanaiforthatBadgesSection();
      }}>
        <img style={{ width: 250, height: 54 }}
             alt={"theresanaiforthat"}
             src="https://media.theresanaiforthat.com/featured3.png" />
      </a>
    </Grid>
    <Grid>
      <a href={PH_PAGE_URL} target="_blank" onClick={() => {
        trackClickBannerProductHunt();
      }}>
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=460441&theme=light"
          alt="Aiter&#0046;io - Generate&#0032;Ads&#0044;&#0032;Content&#0032;and&#0032;Strategy&#0032;with&#0032;a&#0032;Click&#0033;&#0032;Try&#0032;Now&#0032;😉 | Product Hunt"
          style={{ width: 250, height: 54 }} width="250" height="54" />
      </a>
    </Grid>
  </Grid>;
}
