import firebase from "firebase/compat/app";
import { Timestamp } from "firebase/firestore";
import moment from "moment";

export const updatedAtNowFirebase = () => firebase.firestore.Timestamp.now();

export const firebaseTimestampToDate = (timestamp: any) => {
  if (!timestamp) return null;
  try {
    return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
  } catch (e) {
    console.error(`Error converting firebase timestamp to date: ${e} for timestamp: ${timestamp}`);
  }
};

export const isBeforeNow = (momentDate?: Date) => moment(momentDate).isBefore(moment());

export const dateToFirebaseTimestamp = (date: Date) => Timestamp.fromDate(date);

export const dateToIsoString = (date: Date | undefined | null) =>
  !!date ? moment(date).toISOString() : null;

export const isoStringToDate = (date: string | undefined | null) =>
  !!date ? moment(date).toDate() : null;

export const createDelay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
