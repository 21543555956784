import { createTheme, ThemeProvider } from "@mui/material/styles";

import { ReactNode } from "react";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 820,
      md: 950,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: "#3D4BF5",
      light: "#F1F1FB"
    },
    secondary: {
      main: "#00134C",
      light: "#B7B7D8"
    }
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#00134C"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          border: 0,
          // set disabled color
          "&.Mui-disabled": {
            background: "#F1F1FB",
            color: "#B7B7D8"
          }
        }
      }
    }
  }
});

export function ApplyTheme({ children }: { children: ReactNode }) {
  return <>
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </>;
}
