import React, { useEffect } from "react";
import { BackgroundContainer } from "../components/BackgroundContainer";
import HeaderComponent from "../components/website/HeaderComponent";
import { Button, Link, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import GoogleIcon from "../icons/Google.webp";
import {
  trackClickLogoutProfile,
  trackSentryError,
  trackSignOutProfile,
  trackStripePurchaseSuccesful
} from "../utils/analytics";
import { useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import { PricingPage } from "../components/website/PricingPage";
import FooterComponent from "../components/website/FooterComponent";
import moment from "moment";
import { RootState } from "../utils/store";
import Grid from "@mui/material/Unstable_Grid2";
import { signOutUser } from "../utils/auth";
import { useFirebaseLoginState } from "../utils/hooks";
import { Payment } from "@mui/icons-material";
import { STRIPE_URL_MANAGE_PLAN } from "../App";
import { useTranslation } from "react-i18next";
import { useUserCodes } from "../utils/db";
import { getPlanByNumberOfCodesRedeemed } from "../utils/appsumo";
import { LoadingButton } from "@mui/lab";
import { redeemAppsumoCode } from "../utils/api";
import { openCrispChat } from "../utils/crispUtils";
import { firebaseTimestampToDate } from "../utils/dateUtils";
import { max } from "lodash";
import { isPaidUserStatus } from "../components/app/AiterAppComponent";


export default function Profile() {
  const state = useSelector((state: RootState) => state.appState);

  const { isRegistered, user, isAuthStateLoading } = useFirebaseLoginState();
  const appSumoCodes = useUserCodes(user?.uid);
  const isAppSumoUser = appSumoCodes?.length > 0;
  const codesRedeemed = appSumoCodes?.filter(x => x.isRedeemed);
  const codesRefunded = appSumoCodes?.filter(x => x.isRefunded);
  const codesRedeemedAndNotRefunded = codesRedeemed?.filter(x => !x.isRefunded);
  const codesNotRedeemedAndNotRefunded = appSumoCodes?.filter(x => !x.isRedeemed && !x.isRefunded);
  const appSumoPlan = getPlanByNumberOfCodesRedeemed(codesRedeemedAndNotRefunded?.length);
  const isMaxAppSumoPlanReached = appSumoPlan?.id === 3;

  const { t } = useTranslation();

  useEffect(() => {
    if (!isAuthStateLoading && !isRegistered) {
      navigate("/");
    }
  }, [isRegistered, isAuthStateLoading]);

  useEffect(() => {
    // https://aiter.io/profile?checkout_session_id=cs_live_a1CwrcSlTxRCe7vINFEe6pppxURjDoHw4fPh8QOYX2Edo0w8qKrwendMrG&product_name=Testing(month)&product_price=0.01&plan_id=123
    const urlParams = new URLSearchParams(window.location.search);
    const checkoutSessionId = urlParams.get("checkout_session_id") || undefined;
    const productName = urlParams.get("product_name") || undefined;
    const productPrice = urlParams.get("product_price") || undefined;
    if (!!checkoutSessionId && !!productName && !!productPrice) {
      trackStripePurchaseSuccesful(checkoutSessionId, productName, productPrice);
    }
  }, []);

  const confirmLogout = useConfirm();
  const navigate = useNavigate();

  const displayName = user?.providerData[0]?.displayName;
  const userName = displayName?.split(" ")?.[0] ?? "";
  const userSurname = displayName?.split(" ")?.[1] ?? "";
  const userEmail = user?.email ?? "";

  const handleLogoutClick = () => {
    trackClickLogoutProfile();
    confirmLogout({
      title: t("website.profile.confirmLogoutTitle"),
      description: t("website.profile.confirmLogoutMessage")
    })
      .then(async () => {
        const userId = user?.uid;
        try {
          await signOutUser();
        } catch (e) {
          console.error("Error signing out [profile]:", e);
        }
        trackSignOutProfile(userId);
        navigate("/");
      });
  };

  function SubscriptionView() {

    function getManageSubscriptionButton() {
      const isPaidUser = isPaidUserStatus(subscriptionSatus);
      return <Button variant="outlined" size={"large"}
                     sx={{
                       border: 1,
                       color: isPaidUser ? "primary.main" : "success.main",
                       borderColor: isPaidUser ? "primary.main" : "success.main",
                       "&:hover": {
                         borderColor: isPaidUser ? "primary.dark" : "success.dark"
                       }
                     }}
                     onClick={() => window.open(STRIPE_URL_MANAGE_PLAN, "_blank")}
                     startIcon={<Payment />}>
        {
          isPaidUser
            ? t("website.profile.manageSubscription")
            : t("website.profile.renewSubscription")
        }
      </Button>;
    }

    function getSubscriptionDetailsView() {
      return <Grid container spacing={1} direction={"column"}>
        <Grid sx={{ ml: 1 }}>
          <Typography variant={"body2"}>
            {t("website.profile.subscriptionDetailsStartDate")} <b>
            {subsctiptionStartDate.format("LLL")}</b><br />
            {t("website.profile.subscriptionDetailsEndDate")} <b>
            {subsctiptionEndDate.format("LLL")}</b><br />
            {t("website.profile.subscriptionDetailsDaysLeft")} <b>{daysLeft}</b>
          </Typography>
        </Grid>
      </Grid>;
    }

    function getActiveSubscriptionView() {
      return <Grid container direction={"column"} gap={2} sx={{ mt: 0.6 }}>
        <Grid>
          <Typography variant={"body1"}>
            {t("website.profile.subscriptionDetailsActiveSubscription")} <b>{subscriptionName}</b>
          </Typography>
        </Grid>
        {getSubscriptionDetailsView()}
        <Grid>
          <Typography variant={"body2"}>
            <div dangerouslySetInnerHTML={{
              __html: t("website.profile.subscriptionDetailsRequestsLeft", { reqLeft: reqLeft })
            }} />
          </Typography>
        </Grid>
        <Grid>
          <Typography variant={"body2"} color={"success.main"}>
            {t("website.profile.subscriptionDetailsUpdatePlan")}
          </Typography>
        </Grid>
        <Grid xs={12}>
          {getManageSubscriptionButton()}
        </Grid>
      </Grid>;
    }

    function getActiveLimitedSubscriptionView() {
      const isLimitedByRequests = !reqLeft || reqLeft <= 0;
      return <Grid container direction={"column"} spacing={2} sx={{ mt: -0.4 }}>
        <Grid>
          <Typography variant={"body1"}>
            {isLimitedByRequests
              ? <>{t("website.profile.subscriptionDetailsActiveSubscription")} <b>
                {subscriptionName}</b></>
              : <>{t("website.profile.subscriptionDetailsSubscription")} <b>
                {subscriptionName}</b></>}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant={"body2"}>
            <div dangerouslySetInnerHTML={{
              __html: t("website.profile.subscriptionDetailsFinishedSubscription", { reqLeft: reqLeft })
            }} />
          </Typography>
        </Grid>
        {getSubscriptionDetailsView()}
        <Grid>
          <Typography variant={"body2"} color={"success.main"}>
            {t("website.profile.subscriptionDetailsUpdateToContinueUsing")}
          </Typography>
        </Grid>
        <Grid xs={12}>
          {getManageSubscriptionButton()}
        </Grid>
      </Grid>;
    }

    function getCanceledSubscriptionView() {
      const subscriptionCancelAtMoment = moment(firebaseTimestampToDate(subscriptionCancelAt));
      const isCancelationInFuture = subscriptionCancelAt && subscriptionCancelAtMoment.isAfter(moment());
      const cancelAtFormattedString = subscriptionCancelAt && subscriptionCancelAtMoment.format("LLL");
      return <Grid container direction={"column"} spacing={2} xs={12} sx={{ mt: -0.4 }}>
        <Grid>
          <Typography variant={"body1"}>
            <div dangerouslySetInnerHTML={{
              __html: isCancelationInFuture
                ? t("website.reqLeftCounter.canceledActive.futureCancelation.title", { cancelAtFormattedString })
                : t("website.reqLeftCounter.canceledActive.pastCancelation.title", { cancelAtFormattedString })
            }} />
          </Typography>
        </Grid>
        <Grid>
          <Typography variant={"body2"}>
            <div dangerouslySetInnerHTML={{
              __html: isCancelationInFuture
                ? t("website.reqLeftCounter.canceledActive.futureCancelation.subtitle", { reqLeft })
                : t("website.reqLeftCounter.canceledActive.pastCancelation.subtitle", { reqLeft })
            }} />
          </Typography>
        </Grid>
        <Grid>
          <Typography variant={"body2"} color={"success.main"}>
            {t("website.profile.subscriptionDetailsUpdatePlan")}
          </Typography>
        </Grid>
        <Grid xs={12}>
          {getManageSubscriptionButton()}
        </Grid>
      </Grid>;
    }

    function getCanceledInactiveSubscriptionView() {
      const subscriptionCancelAtMoment = moment(firebaseTimestampToDate(subscriptionCancelAt));
      const isCancelationInFuture = subscriptionCancelAt && subscriptionCancelAtMoment.isAfter(moment());
      const cancelAtFormattedString = subscriptionCancelAt && subscriptionCancelAtMoment.format("LLL");

      return <Grid container direction={"column"} spacing={2} sx={{ mt: -0.3 }}>
        <Grid>
          <Typography variant={"body1"}>
            <div dangerouslySetInnerHTML={{
              __html: isCancelationInFuture
                ? t("website.reqLeftCounter.canceledActive.futureCancelation.title", { cancelAtFormattedString })
                : t("website.reqLeftCounter.canceledActive.pastCancelation.title", { cancelAtFormattedString })
            }} />
          </Typography>
        </Grid>
        {getSubscriptionDetailsView()}
        <Grid>
          <Typography variant={"body2"} color={"success.main"}>
            {t("website.profile.subscriptionDetailsUpdatePlan")}
          </Typography>
        </Grid>
        <Grid xs={12}>
          {getManageSubscriptionButton()}
        </Grid>
      </Grid>;
    }

    function getFreemiumSubscriptionView() {
      return <Grid container direction={"column"} spacing={2} sx={{ mt: -0.3 }}>
        <Grid>
          <Typography variant={"body1"} dangerouslySetInnerHTML={
            {
              __html: t("website.profile.subscriptionDetailsFreemium", { reqLeft })
            }
          } />
        </Grid>
      </Grid>;
    }

    const reqLeft = state?.subcription?.requestsLeft ?? 0;
    const subscriptionName = state?.stripe?.subscriptionName ?? "";
    const subscriptionCancelAt = state?.stripe?.cancelAt ?? 0;

    const subsctiptionStartDate = moment(state?.subcription?.dateStart);
    const subsctiptionEndDate = moment(state?.subcription?.dateEnd);
    const daysLeft = subsctiptionEndDate && max([subsctiptionEndDate.diff(moment(), "days"), 0]);

    const subscriptionSatus = state?.subcription?.status;
    switch (subscriptionSatus) {
      case "paid_active": // subscription is active and not limited
        return getActiveSubscriptionView();
      case "paid_active_limited": // subscription is active and limited
        return getActiveLimitedSubscriptionView();
      case "canceled_active": // subscription is canceled but still active
        return getCanceledSubscriptionView();
      case "canceled_past_due": // subscription is canceled and expired
        return getCanceledInactiveSubscriptionView();
      case "freemium": // subscription is freemium, showing nothing since already shown
        return getFreemiumSubscriptionView();
      case undefined:
        console.warn("Subscription status is undefined, showing empty pricing table.");
        return <></>;
      default:
        console.error("Unknown subscription status, showing empty pricing table.");
        return <></>;
    }
  }

  function AppSumoSubscriptionView() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    const [code, setCode] = React.useState("");
    const [isButtonStateLoading, setIsButtonStateLoading] = React.useState(false);
    const [codeError, setCodeError] = React.useState<string | undefined>();

    const onSubmitCodeClick = () => {
      setIsButtonStateLoading(true);
      setCodeError(undefined);

      redeemAppsumoCode(user?.uid, code)
        .then((req) => {
          setIsButtonStateLoading(false);
        })
        .catch((error) => {
          trackSentryError(`Error redeeming code`, {
            code: code,
            error: error.message
          });
          setIsButtonStateLoading(false);
          setCodeError(JSON.parse(error.message).message);
        });
    };

    return <Grid container direction={"column"} spacing={2} sx={{ mt: -0.3 }}>
      <Grid>
        <Typography variant={"body1"}>
          <b>{appSumoPlan?.name}</b> 🚀
        </Typography>
        <Typography variant={"body2"} sx={{ color: "success.main" }}>
          {t("website.profile.appsumoCodesRedeemed")}: <b>{codesRedeemedAndNotRefunded?.length}</b>
        </Typography>
        {!!codesRefunded?.length && <Typography variant={"body2"} sx={{ color: grey[600] }}>
          {t("website.profile.appsumoCodesRefunded")}: <b>{codesRefunded.length}</b>
        </Typography>}
        {!!codesNotRedeemedAndNotRefunded?.length &&
          <Typography variant={"body2"} sx={{ color: red[300] }}>
            {t("website.profile.appsumoCodesErrors")}: <b>{codesNotRedeemedAndNotRefunded.length}</b>
          </Typography>}
      </Grid>
      {appSumoCodes?.map((code, index) => {
        return <Grid>
          <Typography variant={"body2"}>
            {index + 1}. {t("website.profile.appsumoCode")}: <b>{code.code}</b>
          </Typography>
          <Typography variant={"body2"} sx={{ ml: 2 }}
                      color={code.isRedeemed ? "success.main" : red[300]}>
            {code.isRedeemed
              ? <>{t("website.profile.appsumoCodeRedeemedAt")}: <b>{moment(code.redeemedAt).format("LLL")}</b></>
              : <>{t("website.profile.appsumoCodeWasNotRedeemedError")} (<Link href="#"
                                                                               onClick={openCrispChat}
                                                                               sx={{
                                                                                 color: red[300],
                                                                                 textDecoration: "underline"
                                                                               }}>
                {t("website.profile.appsumoContactSupport")}
              </Link>)</>}
          </Typography>
          {code.isRefunded &&
            <Typography variant={"body2"} sx={{ ml: 2 }}
                        color={code.isRefunded ? grey[600] : undefined}>
              {t("website.profile.appsumoCodeRefundedAt")}: <b>{moment(code.refundedAt).format("LLL")}</b>
            </Typography>}
        </Grid>;
      })}
      {!isMaxAppSumoPlanReached && <>
        <Grid xs={12}>
          <TextField
            label={t("website.profile.appsumoAppsumoCode")} variant="outlined"
            sx={{ minWidth: isDesktop ? 300 : "100%" }}
            placeholder={t("website.profile.appsumoCodeExample")} value={code}
            error={!!codeError} helperText={codeError}
            onChange={(e) => setCode(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <LoadingButton
            onClick={onSubmitCodeClick} variant="contained" color={"success"}
            loading={isButtonStateLoading}
            sx={{
              ...(isButtonStateLoading && {
                background: `${green[100]} !important`
              })
            }}>
            {t("website.profile.appsumoSubmitOneMoreCode")}
          </LoadingButton>
        </Grid>
      </>}
    </Grid>;
  }

  return <BackgroundContainer>
    <HeaderComponent />
    {!isAuthStateLoading
      && <>
        <Grid container justifyContent={"center"} gap={4}
              sx={{
                m: { xs: 2, sm: 4, xl: 8 },
                p: { xs: 0, sm: 2, xl: 3 }
              }}>

          <Grid sx={{ mb: { xs: 0, sm: 6 } }}>
            <Typography variant={"h5"}>
              {t("website.profile.details")}
            </Typography>
          </Grid>

          <Grid container xs={12} smOffset={1} xsOffset={0} spacing={{ xs: 2, sm: 0 }}
                direction={{ xs: "column", sm: "row" }}>
            <Grid xs={2}>
              <Typography variant={"h6"}>
                {t("website.profile.general")}
              </Typography>
            </Grid>
            <Grid container xs={12} sm={6} spacing={2}>
              <Grid xs={12} sm={6}>
                <TextField fullWidth label={t("website.profile.name")} value={userName}
                           variant="outlined" disabled />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField fullWidth label={t("website.profile.surname")} value={userSurname}
                           variant="outlined" disabled />
              </Grid>
              <Grid sx={{ ml: 1, mt: -1 }}>
                <Typography variant={"caption"} sx={{ color: grey[500] }}>
                  {t("website.profile.caption")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} smOffset={1} xsOffset={0}
                spacing={{ xs: 2, sm: 0 }}
                direction={{ xs: "column", sm: "row" }}>
            <Grid xs={2}>
              <Typography variant={"h6"}>Access</Typography>
            </Grid>
            <Grid container xs={12} sm={6} spacing={2} direction={"column"}>
              <Grid xs={12}>
                <TextField fullWidth label={t("website.profile.email")} value={userEmail}
                           variant="outlined" disabled />
              </Grid>
              <Grid container direction={"row"}>
                <Grid>
                  <Button variant="outlined" size={"large"}
                          sx={{ border: 1 }}
                          disabled
                          startIcon={
                            <img src={GoogleIcon} alt="google icon"
                                 style={{ width: 24, height: 24 }} />
                          }>
                    {t("website.profile.googleConnected")}
                  </Button>
                </Grid>
                <Grid>
                  <Button variant="outlined" size={"large"}
                          onClick={() => handleLogoutClick()}
                          sx={{ border: 1, borderColor: grey[300], color: grey[700] }}
                          color={"secondary"}>
                    {t("website.profile.logout")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} smOffset={1} xsOffset={0} spacing={{ xs: 2, sm: 0 }}
                direction={{ xs: "column", sm: "row" }}>
            <Grid xs={2}>
              <Typography variant={"h6"}>Subscription</Typography>
            </Grid>
            <Grid xs direction={"column"}>
              {isAppSumoUser ? <AppSumoSubscriptionView /> : <SubscriptionView />}
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} direction={"column"} sx={{ minHeight: 300 }}>
          {!isAppSumoUser && <PricingPage />}
        </Grid>

        <FooterComponent />
      </>}
  </BackgroundContainer>;
}
