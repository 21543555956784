import * as React from "react";
import { scrollToTop } from "../../utils/scroller";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export function GetResultsButton({ size, text, mt, callback }: {
  size?: "small" | "medium" | "large",
  text?: string,
  mt?: number,
  callback?: () => void
}) {
  const { t } = useTranslation();

  return <Grid xs={12} sx={{
    display: "flex", justifyContent: "center",
    mt: !!mt ? mt : 0
  }}>
    <Button size={!!size ? size : "small"}
            variant="contained"
            color="primary"
            sx={{
              my: 2,
              display: "block",
              textTransform: "none",
              fontWeight: 400,
              bgcolor: "primary.main",
              color: "white",
              "&:hover": {
                bgcolor: "primary.dark",
                color: "white"
              }
            }}
            onClick={() => {
              if (callback) {
                callback();
              }
              const shouldShakeButton = window.scrollY <= 300;
              const onScrollFinished = shouldShakeButton
                ? () => {
                  const button = document.getElementById("GET_RESULTS_BUTTON");
                  if (!!button) {
                    button.classList.add("gentle-tilt-move-shake");
                    setTimeout(() => {
                      button.classList.remove("gentle-tilt-move-shake");
                    }, 600);
                  }
                }
                : undefined;
              scrollToTop(onScrollFinished);
            }}>
      {!!text ? text : t("app.buttons.resultsButton")}
    </Button>
  </Grid>;
}
