import React from "react";
import Lottie from "react-lottie";
import animationData from "../../icons/lottie-loading-anonymous-users.json";
import Grid from "@mui/material/Unstable_Grid2";
import AnimatedText from "./AnimatedText";
import { useTranslation } from "react-i18next";

export default function LoadingLottieAnimationAnonymousUsers() {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const lottie = <Lottie options={defaultOptions} height={200} width={200}
                         style={{ marginTop: 16 }} />;
  return <Grid container>
    <Grid xs={12}>
      {lottie}
    </Grid>
    <Grid xs={12}>
      <AnimatedText text={t("app.labels.generatingCampaignDetails")} />
    </Grid>
  </Grid>;
}
